import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useMonaco } from "@monaco-editor/react";
import "./selectLanguages.scss";
const { Option } = Select;

export default function SelectLanguages({ setLanguages, question }) {
  const defaultLanguages = [
    { name: "Python", value: "py" },
    { name: "JavaScript", value: "js" },
    { name: "C++", value: "cpp" },
    { name: "Java", value: "java" },
  ];
  const filteredLanguages = defaultLanguages.filter((language) => {
    return question.init_code.some((code) => code._language == language.value);
  });
  console.log("😀 - filteredLanguages - filteredLanguages", filteredLanguages);

  // console.log(monaco);
  return (
    <>
      <Select
        id="select-language"
        style={{ width: 120 }}
        defaultValue={question.init_code[0]._language}
        onSelect={(value) => setLanguages(value)}
      >
        {filteredLanguages.map((lang, index) => {
          return (
            <Option key={index} value={lang.value}>
              {lang.name}
            </Option>
          );
        })}
        {/* {languages.map((lang) => (
          <Option value={lang}>{lang}</Option>
        ))} */}
      </Select>
    </>
  );
}
