import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectThongTinMoRong } from "../../../redux/reducer/authReducer";
import { getHinhAnh } from "../../../utils/GetHinhanh";
import servCodingCamp from "../../../services/codingCam.service";
import { selectListThanhTich, setListThanhTich, setPersonalState } from "../tuongNhaSlice";
import { useParams } from "react-router-dom";
import classNames from "classnames";
export default function ListThanhTich() {
  let { userId } = useParams();

  const listThanhTich = useSelector(selectListThanhTich);
  let dispatch = useDispatch();
  useEffect(() => {
    servCodingCamp
      .getThongTinTuongNha(userId, 1)
      .then((res) => {
        dispatch(setListThanhTich(res.data.content.danhSachThanhTich));
        dispatch(setPersonalState(res.data.content));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const { danhSachThanhTich } = useSelector(selectThongTinMoRong);
  const renderThanhTich = () => {
    if (danhSachThanhTich.length == 0) {
      return <p className="text-content">Bạn chưa có thành tích </p>;
    }
    return listThanhTich?.map(({ hinhAnh, tenThanhTich, moTa, soHuu }) => {
      return (
        <div
          className={classNames(
            "space-y-2 text-sub-title card-theme  rounded-lg flex  items-center p-2 space-x-3",
            { "bg-gray-400 border-none text-black": !soHuu }
          )}
        >
          <img src={getHinhAnh(hinhAnh)} className="w-16 flex-shrink-0" alt="" />
          <div className="text-center flex-grow">
            <span className="text-center textm-title">{tenThanhTich}</span>
            <p className="text-content">{moTa}</p>
          </div>
        </div>
      );
    });
  };
  return <div className="grid grid-cols-3 gap-5">{renderThanhTich()}</div>;
}
