import { Table } from "antd";
import React from "react";
import { tenNhiemVu } from "../../../utils/taskUtils";

let columns = [
  {
    key: "tagSkill",
    dataIndex: "tagSkill",
    title: "Skill",
  },
  {
    key: "maLoai",
    dataIndex: "maLoai",
    title: "Loại nhiệm vụ",
    render: (text) => tenNhiemVu[text],
  },
  {
    key: "tienDo",
    dataIndex: "tienDo",
    title: "Tiến độ",
  },
  {
    key: "thoiGianLam",
    dataIndex: "thoiGianLam",
    title: "Thời gian làm",
    render: (text) => `${text} phút`,
  },
  {
    key: "diem",
    dataIndex: "diem",
    title: "Điểm",
  },
];

export default function TableScore({ chuyenDe }) {
  let { diemTrungBinh, thoiGianHoc, phanTramHoanThanh } = chuyenDe;
  return (
    <div className="text-title space-y-5">
      <p className="title">{chuyenDe.tenChuyenDe}</p>
      <div className="flex items-center justify-between text-xl px-10 py-3 bg-gray-200 text-dark">
        <span>Điểm trung bình: {diemTrungBinh} điểm </span>
        <span>Thời gian học: {thoiGianHoc} phút</span>
        <span>Phần trăm hoàn thành: {phanTramHoanThanh} %</span>
      </div>
      <Table pagination={{ pageSize: 10 }} columns={columns} dataSource={chuyenDe.danhSachSkill} />
    </div>
  );
}
