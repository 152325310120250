import React from "react";
import top1 from "./img/top1.png";
import top2 from "./img/top2.png";
import top3 from "./img/top3.png";
import { useNavigate } from "react-router-dom";
import { ConvertNameUser } from "../../utils/ConvertNameUser";
import { getHinhAnhFB } from "../../utils/GetHinhanh";
let listTop = [top1, top2, top3];
export default function ItemTop({ user, rank, size }) {
  let navigate = useNavigate();

  return (
    <div
      style={{
        transform: size == 1 ? "translateY(-15px)" : "translateY(0)",
      }}
      className="flex flex-col items-center space-y-0.5 text-content w-1/3 text-center"
    >
      <span
        style={{
          transform: `scale(${size}`,
        }}
        className="font-bold text-xl"
      >
        #{rank + 1}
      </span>
      <img
        onClick={() => {
          navigate(`/profile/${user.nguoiDungId}`);
        }}
        style={{
          transform: `scale(${size}`,
          borderColor: "#DBB845",
        }}
        className="w-20 h-20 object-cover rounded-full border-4 squre cursor-pointer "
        src={getHinhAnhFB(user?.avatar)}
        alt=""
      />

      <img
        style={{
          transform: `scale(${size}`,
        }}
        src={listTop[rank]}
        alt=""
        className="w-1/2"
      />
      <span className="capitalize">{ConvertNameUser(user?.hoTen, 3)}</span>
    </div>
  );
}
