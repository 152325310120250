import React from "react";
import ContentTab from "../../components/ContentTab/ContentTab";
import ReactHtmlParser from "react-html-parser";
import "./style.css";
export default function TabLearnVideo({ baiHoc }) {
  const data = [
    {
      label: `Nội dung`,
      key: "1",
      children: ReactHtmlParser(baiHoc?.content?.moTa),
    },
    {
      label: `Ghi chú`,
      key: "2",
      children: ReactHtmlParser(baiHoc?.content?.ghiChu),
    },
    // {
    //   label: `Q&A`,
    //   key: "3",
    //   children: ReactHtmlParser(
    //     baiHoc?.content?.ghiChu || ""
    //   ),
    // },
  ];
  console.log("😀 - TabLearnVideo - data", data);
  if (!baiHoc?.content?.moTa && !baiHoc?.content?.ghiChu) {
    return <></>;
  }
  return (
    <div id="tabLearnVideo" className="c-col-100 ">
      <div className="card-theme h-full">
        <div style={{ minHeight: 200 }} className="c-card-content">
          <ContentTab
            className="text-content"
            paddingHeaderX={20}
            paddingHeaderY={10}
            paddingTabItemX={20}
            paddingTabItemY={5}
            data={data.filter((item) => item.children.length)}
          />
        </div>
      </div>
    </div>
  );
}
