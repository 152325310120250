import React from "react";
import TitleTaskNavigate from "../../../components/TitleTaskNavigate/TitleTaskNavigate";
import "./style.scss";
export default function ItemTask({ task }) {
  let iconIsAllow = task.truyCap ? (
    <i class="fa fa-unlock-alt green-icon"></i>
  ) : (
    <i class="fa fa-lock red-icon"></i>
  );
  let iconIsFinish = task.hoanThanh ? (
    <i class="fa-solid text-black rounded-full green-icon-bg h-5 w-5 text-center leading-6 fa-check"></i>
  ) : (
    <i class="fa-solid fa-triangle-exclamation red-icon"></i>
  );
  return (
    <div className="flex items-center justify-between item-task">
      <TitleTaskNavigate task={task} />
      <div className=" flex space-x-3 items-center justify-between w-72 ">
        <span>{}</span>
        <span className="tenChuyenDe text-title">
          {task.tongExp} XP + {task.tongCoin} COIN
        </span>
        <div className="space-x-5">
          {/* {iconIsAllow} */}
          {iconIsFinish}
        </div>
      </div>
    </div>
  );
}
