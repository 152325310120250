import React from "react";
import { useNavigate } from "react-router-dom";
import { navigatePageByTask, parentPage } from "../../../routes/utitlity";
import TooltipContentNhiemVu from "../../../components/TooltipContentNhiemVu/TooltipContentNhiemVu";
let btnCss = {
  background:
    "radial-gradient(226.46% 280.26% at 41.98% 268.42%, #FF0C0C 8.4%, #301D09 89.92%) ",
  borderRadius: "11px",
};
export default function TitleItemPratice({ item }) {
  const navigate = useNavigate();

  const btnTiepTucHoc = (
    <button
      style={btnCss}
      onClick={() => {
        navigatePageByTask(item, navigate, parentPage.pratice);
      }}
      className="  w-max px-2 text-sub-title  font-medium  bg-main rounded cursor-pointer btn_theme_red "
    >
      Bắt đầu làm hoặc tiếp tục
    </button>
  );
  return (
    <div className=" flex items-center justify-between h-22 border-b-1">
      <div className="flex items-center space-x-3">
        <TooltipContentNhiemVu maLoaiNhiemVu={item.maLoaiNhiemVu} />
        <p className="text-title font-medium m-0 p-0">{item.tenNhiemVu}</p>
      </div>
      {item.complete ? null : btnTiepTucHoc}
    </div>
  );
}
