import { parentPage } from "../routes/utitlity";
import localStorageServ from "../services/locaStorage.service";
import { Trans } from 'react-i18next';

let userId = localStorageServ.userInfo.get()?.id;

export const subItems = [
  {
    key: "1",
    name: <Trans i18nKey='menuLeft.nhiemVu' />,
    icon: <i className="fa fa-book-reader"></i>,
    path: parentPage.task,
  },
  {
    key: "2",
    name: <Trans i18nKey='menuLeft.baiVietNoiBat' />,
    icon: <i className="fa fa-newspaper"></i>,
    path: "/blog/1",
  },
  {
    key: "3",
    name: <Trans i18nKey='menuLeft.chiaSeMienPhi' />,
    icon: <i className="fa fa-star"></i>,
    path: "/workshop",
  },
];
export const dataHeader = [
  {
    key: "1",
    name: <Trans i18nKey='header.trangChu' />,
    path: "/dash-broad",
    item: [],
  },
  {
    key: "1",
    name: <Trans i18nKey='header.loTrinh' />,
    path: "/dash-broad",
    item: [],
  },

  {
    key: "3",
    name: <Trans i18nKey='header.tuongCuaToi' />,
    path: `/profile/${userId}`,
    item: subItems,
  },
  {
    key: "4",
    name: <Trans i18nKey='header.vatPham' />,
    path: `/shop-page`,
    item: subItems,
  },
];
