import { http } from "../utils/config";

const GET_PATH = '/api/monhoc';

const layDanhSachMonHoc = function () {
    return http.get(GET_PATH);
}

const themMonHoc = function (data) {
    return http.post(GET_PATH, data);
}

const suaMonHoc = function (data) {
    return http.put(`${GET_PATH}/${data.id}`, data);
}

const xoaMonHoc = function (id) {
    return http.delete(`${GET_PATH}/${id}`);
}

const GetMonHocsByListIdService = function (ds) {
    return http.post(`${GET_PATH}/getmonhocbylist`, ds)
}

export const monHocService = {
    layDanhSachMonHoc,
    themMonHoc,
    suaMonHoc,
    xoaMonHoc,
    GetMonHocsByListIdService
}
