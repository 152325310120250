import Axios from "axios";
import environment from "../environments/environment";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../redux/reducer/spinnerReducer";
import store from "../redux/store";
import localStorageServ from "./locaStorage.service";
import { API_URL_DOMAIN } from "../utils/urlDomain";

export class AxiosService {
  axios;
  axiosConfig;
  authService;
  constructor(baseURL) {
    this.axios = Axios.create({
      // baseURL: this.getBaseUrl(),
      baseURL: baseURL + "/api",
    });
    this.getAxiosConfig();
    this.baseURL = baseURL;
  }

  // getBaseUrl() {
  //   return this.baseURL + "/api";
  // }

  getAxiosConfig = (_token) => {
    // const token = _token ? _token : localStorageServ.accessToken.get();
    this.axiosConfig = {
      headers: {
        token: localStorageServ.userInfo.get()?.token,
        apiKey: "UPD124yRTWF124QJFweUaCYSECETBERS",
        "Content-Type": "application/json",
      },
    };
  };

  removeAxiosConfig = () => {
    this.axiosConfig = {
      headers: {
        iKeapy: ``,
        "Content-Type": "application/json",
      },
    };
  };

  getMethod(uri, loading = true) {
    return this.handleFlow(this.axios.get(uri, this.axiosConfig), loading);
  }

  postMethod(uri, data, loading = true) {
    return this.handleFlow(this.axios.post(uri, data, this.axiosConfig), loading);
  }

  putMethod(uri, data, loading = true) {
    return this.handleFlow(this.axios.put(uri, data, this.axiosConfig), loading);
  }

  patchMethod(uri, data, loading = true) {
    return this.handleFlow(this.axios.patch(uri, data, this.axiosConfig), loading);
  }

  deleteMothod(uri, loading = true) {
    return this.handleFlow(this.axios.delete(uri, this.axiosConfig), loading);
  }

  handleFlow(method, loading = true) {
    loading && store.dispatch(set_request_spinner_started());
    return new Promise((resolve, reject) => {
      method
        .then((res) => {
          loading && store.dispatch(set_request_spinner_ended());

          resolve({
            data: res.data,
            status: res.status,
            isSuccess: true,
          });
        })
        .catch((err) => {
          loading && store.dispatch(set_request_spinner_ended());

          this.handleError(err);
          reject({
            err: err,
          });
        });
    });
  }

  handleError = (err) => {
    const status = err.response?.status;
    switch (status) {
      case 401:
      case 403:
        window.location.assign("/login");
        break;
      default:
        break;
    }
  };
  //
  axiosInstance = (req) => {
    this.axios(req, this.axiosConfig);
  };
}

/* eslint-disable no-useless-constructor */
// export const AxiosServ = new AxiosService("https://backend.cyberlearn.vn");
export const AxiosServ = new AxiosService("https://api.techx.edu.vn");
export let BASE_URL_CODINGCAMP = "https://api.techx.edu.vn";
export const AxiosServCodingCamp = new AxiosService(BASE_URL_CODINGCAMP);
export const AxiosDemo = new AxiosService(API_URL_DOMAIN);
