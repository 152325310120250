import React, { useEffect, useState } from "react";
import DetailPosted from "./DetailPosted/DetailPosted";
import CommentOfPosted from "./CommentOfPosted/CommentOfPosted";
import WriteComment from "./WriteComment/WriteComment";
import InforUserOfPosted from "./InforUserOfPosted/InforUserOfPosted";
import ListPosted from "./ListPosted/ListPosted";
import RelevantPosted from "./RelevantPosted/RelevantPosted";
import TopListPosted from "./TopListPosted/TopListPosted";
import { useParams } from "react-router-dom";
import servCodingCamp from "../../services/codingCam.service";
let mainPost = {
  id: 3,
  tieuDe: "comment 1",
  noiDung:
    "lists, and data frames. With the coding skills you'll gain in this course, you'll be ready to undertake your own data analysis in R. There are millions of R users worldwide, cementing it as a leading programming language in statistics and data science. Begin your coding journey in one of DataCamp's most popular courses today!",
  tagSkill: "[]",
  trangThai: 0,
  loaiBaiViet: "[]",
  nguoiTao: "Bùi Tiến Dũng",
  nguoiDuyet: "",
  chuyenDeId: 1,
  baiVietCha: 2,
  luotXem: 0,
  luotLike: 0,
  luotTraLoi: 0,
  luotVote: 3,
  bookMark: false,
};
export default function DetailDocumentPage() {
  let { id: idDocument } = useParams();
  const [detailDocument, setDetailDocument] = useState({});
  let {
    id,
    tieuDe,
    noiDung,
    tagSkill,
    trangThai,
    loaiBaiViet,
    nguoiTao,
    nguoiDuyet,
    chuyenDeId,
    baiVietCha,
    luotXem,
    luotLike,
    luotTraLoi,
    luotVote,
    bookMark,
  } = mainPost;
  useEffect(() => {
    servCodingCamp
      .getDocumentByOption(idDocument)
      .then((res) => {
        // console.log("🚀 - file: DocumentPage.js:69 - .then - res", res);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className=" bg-red-5000">
      <div className="c-card-content">
        <button className="bg-sp px-4 py-2 text-white text-sub-title rounded-lg">
          Bài viết của tôi
        </button>
      </div>
      <div className="c-card-content ">
        <div className="flex h-full">
          <div style={{ width: "90%" }} className=" h-full relative">
            <input
              type="text"
              placeholder="Nhập vào tên bài viết hoặc chủ đề tác giả"
              className="h-full w-full border border-black py-2 px-3 outline-none"
            />
            <i class="fa fa-search absolute text-title font-bold right-4 top-1/2 transform -translate-y-1/2"></i>
          </div>
        </div>
      </div>
      <div className="c-card-content flex">
        <div className="c-col-100 pl-0">
          <div className="card-theme">
            <div className="flex">
              <div style={{ width: "5%" }}></div>
              <DetailPosted mainPost={mainPost} />
            </div>
            <CommentOfPosted />
            <WriteComment />
          </div>
        </div>
        {/* <div className="c-col-25 pr-0 ">
          <InforUserOfPosted />
          <ListPosted />
          <RelevantPosted />
          <TopListPosted />
        </div> */}
      </div>
    </div>
  );
}
