import environment from "../environments/environment";

export const getHinhAnh = (url) => {
  return `https://api.techx.edu.vn/images/${url}`;
};

export let getHinhAnhFB = (id) => {
  if (!id) {
    return "https://scontent.fhan4-1.fna.fbcdn.net/v/t1.30497-1/143086968_2856368904622192_1959732218791162458_n.png?stp=dst-png_p120x120&_nc_cat=1&ccb=1-7&_nc_sid=2b6aad&_nc_ohc=M5xBkqsrW0IAX8EvTL1&_nc_ht=scontent.fhan4-1.fna&oh=00_AfDl-0N5MZpbZHaN7rb7khYD3oPBFR9B2LHEpTn4Nnss4Q&oe=65716C78";
  }
  return `https://graph.facebook.com/${id}/picture?type=large`;
};
