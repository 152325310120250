import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { setLabelChart, setStatisChartArr } from "../../../redux/reducer/chartReducer";
import { generateOption, gerateDataSetChart } from "../../../utils/GenerateDataSetChart";
import { getDayName } from "../../../utils/GenerateDay";
import servCodingCamp from "../../../services/codingCam.service";
import localStorageServ from "../../../services/locaStorage.service";
import Static from "./Static";
import { useTranslation } from "react-i18next";
const ChartStatisticTimeLearning = React.memo(() => {
  const [activeIndexFitler, setActiveIndexFiler] = useState(0);
  const [activeChartIndex, setActiveChartIndex] = useState(0);

  const dispatch = useDispatch();
  const userInfo = localStorageServ.userInfo.get();

  const { labelChart, statisChartArr } = useSelector((state) => state.chart);

  const labelChartRedux = labelChart;
  const statisChartRedux = statisChartArr;
  const statisArr = statisChartRedux.map((item) => {
    return { ...item };
  });
  const labeArr = [...labelChartRedux];
  const handleChangeFilter = (activeIndexFitler, activeChartIndex) => {
    let top = activeChartIndex === 1;
    servCodingCamp
      .getDataChartThongKe(activeIndexFitler, top)
      .then((res) => {
        let labels = getDayName(res.data.content[0].lstSoPhut.length, activeIndexFitler);

        dispatch(setStatisChartArr(res.data.content));
        dispatch(setLabelChart(labels));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleChangeFilter(activeIndexFitler, activeChartIndex);
  }, [activeIndexFitler, activeChartIndex]);

  const data = (canvas) => {
    if (statisArr.length) {
      return {
        labels: labeArr,
        datasets: gerateDataSetChart(statisArr, canvas, userInfo,t),
      };
    } else {
      return { labels: labeArr, datasets: [] };
    }
  };
  const { t } = useTranslation()

  return (
    <div className=" h-full justify-between   flex flex-col space-y-2 relative w-full card-theme ">
      <p style={{ color: "#EEA54C" }} className="  text-lg text-title text-left c-card-content">
        {t('trangChu.thongKeThoiGianHoc')}
      </p>

      <div className="  overflow-hidden w-full mx-auto  transform  flex-grow   ">
        <Line
          data={data}
          options={generateOption(statisArr)}
          className="h-full px-3 m-0 w-full "
          plugins={{}}
        />
      </div>
      <Static />
    </div>
  );
});
export default ChartStatisticTimeLearning;
