import { http } from "../utils/config";

const GET_PATH = '/api/chuyende';

const layDanhSachChuyenDe = () => {
    return http.get(GET_PATH);
}

const themChuyenDe = (data) => {
    return http.post(GET_PATH, data);
}
const suaChuyenDe = (data) => {
    console.log(data)
    return http.put(`${GET_PATH}/${data.id}`, data);
}
const xoaChuyenDe = (id) => {
    return http.delete(`${GET_PATH}/${id}`);
}
const getChuyenDeMentorPhuTrach = (mentorId) => {
    return http.get(`${GET_PATH}/chuyendementorphutrach?mentorId=${mentorId}`)
}

const getChuyenDeHocVienDK = ()=>{
    return http.get(`${GET_PATH}/cacChuyenDeHocVienDangKy`)
}

export const chuyenDeService = {
    layDanhSachChuyenDe,
    themChuyenDe,
    suaChuyenDe,
    xoaChuyenDe,
    getChuyenDeMentorPhuTrach,
    getChuyenDeHocVienDK
}
