import React, { useEffect, useState } from "react";
import Axios from "axios";
import HtmlParser from "react-html-parser";
import "./list_blog.css";
import { Pagination } from "antd";
import { useNavigate, useParams } from "react-router-dom";

export const BlogPage = () => {
  let [noiDung, setNoiDung] = useState("");
  let param = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    Axios({
      method: "GET",
      url: "https://cybersoft.edu.vn/category/bai-viet-nen-doc/page/" + param.page,
    }).then((res) => {
      let start = res.data.indexOf("api-start");
      let end = res.data.indexOf("api-end");
      let content = res.data.substring(start, end);
      content = content.replace("api-start", "").replaceAll("href=", " target='_blank' href=");

      // console.log(content);
      setNoiDung(JSON.parse(content));
    });
  }, [param.page]);

  /**q
   *  lay số trang thông qua đếm chuỗi từ API tra ve
   *  tinh toan load pagination = nhan 10 de có so trang
   */
  let count = 0;

  if (noiDung != "") {
    count = noiDung.page.split("").filter((value, index, arr) => {
      return arr.slice(index, index + 9).join("") === "cybersoft";
    }).length;

    if (param.page != 1 && param.page != count) {
      count -= 1;
    }
    count = count * 10;
  }

  return (
    <div className="h-max-content min-h-screen w-full  flex">
      <div className="w-60"></div>
      <div style={{ flexGrow: "1" }} className="h-max-cotent flex flex-col  min-h-screen ">
        {" "}
        <div className="blog_api grid grid-cols-4 xl:grid-cols-3 gap-5 container p-5">
          {noiDung != "" &&
            noiDung.data.map((item) => {
              return (
                <div className=" rounded-lg bg-white">
                  {HtmlParser(item.image)}

                  {HtmlParser(item.title)}

                  {HtmlParser(item.content)}
                </div>
              );
            })}
        </div>
        <Pagination
          className="p-5 text-center"
          total={count}
          onChange={(page, pageSize) => {
            navigate("/blog/" + page);
          }}
        />
      </div>
    </div>
  );
};
