import React, { useEffect, useState } from "react";
// import BaiTapTracNghiem from "../../components/ContentTracNghiemExtra/BaiTapTracNghiem";
import TracNghiem_Start from "../../components/ContentTracNghiemExtra/TracNghiem_Start";
import servCodingCamp from "../../services/codingCam.service";
import { useParams } from "react-router-dom";
import localStorageServ from "../../services/locaStorage.service";
import { typeTrangThaiNop } from "../../components/StepProjectInstruction/util";
import DaChamContent from "./DaChamContent";
import LamLaiContent from "./LamLaiContent";

export default function DetailScreenQuizz() {
  let { chuyenDeId, taskId, monHocId } = useParams();
  let [thongTinBaiTap, setThongTinBaiTap] = useState({});
  function fetchThongTinBaiNop() {
    let data = {
      nguoidungId: localStorageServ.userInfo.get().id,
      chuyenDeId,
      taskId,
      monHocId,
    };
    servCodingCamp
      .getLayThongTinBaiTap(data)
      .then((res) => {
        console.log(res);
        setThongTinBaiTap(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchThongTinBaiNop();
  }, []);
  if (thongTinBaiTap.trangThai == typeTrangThaiNop.DA_CHAM) {
    return <DaChamContent data={thongTinBaiTap} />;
  }
  if (thongTinBaiTap.trangThai == typeTrangThaiNop.LAM_LAI) {
    return <LamLaiContent fetchThongTinBaiNop={fetchThongTinBaiNop} data={thongTinBaiTap} />;
  }

  return <TracNghiem_Start onSuccess={fetchThongTinBaiNop} />;
}
