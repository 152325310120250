import React from "react";

export default function CardDiscussion({ dTopic }) {
  const renderTopics = () => {
    return dTopic.content.map((c) => {
      return (
        <p className="space-x-2 mt-3">
          <span className="text-lg text-pink-violet">{c.name}</span>
          {c.issue ? (
            <button className=" w-7 h-7 text-icon-violet text-white rounded-full text-lg">
              {c.issue}
            </button>
          ) : (
            ""
          )}
        </p>
      );
    });
  };
  return (
    <div className="card-theme p-3 bg-white " style={{ height: 300 }}>
      <div className="flex space-x-2 ">
        <div style={{ width: "10%" }}>{dTopic.icon}</div>
        <div className="w-full">
          <div style={{ width: "100%" }} className="flex justify-between ">
            <span className="text-pink-violet text-lg">{dTopic.title}</span>
            <span className="text-xl text-pink-violet">
              <i style={{ transform: "rotate(90deg)" }} class="fa fa-play"></i>
            </span>
          </div>
          {renderTopics()}
        </div>
      </div>
    </div>
  );
}
