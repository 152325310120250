import { http } from "../utils/config";

const GET_PATH = "/api/groupchat";

const getListGroupChat = () => {
  return http.get(GET_PATH);
};

const addGroupChat = (data) => {
  return http.post(GET_PATH, data);
};

const getGroupChatById = (groupChatId) => {
  return http.get(`${GET_PATH}/${groupChatId}`);
};

const editGroupChat = (groupChatId, data) => {
  return http.put(`${GET_PATH}/${groupChatId || data.id}`, data);
};

const delGroupChat = (groupChatId) => {
  return http.delete(`${GET_PATH}/${groupChatId}`);
};

const getGroupChatByDSChat = (dschat, gftid) => {
  console.log(dschat, gftid)
  return http.get(`${GET_PATH}/getDsChat`, { params: { dschat, gftid } });
};

const getGroupChatByChuyenDeId = (dschat, chuyenDeId) => {
  return http.get(`${GET_PATH}/getDsChatByChuyenDeId`, { params: { dschat, chuyenDeId } });
};

export const groupChatService = {
  getListGroupChat,
  addGroupChat,
  getGroupChatById,
  editGroupChat,
  delGroupChat,
  getGroupChatByDSChat,
  getGroupChatByChuyenDeId
};
