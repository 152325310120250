import React, { useEffect, useState } from "react";

import CollapseTask from "./CollapseTask/CollapseTask";

import servCodingCamp from "../../services/codingCam.service";
import BannerTask from "./Banner/BannerTask";
import { useParams } from "react-router-dom";
import localStorageServ from "../../services/locaStorage.service";
import RankedSystem from "../../components/RankedSystem/RankedSystem";
export default function TaskPageTest() {
  const userInfo = localStorageServ.userInfo.get();
  const [dataTaskPage, setDataTaskPage] = useState({});
  const [dataHoanThanh, setDataHoanThanh] = useState([]);
  let { chuyenDeId } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        // const res = await servCodingCamp.getDataTaskPage(38);
        const res = await servCodingCamp.getDataTaskPage(chuyenDeId);
        let result = res.data.content;
        setDataTaskPage(result);
      } catch (error) {
        window.location.href = "/";
      }
    };
    getData();
  }, [chuyenDeId]);
  useEffect(() => {
    let payload = {
      nguoiDungId: userInfo.id,
      chuyenDeId: chuyenDeId,
      monHocId: 0,
    };
    servCodingCamp
      .postlayDanhSachHoangThanh(payload)
      .then((res) => {
        console.log("😀 - .then - res", res);
        // console.log("hoanthanh", res);
        setDataHoanThanh(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    // <div contenteditable="true" className="w-full h-full">
    <div style={{ maxWidth: 1280 }} className="w-full h-full mx-auto">
      <div className="c-col-full">
        <BannerTask dataTask={dataTaskPage} />
      </div>
      <div className="space-y-5 border border-red-600 ">
        {dataTaskPage?.danhSachMonHoc?.map((item, index) => {
          return <CollapseTask dataContent={item} key={index} index={index} />;
        })}
      </div>
    </div>
  );
}
