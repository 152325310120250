import React from "react";
import { getImgDanhHieu } from "../../pages/DashBroad/OverviewInfoUser/utils";
import classNames from "classnames";
import { Tooltip } from "antd";
import bgColumn from "./columBg.png";
export default function ItemRank({ item, isActive, index }) {
  let height = (5 - index) * 15;
  let heightPercent = 100 - height + "%";
  return (
    <div
      style={{ width: `${10 + index}%` }}
      // style={{ width: `${14}%` }}
      className=" flex items-center flex-col justify-end h-full  relative   "
    >
      <div style={{ height: heightPercent }} className="relative  w-full ">
        <Tooltip
          title={<p className="bg-white text-dark p-2 rounded">{item.ghiChu}</p>}
          placement="right"
        >
          <div
            className="relative z-10 transform -translate-y-20"
            style={{ paddingBottom: index * 50 }}
          >
            <img
              className={classNames("w-full rounded  p-2 ", {
                "card-theme border-2": isActive,
              })}
              dff
              src={getImgDanhHieu(item.hinhDanhHieu)}
              alt=""
            />
            <p className="text-base text-center font-medium text-white w-2/3 mx-auto">
              {item.tenDanhHieu}
            </p>
          </div>
        </Tooltip>

        <img
          src={bgColumn}
          className="h-full w-3/5  object-cover absolute bottom-0  left-1/2 -translate-x-1/2 transform"
          alt=""
        />
      </div>
    </div>
  );
}
