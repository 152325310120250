import { configureStore } from "@reduxjs/toolkit";
import chartReducer from "./reducer/chartReducer";
import signUpReducer from "./reducer/signUpReducer";
import spinnerReducer from "./reducer/spinnerReducer";
import themeReducer from "./reducer/themeReducer";
import authReducer from "./reducer/authReducer";
import testDauVaoSlice from "../pages/TestQuizPage/testDauVaoSlice";
import giftSlice from "../components/ModalConfetti/giftSlice";
import groupChatReducer from "./reducer/groupChatReducer";
import issueReducer from "./reducer/issueReducer-Chat";
import groupForTaskReducer from "./reducer/groupForTaskReducer";
import chuyenDeReducer from "./reducer/chuyenDeReducer-Chat";
import nopBaiReducer from "./reducer/nopBaiReducer-Chat";
import runcodeSlice from "../pages/Runcode/runcodeSlice";
import dashboardSlice from "./reducer/dashboardSlice";
import loTrinhSlice from "../template/HeaderTask/loTrinhSlice";
import stackChatReducer from "./reducer/stackChatReducer";
import taskPageSlice from "./reducer/taskPageSlice";
import vatPhamSlice from "./reducer/vatPhamSlice";
import tuongNhaSlice from "../pages/TuongNhaPage/tuongNhaSlice";

export default configureStore({
  reducer: {
    chart: chartReducer,
    signUp: signUpReducer,
    spinner: spinnerReducer,
    theme: themeReducer,
    authUser: authReducer,
    runcodeSlice: runcodeSlice,
    user: authReducer,
    testDauVaoSlice: testDauVaoSlice,
    phanThuongSlice: giftSlice,
    groupChat: groupChatReducer,
    groupForTask: groupForTaskReducer,
    issue: issueReducer,
    chuyenDe: chuyenDeReducer,
    nopBai: nopBaiReducer,
    tuongNhaSlice: tuongNhaSlice,
    dashboardSlice,
    loTrinhSlice: loTrinhSlice,
    stackChatReducer,
    taskPageSlice,
    vatPhamSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});
