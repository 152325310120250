import React from "react";
import ItemCoin from "../ItemCoin/ItemCoin";
import ToolTipLevel from "../ToolTipLevel/ToolTipLevel";
import ModelContentIcon from "../ModalContentIcon/ModalContentIcon";
import { iconDashBoard } from "../ModalContentIcon/utilities";

export default function BarTichCucKinhNghiem() {
  return (
    <div className="w-80 space-y-2">
      <div className="flex space-x-5 w-full ">
        <ModelContentIcon loai={iconDashBoard.NangDong} />
        <ModelContentIcon loai={iconDashBoard.Xp} />
      </div>
    </div>
  );
}
