import React from "react";
import UserComment from "./UserComment";

const data = {
  title: "# Dự án uber",
  dataComment: [
    {
      avatar: "./img/ngo_diec_pham.png",
      name: "Đỗ Quang Sĩ",
      role: "Menter",
      time: 10,
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. In, voluptas? Earum animi dolor, repudiandae reiciendis est soluta atque dignissimos? Hic totam illo distinctio eius eum beatae mollitia, molestias enim veniam modi minima! Odit repudiandae, cum sunt commodi eius omnis quos sol",
    },
    {
      avatar: "./img/ngo_diec_pham.png",
      name: "Trần Quang Sĩ",
      role: "Menter",
      time: 8,
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. In, voluptas? Earum animi dolor, repudiandae reiciendis est soluta atque dignissimos? Hic totam illo distinctio eius eum beatae mollitia, molestias enim veniam modi minima! Odit repudiandae, cum sunt comm",
    },
    {
      avatar: "./img/ngo_diec_pham.png",
      name: "Trương Tấn Khải",
      role: "Menter",
      time: 7,
      content: "Lorem ipsum dolor",
    },
  ],
};
export default function FrameComment() {
  const renderComments = () => {
    return data.dataComment.map((d, i) => {
      return <UserComment key={i} dataComment={d} />;
    });
  };
  return (
    <div className="h-full  card-theme p-6 space-y-6" style={{ width: "73%" }}>
      <div className="rounded-3xl px-10 py-3 bg-white text-pink-violet text-lg">
        # Dự án uber
      </div>
      <div className="card-theme bg-white p-8 pr-20 ">
        {renderComments()}
        <div className="relative border-2 border-purple-600 rounded-xl mt-10">
          <button
            style={{ transform: "translateY(-50%)" }}
            className="bg-gray-400 text-white rounded-lg w-10 h-10 absolute top-1/2 left-3  "
          >
            <i class="fa fa-plus"></i>
          </button>
          <input
            className="w-full h-full py-4 px-16 outline-none rounded-xl"
            type="text"
            value={"Oke anh em nha"}
          />
          <button
            style={{ transform: "translateY(-50%)" }}
            className=" text-white rounded-lg text-3xl absolute top-1/2 right-3  "
          >
            🙀
          </button>
        </div>
      </div>
    </div>
  );
}
