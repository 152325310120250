import React, { useState } from "react";
import { Drawer, Layout } from "antd";
import { Outlet } from "react-router-dom";
import HeaderTask from "./HeaderTask/HeaderTask";
import { useSelector } from "react-redux";
import { dataHeader } from "./utils";
const { Header, Content, Sider } = Layout;

export default function HomeTemplate() {
  const theme = useSelector((state) => state.theme.theme);
  let root = document.documentElement;
  root.classList.add(theme);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "black",
        }}
        className="flex h-20  fixed top-0 left-0 w-full z-50 items-center  justify-between space-x-5"
      >
        <HeaderTask />
      </Header>
      <Layout>
        <Layout
          style={{
            margin: `80px 0 0 0`,
            padding: "0",
          }}
          className="bg-content"
        >
          <Content>
            <Outlet className="" />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
