import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import servCodingCamp from "../../services/codingCam.service";

const initialState = {
  dataTaskPage: {},
};
export const getDataTaskPageAction = createAsyncThunk(
  "task/getDataTaskPage",
  async (chuyenDeId, thunkAPI) => {
    try {
      const res = await servCodingCamp.getDataTaskPage(chuyenDeId);
      return res.data.content;
    } catch (error) {
      window.location.href = "/";
    }
  }
);
const taskPageSlice = createSlice({
  name: "taskPageSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getDataTaskPageAction.fulfilled]: (state, action) => {
      state.dataTaskPage = action.payload;
    },
  },
});

export const {} = taskPageSlice.actions;
export default taskPageSlice.reducer;
