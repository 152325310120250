import { createSlice } from "@reduxjs/toolkit";
import { baiHocService } from "../../services/baiHocService";
import moment from "moment";
import { issueService } from "../../services/issueService";

const initialState = {
  issueState: {
    id: 0,
    chuyenDeId: 0,
    hocVienId: "",
    ngayTao: "",
    noiDung: "",
    title: "",
    trangThai: "DANG_HO_TRO",
  },
  issuesMentorPhuTrach: [],
  issueHocVienTheoTungChuyenDe: [],
};

const issueReducer = createSlice({
  name: "issueReducer",
  initialState,
  reducers: {
    getIssueAllMentorPhuTrach: (state, action) => {
      state.issuesMentorPhuTrach = [...action.payload];
    },
    setIssueState: (state, action) => {
      console.log(action.payload);
      state.issueState = { ...action.payload };
    },
    getIssueHocVienTheoTungChuyenDe: (state, action) => {
      state.issueHocVienTheoTungChuyenDe = [...action.payload];
    },
  },
});

export const { getIssueAllMentorPhuTrach, setIssueState, getIssueHocVienTheoTungChuyenDe } =
  issueReducer.actions;
export default issueReducer.reducer;

//--------- action async ------------

export const getIssueAllMentorPhuTrachApi = (mentorId) => {
  return async (dispatch) => {
    try {
      const result = await issueService.getAllIssueByMentor(mentorId);
      const action = getIssueAllMentorPhuTrach(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
};
export const createNewIssue = (newIssue) => {
  return async (dispatch) => {
    try {
      const result = await issueService.postIssue(newIssue);
      const action = getIssueAllMentorPhuTrach(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
};
export const getIssueHocVienTheoTungChuyenDeApi = () => {
  return async (dispatch) => {
    try {
      const result = await issueService.getIssueHocVienTheoTungChuyenDeService();
      const action = getIssueHocVienTheoTungChuyenDe(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
};
