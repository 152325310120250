import React from "react";
import { useSelector } from "react-redux";
import { selectThongTinMoRong, selectUserInfor } from "../../redux/reducer/authReducer";
import ProgressLine from "../ProgressLine/ProgressLine";
import { useTranslation } from "react-i18next";

let iconUp = (
  <span style={{ color: "#16FF00" }} className="">
    <i class=" fa fa-long-arrow-alt-up "></i>
  </span>
);
let iconDown = (
  <span style={{ color: "#dc3545" }} className="">
    <i class=" fa fa-long-arrow-alt-down "></i>
  </span>
);

function WrapperIcon({ title, current, total, isUpPercent, color, icon, percentChange }) {
  let percent = Math.floor((current / total) * 100);
  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div className="text-content font-bold space-x-1 flex items-center">
          {icon}
          <span className="whitespace-nowrap">{title}</span>
          <div className="text-sub-content">
            ({current}/{total})
          </div>
        </div>
        <div className="flex items-center space-x-1">
          {isUpPercent ? iconUp : iconDown}
          {percentChange && <span className="text-content">{percentChange}%</span>}
        </div>
      </div>
      <ProgressLine size={1} type={color} percent={percent >= 100 ? 100 : percent} />
    </div>
  );
}

export let IconTichCuc = () => {
  const { phanTramTichCuc, phanTramKinhNghiem, tichCuc, tichCucToiDa } =
    useSelector(selectThongTinMoRong)?.userInfo;
  const { tichCucTang } = useSelector(selectThongTinMoRong)?.phanTramTichCucKinhNghiem;
  const { t } = useTranslation()

  let icon = <i style={{ color: "#ffc107" }} class="fa fa-bolt"></i>;
  return (
    <WrapperIcon
      title={t('header.tichCuc')}
      current={tichCuc}
      total={tichCucToiDa}
      icon={icon}
      color={"red"}
      isUpPercent={tichCucTang}
      percentChange={phanTramTichCuc}
    />
  );
};
export let IconKinhNghiem = () => {
  const { phanTramTichCuc, tichCucTang, phanTramKinhNghiem, kinhNghiem, kinhNghiemToiDa } =
    useSelector(selectThongTinMoRong)?.userInfo;
    const { t } = useTranslation()

  const { kinhNghiemTang } = useSelector(selectThongTinMoRong)?.phanTramTichCucKinhNghiem;
  let icon = (
    <span>
      <i style={{ color: "rgba(0, 209, 255, 1)" }} class="fa fa-star"></i>
    </span>
  );
  return (
    <WrapperIcon
      title={t('header.xp')}
      current={kinhNghiem}
      total={kinhNghiemToiDa}
      color={"yellow"}
      icon={icon}
      isUpPercent={kinhNghiemTang}
      percentChange={kinhNghiemTang}
    />
  );
};
