import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import ButtonNopBai from "../ButtonNopBai/ButtonNopBai";
import { createPayloadNopBai, markTaskAsCompleted } from "../../utils/taskUtils";
import "./style.scss";
import servCodingCamp from "../../services/codingCam.service";
import { typeTrangThaiNop } from "./util";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import localStorageServ from "../../services/locaStorage.service";
import { useTranslation } from "react-i18next";
let inputCaps =
  " bg-gray-50 border border-gray-300 text-gray-900 text-content rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 block";
export default function StepProjectInstruction({
  noiDung,
  orderNumber,
  requireId = 1,
  trangThaiTongBaiTap = {},
  getThongTinBaiTapNop = () => { },
  tongCoin,
  tongExp,
  maLoaiNhiemVu,
  required,
}) {
  const { t } = useTranslation()

  let user = localStorageServ.userInfo.get();
  let indexThongTinNop = trangThaiTongBaiTap.noiDung.findIndex(
    (thongTinNop) => thongTinNop.requireId == required.requireId
  );

  let thongTinBaiTap = trangThaiTongBaiTap?.noiDung[indexThongTinNop] || {};
  let { chuyenDeId, taskId, monHocId } = useParams();
  let { baiLam, diem, nhanXet, trangThai, id: nopBaiId } = thongTinBaiTap;

  // convert heart to input html
  let handleNopLaiBai = () => {
    const inputs = document.querySelectorAll(`#noiDungCaps_${orderNumber} #input_box`);
    // các link input render then trái tim
    const baiLam = [];
    let isValid = true;
    Array.from(inputs).forEach((input) => {
      if (input.value.trim() === "") {
        isValid = false;
      }
      baiLam.push(input.value);
    });
    if (isValid == false) {
      toast.error("Bạn vui lòng nộp đầy đủ các link theo yêu cầu");
      return;
    }
    // validate
    let payload = {
      nopBaiId,
      baiLam: JSON.stringify(baiLam),
    };
    servCodingCamp
      .postNopLaiBaiTap(payload)
      .then((res) => {
        getThongTinBaiTapNop();
        toast.success("Bạn đã cập nhật bài tập thành công");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let handleNopBai = () => {
    const inputs = document.querySelectorAll(`#noiDungCaps_${orderNumber} #input_box`);
    // các link input render then trái tim
    const baiLam = [];

    let isValid = true;
    Array.from(inputs).forEach((input) => {
      console.log("😀 - Array.from - input.value", input.value);
      if (input.value.trim() === "") {
        isValid = false;
      }
      baiLam.push(input.value);
    });
    if (isValid == false) {
      toast.error("Bạn vui lòng nộp đầy đủ các link theo yêu cầu");

      return;
    }

    let payload = createPayloadNopBai(
      taskId,
      maLoaiNhiemVu,
      JSON.stringify(baiLam),
      requireId,
      chuyenDeId,
      monHocId
    );
    servCodingCamp
      .postNopBaiTap(payload)
      .then((res) => {
        getThongTinBaiTapNop();
        toast.success("Bạn đã nộp bài tập thành công");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let addInputToLayout = (noiDung) => {
    let arrayBaiLam = JSON.parse(baiLam || "[]");
    if (noiDung.includes("&hearts;")) {
      // list input cần convert
      var currentIndex = 0;

      var noiDung = noiDung.replace(/&hearts;/g, function (match) {
        if (baiLam?.length) {
          var replacement = ` <p class="text-red-600 text-xl">Bài của bạn đang được chấm</p>  <input type="text"  class='practice_fill_input text-black ${inputCaps}' readonly placeholder='${arrayBaiLam[currentIndex] || ""
            }'  id='input_box'  /> `;
          currentIndex++;

          return replacement;
        }
        var replacement = `<input type="text" class='practice_fill_input text-black ${inputCaps}' placeholder='${arrayBaiLam[currentIndex] || ""
          }'  id='input_box'  /> `;
        currentIndex++;
        return replacement;
      });
    }
    //
    return HtmlParser(noiDung);
  };
  let handleBatDauLamBaiTap = () => {
    let data = { chuyenDeId, taskId, monHocId, nguoiDungId: user.id };
    servCodingCamp
      .batDauLamBaiTap(data)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let renderTrangThai = () => {
    if (trangThaiTongBaiTap.trangThai == typeTrangThaiNop.CHUA_LAM) {
      return <></>;
    }
    if (trangThaiTongBaiTap.trangThai == typeTrangThaiNop.HET_HAN) {
      return <></>;
    }
    switch (trangThai) {
      case typeTrangThaiNop.DA_CHAM: {
        return (
          <div className="text-yellow card-theme p-2 rounded">
            <p>
              {" "}
              {t('chiTietNhiemVu.diemCuaBan')}
              : <span className="font-medium">
                {diem}
              </span>
              {t('chiTietNhiemVu.diem')}

            </p>
            {nhanXet && <p>
              {t('chiTietNhiemVu.nhanXet')} {HtmlParser(nhanXet)}</p>}
          </div>
        );
      }
      case typeTrangThaiNop.NOP_LAI: {
        return (
          <div>
            <div className="text-yellow">
              {t('chiTietNhiemVu.banCanBoSungLai')}
            </div>
            <ButtonNopBai text="Cập nhật" onClick={handleNopLaiBai} />
          </div>
        );
      }
      case typeTrangThaiNop.CHO_CHAM: {
        return <div className=""></div>;
      }
      case typeTrangThaiNop.HET_HAN: {
        return <div className=""></div>;
      }
      default:
        return <ButtonNopBai onClick={handleNopBai} />;
    }
  };
  return (
    <div className=" h-full w-full">
      <div
        style={{
          background:
            " radial-gradient(158.28% 164.47% at 100% 135.53%, #F8DB77 8.4%, #ECA937 31.76%, #FFC94C 51.58%) ",
        }}
        className=" w-max flex h-8 rounded space-x-5 p-2 ml-auto items-center text-black"
      >
        <div className="text-black">
          <i className="fas fa-gem mr-2"></i>
          <span>{tongExp} {t('header.xp')}</span>
        </div>
        <div className="text-black">
          <i className="fas fa-coins mr-2"></i>
          <span>{tongCoin} {t('chiTietNhiemVu.coin')}</span>
        </div>
      </div>
      <div>
        <p
          id={`noiDungCaps_${orderNumber}`}
          className="c-card-content text-content  p-0  stepListRequireNopBai leading-7"
        >
          {addInputToLayout(noiDung)}
        </p>
      </div>
      {/* !!!!!!! đã chấm rồi thì ko cho nộp lại */}

      <div className=" p-5 text-xl text-left ">{renderTrangThai()}</div>
    </div>
  );
}
