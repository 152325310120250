import React, { useEffect, useState } from "react";
import ModelContentIcon from "../../components/ModalContentIcon/ModalContentIcon";
import { iconBestPractice } from "../../components/ModalContentIcon/utilities";
import ContentBestPractice from "./ContentBestPractice/ContentBestPractice";
import servCodingCamp from "../../services/codingCam.service";

const dataFake = {
  bgPractice: "./img/background-practice.png",
  chuDe: [
    {
      type: "html",
      name: "HTML",
    },
    {
      type: "css",
      name: "CSS",
    },
    {
      type: "react",
      name: "React Js",
    },
    {
      type: "dotnet",
      name: "Dot Net",
    },
    {
      type: "python",
      name: "Python",
    },
  ],
};

export default function BestPracticePage() {
  const [dataBestPractice, setDataBestPractice] = useState({
    danhSachBestPractice: [],
    danhSachDaLuu: [],
  });
  useEffect(() => {
    servCodingCamp
      .getBestPractice(1)
      .then((res) => {
        setDataBestPractice(res.data.content);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const extractModalSkill = (name) => {
    if (name === "python") {
      return <ModelContentIcon loai={iconBestPractice.SkillPyThon} />;
    } else if (name === "dotnet") {
      return <ModelContentIcon loai={iconBestPractice.SkillDotNet} />;
    } else if (name === "react") {
      return <ModelContentIcon loai={iconBestPractice.SkillReactJs} />;
    } else if (name === "html") {
      return <ModelContentIcon loai={iconBestPractice.SkillHtml} />;
    } else if (name === "css") {
      return <ModelContentIcon loai={iconBestPractice.SkillCss} />;
    }
  };
  const renderAllBestPratice = () => {
    return dataBestPractice.map((c) => {
      return (
        <div className="flex font-medium items-center px-4 py-2 border rounded-lg">
          <span>{c.tenBaiHoc}</span>
          <div className="w-5 h-5 mx-1 inline-block  bg-white  rounded-xl">
            {/* {extractModalSkill(c.type)} */}
          </div>
        </div>
      );
    });
  };
  return (
    <main>
      <div className="c-col-full h-96 ">
        <img src={dataFake.bgPractice} alt="" className="w-full h-full object-cover rounded" />
      </div>
      <div className="c-col-full">
        <div className="card-content flex items-center space-x-3">
          <span className="text-title font-bold text-white">Chủ Đề : </span>
          <p className="text-sub-title flex space-x-4">{/* {renderAllBestPratice()} */}</p>
        </div>
      </div>
      <ContentBestPractice dataBestPractice={dataBestPractice} />
    </main>
  );
}
