import React, { useEffect, useState } from "react";
import InformationProject from "../../components/ListRequireNopBai/InformationProject";
import { loaiNhiemVu, createPayloadLayThongTinBaiTap } from "../../utils/taskUtils";
import { useParams } from "react-router-dom";
import servCodingCamp from "../../services/codingCam.service";
import ListRequireNopBai from "../../components/ListRequireNopBai/ListRequireNopBai";
import ContentTabCol from "../../components/ContentTabCol/ContentTabCol";
import ModalClickZoomImg from "../../components/ModalClickZoomImg/ModalClickZoomImg";

export default function DetailScreenCapstone() {
  let { chuyenDeId, taskId, monHocId } = useParams();
  const [detailPage, setDetailPage] = useState({});
  const [trangThaiTongBaiTap, setTrangThaiTongBaiTap] = useState({
    noiDung: [],
  });

  const maLoaiNhiemVu = detailPage.maLoaiNhiemVu;
  console.log("😀 - maLoaiNhiemVu", maLoaiNhiemVu);

  const [listTabPane, setListTabPane] = useState([]);
  let listRequire = [];
  if (detailPage.noiDungNhiemVu?.noiDungBaiTap?.noiDung) {
    listRequire = JSON.parse(detailPage.noiDungNhiemVu.noiDungBaiTap.noiDung);
  }
  let getListPanel = (trangThaiTongBaiTap) => {
    let listTabPane = listRequire.map((required, index) => {
      // kiểm tra bài đã nộp hay chưa

      // -1 => false => chưa nộp

      return {
        label: required.requireId,
        key: required.requireId,
        children: (
          <ListRequireNopBai
            monHocId={monHocId}
            taskId={taskId}
            chuyenDeId={chuyenDeId}
            trangThaiTongBaiTap={trangThaiTongBaiTap}
            required={required}
            orderNumber={index}
            getThongTinBaiTapNop={getThongTinBaiTapNop}
            maLoaiNhiemVu={maLoaiNhiemVu}
            tongCoin={detailPage.tongCoin}
            tongExp={detailPage.tongExp}
          />
        ),
      };
    });
    setListTabPane(listTabPane);
  };
  let getThongTinBaiTapNop = () => {
    let payload = createPayloadLayThongTinBaiTap(taskId, chuyenDeId, monHocId);
    servCodingCamp
      .getLayThongTinBaiTapNop(payload)
      .then((res) => {
        setTrangThaiTongBaiTap(res.data.content);
        getListPanel(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        console.log(res);

        setDetailPage(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getThongTinBaiTapNop();
  }, [detailPage.id]);

  return (
    <div id="containerNoiDungBaiTap" className="flex w-full flex-wrap">
      <ModalClickZoomImg />
      <div className="c-col-full">
        <InformationProject
          trangThaiTongBaiTap={trangThaiTongBaiTap}
          maNhiemVu={maLoaiNhiemVu}
          detail={detailPage}
        />
      </div>
      <div className="tabCol w-full">
        <ContentTabCol data={listTabPane} />
      </div>
    </div>
  );
}
