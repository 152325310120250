import React, { useEffect, useState } from "react";

import moment from "moment";
import { FORTMAT_DAY, getCurrentWeekDay } from "./utils";
import classNames from "classnames";
import LootBox from "./LootBox/LootBox";
import servCodingCamp from "../../../services/codingCam.service";
import { setPhanThuong } from "../../../components/ModalConfetti/giftSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { updateThongTinMoRongAction } from "../../../redux/reducer/authReducer";
import ModalConfetti from "../../../components/ModalConfetti/ModalConfetti";
import ModalHeatMap from "./ModalHeatMap/ModalHeatMap";
import { useTranslation } from "react-i18next";

export default function ChuoiDiemDanh() {
  const { t } = useTranslation()

  const [listDiemDanh, setListDiemDanh] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    fetchListDiemDanh();
  }, []);
  function fetchListDiemDanh() {
    servCodingCamp
      .getLayDanhSachDiemDanh()
      .then((res) => {
        let result = res.data.content.slice(-7).map((item) => {
          let day = moment(item.ngayDiemDanh, "DD/MM/YYYY HH:mm:ss").format(FORTMAT_DAY);
          return day;
        });
        setListDiemDanh(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function postDiemDanh() {
    servCodingCamp
      .postDiemDanh()
      .then((res) => {
        if (res.data.content) {
          fetchListDiemDanh();
          dispatch(setPhanThuong(res.data.content));
          toast.success("Điểm danh thành công");
          dispatch(updateThongTinMoRongAction());
        } else {
          // message.error("Điểm danh không thành công");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const renderDayOfTheWeek = () => {
    const currentWeek = getCurrentWeekDay(listDiemDanh);

    let listDays = [];
    listDays = currentWeek.map((d, index) => {
      let activeColor = "rgb(255, 201, 76)";
      let notActioveColor = " linear-gradient(180deg, #66676C 31.25%, #353034 100%)";
      if (d.isToday && !d.isActive) {
        // if (d.isToday) {
        return (
          <LootBox
            postDiemDanh={postDiemDanh}
            isActive={d.isActive}
            currentWeek={currentWeek}
            day={d}
            index={index}
          />
        );
      }
      if (d.isToday && d.isActive) {
        return <ModalHeatMap currentWeek={currentWeek} index={index} day={d} />;
      }
      return (
        <div style={{ width: "13%" }} className="relative h-10">
          <span
            style={{
              background: d.isActive ? activeColor : notActioveColor,
              border: d.isActive ? "1px solid #FFC94C" : "none",
              color: d.isActive ? "black" : "gray",
            }}
            className={classNames(
              "h-8 w-full relative top-0 left-0    font-bold flex items-center justify-center  ",
              {
                "rounded-tl-xl rounded-bl-xl": index == 0,
              },
              {
                "rounded-tr-xl rounded-br-xl": index == currentWeek.length - 1,
              }
            )}
          >
            {d.letter}
          </span>
        </div>
      );
    });
    return listDays;
  };

  return (
    <div className="   w-full c-card-content">
      <ModalConfetti />
      <div className="  items-center text-sub-title font-medium space-x-2 pr-0 ">
        <ul className=" pl-4">
          <li>
            <span>{t('trangChu.chuoiDiemDanh')}: </span>
            <span>
              {getCurrentWeekDay(listDiemDanh).filter((day) => day.isActive).length}
              /7
            </span>
          </li>
        </ul>
      </div>
      <div className=" pr-0 ">
        <div className=" p-2 space-y-2  ">
          <div className=" flex items-center text-title font-bold space-x-2"></div>
          <div className="flex justify-between">{renderDayOfTheWeek()}</div>
        </div>
      </div>
    </div>
  );
}
