import React from "react";
import ContentTab from "../../../components/ContentTab/ContentTab";
import ListCardBestPracTice from "./ListCardBestPracTice";

export default function ContentBestPractice({ dataBestPractice }) {
  let { danhSachDaLuu, danhSachBestPractice } = dataBestPractice;

  const dataTab = [
    {
      label: `Tất Cả`,
      key: "1",
      children: (
        <div>
          <ListCardBestPracTice data={danhSachBestPractice} />
        </div>
      ),
    },
    {
      label: `Của Tôi`,
      key: "2",
      children: (
        <ListCardBestPracTice
          isSave={true}
          data={danhSachBestPractice.filter((item) => {
            return danhSachDaLuu.includes(item.id);
          })}
        />
      ),
    },
  ];
  return (
    <div className="c-col-full">
      <ContentTab
        paddingHeaderX={10}
        paddingHeaderY={5}
        paddingTabItemX={8}
        paddingTabItemY={5}
        data={dataTab}
      />
    </div>
  );
}
