import React from "react";
import DiscussionTopic from "./DiscussionTopic/DiscussionTopic";
import FrameComment from "./FrameComment/FrameComment";
const dataFake = {
  dataDiscussion: [
    {
      title: "Chuyên đề HTMLCSS Formum",
      icon: <i class="fa fa-anchor text-2xl text-pink-violet"></i>,
      content: [
        { name: "# Câu hỏi thường gặp", issue: 10 },
        { name: "# Thảo luận chung", issue: 10 },
      ],
    },
    {
      title: "Capstone project",
      icon: <i class="fa fa-code-branch text-2xl text-pink-violet"></i>,
      content: [
        { name: "# Dự án uber", issue: 10 },
        { name: "# Dự án ABC", issue: 0 },
      ],
    },
  ],
};
export default function Discussion() {
  return (
    <div className="flex w-full h-full">
      <div className="p-6 space-y-6 w-full h-full">
        <div style={{ height: 740 }} className="flex space-x-7">
          <DiscussionTopic data={dataFake.dataDiscussion} />
          <FrameComment />
        </div>
      </div>
    </div>
  );
}
