import React, { useEffect, useState } from "react";
import { getImgDanhHieu } from "./utils";
import Modal from "react-bootstrap/Modal";

import RankedSystem from "../../../components/RankedSystem/RankedSystem";
import servCodingCamp from "../../../services/codingCam.service";

export default function ModalDanhHieu({ danhHieu }) {
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    servCodingCamp
      .layMocHuyHieu()
      .then((result) => {
        setList(result.data.content);
      })
      .catch((err) => {});
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div>
        <img
          onClick={showModal}
          src={`${getImgDanhHieu(danhHieu?.hinhDanhHieu)}`}
          className="w-20 cursor-pointer"
          alt=""
        />
      </div>
      <Modal
        bsPrefix="modal-ranking modal"
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
        size="xl"
      >
        <Modal.Body className="modal-body-gradient">
          <RankedSystem list={list} currentRank={danhHieu} />
        </Modal.Body>
      </Modal>
    </>
  );
}
