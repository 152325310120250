import React, { useEffect, useState } from "react";
import servUserWall from "../../../services/userWall.service";
import { useParams } from "react-router-dom";
import Item from "./Item";

export default function ListFollow({ trangThai }) {
  const [listFllow, setListFllow] = useState([]);
  let { userId } = useParams();
  useEffect(() => {
    servUserWall
      .danhSachFllow(userId, trangThai)
      .then((res) => {
        console.log(res);
        setListFllow(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [trangThai, userId]);
  return (
    <div className="space-y-2">
      {listFllow.length == 0 && <p className="text-title">Chưa có nội dung</p>}
      { listFllow.map((item) => {
        return <Item user={item} key={item?.id} />;
      })}
    </div>
  );
}
