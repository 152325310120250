import React from "react";

let imgSrc =
  "https://drive.google.com/file/d/19VapfW9zD8mGK9OBtQbPJ39F4Jl5rfAj/view?usp=sharing";
export default function Banner({ data }) {
  return (
    <div className="card-theme">
      {/* <Banner /> */}
      <div className="c-card-content ">
        <div className="w-full bg-gray-500 rounded-xl h-96"></div>

        <h2 className="text-title text-center">{data.tenBaiHoc}</h2>
        <div className="c-card-content space-y-5">
          <p className="flex items-center space-x-1 font-bold text-content">
            <i class="fa fa-eye"></i>
            <span>{data.luotXem}</span>
          </p>
          <p className="space-x-5 text-title ">
            <i class="fab fa-html5"></i>
            <i class="fab fa-html5"></i>
          </p>
        </div>
      </div>
    </div>
  );
}
