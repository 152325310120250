import React from "react";
import circleBg from "./circleBg.png";

export default function AvatarUserCicle({ avatar, height, width }) {
  return (
    <div
      style={{
        width,
        height,
      }}
      className="relative flex-shrink-0"
    >
      <img src={circleBg} alt="" className="absolute scale-125 transform" />

      <img
        src={avatar}
        alt=""
        className="w-full h-full  rounded-full object-cover absolute z-10 "
      />
      <div
        style={{
          transform: `translate(-50%,20%)`,
        }}
        className="absolute -bottom-3 left-1/2 z-20 "
      ></div>
    </div>
  );
}
