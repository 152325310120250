import { Breadcrumb } from "antd";
import React, { useEffect } from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routesBreadcrumb } from "./configBreadCrumb";
import { useSelector } from "react-redux";
import { selectListLoTrinh } from "../../template/HeaderTask/loTrinhSlice";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";
import OutlineTaskList from "./OutlineTaskList";
import { useState } from "react";
import servCodingCamp from "../../services/codingCam.service";
import { NavLink } from "react-router-dom";
let BreadCrumbNav = ({}) => {
  let { chuyenDeId, taskId, monHocId } = useParams();
  let [tenTask, setTenTask] = useState();
  useEffect(() => {
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        setTenTask(res.data.content.tenNhiemVu);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [chuyenDeId, taskId, monHocId]);
  const list = useSelector(selectListLoTrinh);
  let loTrinh = list.find((item) => item.chuyenDeId == chuyenDeId);
  const breadcrumbs = useBreadcrumbs(routesBreadcrumb);
  let renderBeadcrumbs = () => {
    if (breadcrumbs.length == 3) {
      return (
        <>
          <Breadcrumb.Item>
            <NavLink key={"1"} to={"/"}>
              <span className="text-yellow">{breadcrumbs[0].breadcrumb}</span>
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              className={classNames({
                "text-white": breadcrumbs.length == 3,
                "text-yellow": breadcrumbs.length > 3,
              })}
            >
              {breadcrumbs[1].breadcrumb}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="text-white">{loTrinh?.tenChuyenDe}</span>
          </Breadcrumb.Item>
        </>
      );
    }
    return (
      <>
        <Breadcrumb.Item>
          <NavLink key={"1"} to={"/"}>
            <span className="text-yellow">{breadcrumbs[0].breadcrumb}</span>
          </NavLink>
          {/* </NavLink> */}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <NavLink key={breadcrumbs[1].pathname} to={"/task-page/" + chuyenDeId}>
            <span className="text-yellow">{breadcrumbs[1].breadcrumb}</span>
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span className="text-white">{tenTask}</span>
        </Breadcrumb.Item>
      </>
    );
  };
  return (
    <div className="z-50 h-8 top-16 right-0 flex  pl-2 bg-black   fixed w-full  items-center justify-between px-5 ">
      <div>{renderBeadcrumbs()}</div>
      {breadcrumbs.length > 3 && <OutlineTaskList />}
    </div>
  );
};
export default BreadCrumbNav;
