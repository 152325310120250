import { Button, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

export default function Navigate_Footer_ViewAnsers({
  current,
  total,
  handleChangeCurrentQuestion,
}) {
  const { t } = useTranslation()

  const dispatch = useDispatch();
  const percent = Math.floor((current / total) * 100);
  return (
    <div
      style={{
        background:
          "radial-gradient(91.01% 91.01% at 16.52% 42.45%, #ffc94c 0%, #eebe47 23%, #edb720 52%",
      }}
      className=" bg-white flex items-center h-16 w-full justify-center space-x-10 px-16 border-none rounded-2xl"
    >
      <div className="flex items-cente space-x-5 justify-center max-w-screen-md w-full">
        <Progress
          step={total}
          percent={percent}
          className="w-full"
          showInfo={false}
          strokeWidth={15}
          strokeColor={{
            // "0%": "#4A00E0",
            "0%": "#301D09",
            "100%": "#FF0C0C",
            // "100%": "#8E2DE2",
          }}
          // trailColor="rgba(34, 34, 96,0,1)"
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        <span className="font-bold flex-shrink-0 text-content ">
          {current}/{total} câu
        </span>
      </div>
      <Button
        onClick={() => {
          current > 1 && handleChangeCurrentQuestion(-1);
        }}
        className={
          "   font-bold px-8 rounded  flex items-center h-10 flex-shrink-0   focus:border-blue-theme   bg-white text-blue-theme shadow-lg border-blue-theme border-2 border-opacity-50 hover:shadow-xl hover:border-opacity-100  transition duration-200"
        }
      >
        {t('chiTietNhiemVu.quayLai')}

      </Button>
      <Button
        onClick={() => {
          current < total && handleChangeCurrentQuestion(+1);
        }}
        className={
          "   duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-xl  btn-theme bg-white text-black"
        }
      >
        {t('chiTietNhiemVu.cauTiepTheo')}

      </Button>
    </div>
  );
}
