import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import servCodingCamp from "../../../services/codingCam.service";
import { useTranslation } from "react-i18next";
export default function ThongKeTienDo({ isChuyenDeIntern }) {
  const [thongKe, setThongKe] = useState({});
  let { chuyenDeId } = useParams();
  const { t } = useTranslation()

  useEffect(() => {
    servCodingCamp
      .getThongKeTask_ThoiGian(chuyenDeId)
      .then((res) => {
        setThongKe(res.data.content[res.data.content.length - 1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderDetailTask = () => {
    let iconArray = [
      <i className=" fa fa-clipboard-list"></i>,
      <i className=" fa fa-file-alt"></i>,
      <i className=" fa fa-code"></i>,
      <i className=" fa fa-hammer"></i>,
      <i className=" fa fa-newspaper"></i>,
      <i className=" fa fa-laptop"></i>,
      <i className=" fa fa-file-alt"></i>,
      <i className=" fa fa-user-friends"></i>,
      <i className=" fa fa-calendar-check"></i>,
    ];
    return thongKe.thongKeChiTiet
      ?.filter(({ tongSoLuong }) => tongSoLuong > 0)
      .map(({ tieuDe, tongSoLuong, soLuongHoanThanh, thoiGian }, index) => {
        return (
          <div className="flex justify-between px-1 space-x-1">
            <div className="flex items-center space-x-3  text-content ">
              <p className="text-left">
                <span className="inline-block w-10 text-center">{iconArray[index]}</span>
                {tieuDe} ({soLuongHoanThanh}/{tongSoLuong})
              </p>
            </div>
            <p className="text-xs text-gray-200">{thoiGian} phút</p>
          </div>
        );
      });
  };

  return (
    <div className="item_banner space-y-4 w-full p-2 py-5">
      <p className="text-title">{t('loTrinh.thongKeNhiemVuHoanThanh')}</p>
      <div className=" w-full  overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-white scrollbar-thumb-rounded-xl  scrollbar-track-rounded-xl  pr-4  grid grid-cols-2  gap-1 gap-y-2  ">
        {renderDetailTask()}
      </div>
    </div>
  );
}
