export const loaiNhiemVu = {
  VIDEO: "VIDEO",
  BAITAP: "BAITAP",
  QUIZ: "QUIZ",
  CAPS: "CAPS",
};
export function getThongKetChiTietChuyenDe(chuyenDe) {
  if (!chuyenDe) {
    return [];
  }

  let tongMonHoc = chuyenDe.danhSachMonHoc?.length;
  let tongVideo = 0;
  let tongBaiTap = 0;
  let tongNhiemVu = 0;
  let tongTracNghiem = 0;
  let tongRunCode = 0;
  let tongDuAnCaps = 0;

  let phanThuong = {
    tongCoin: 0,
    tongExp: 0,
  };
  chuyenDe.danhSachMonHoc?.forEach((monHoc) => {
    tongNhiemVu += monHoc.danhSachNoiDung?.length;
    monHoc.danhSachNoiDung?.forEach((noiDung) => {
      phanThuong.tongCoin += noiDung.tongCoin;
      phanThuong.tongExp += noiDung.tongExp;
      let { maLoaiNhiemVu } = noiDung;
      if (maLoaiNhiemVu == loaiNhiemVu.VIDEO) {
        noiDung.noiDungNhiemVu?.forEach((chuongHoc) => {
          // tongVideo
          tongVideo += chuongHoc.noiDungBaiHoc?.length;
        });
      }
      if (maLoaiNhiemVu == loaiNhiemVu.BAITAP) {
        tongBaiTap++;
      }
      if ((maLoaiNhiemVu = loaiNhiemVu.QUIZ)) {
        tongTracNghiem++;
      }
      if ((maLoaiNhiemVu = loaiNhiemVu.CAPS)) {
        tongDuAnCaps++;
      }
    });
  });

  const arrayItemNoiDungChuyenDe = [
    {
      total: tongMonHoc,
      icon: <i class="fab fa-leanpub"></i>,
      contentText: "Môn học",
      about: <div>{tongMonHoc} môn học</div>,
      type: "monhoc",
    },
    {
      total: tongVideo,
      icon: <i class="fa fa-video"></i>,
      contentText: "Video",
      about: <div>{tongVideo} video</div>,
      type: "video",
    },
    {
      total: tongNhiemVu,
      icon: <i class="fa fa-tasks"></i>,
      contentText: "Nhiệm vụ",
      about: <div>{tongNhiemVu} nhiệm vụ</div>,
      type: "nhiemvu",
    },
    {
      total: tongTracNghiem,
      icon: <i class="fa fa-pencil-ruler"></i>,
      contentText: "Hệ thống trắc nghiệm hiện đại",
      about: <div>{tongTracNghiem} trắc nghiệm</div>,
      type: "tracnghiem",
    },
    {
      total: tongRunCode,
      icon: <i className="fas fa-laptop-code"></i>,
      contentText: "Hệ thống run-code",
      about: <div>{tongRunCode} Live coding </div>,
      type: "runcode",
    },
    {
      total: tongDuAnCaps,
      icon: <i className="fas fa-flag-checkered"></i>,
      contentText: "Dự án",
      about: <div>{tongDuAnCaps} dự án</div>,
      type: "capstone",
    },
  ];
  return { arrayItemNoiDungChuyenDe, phanThuong };
}

/**
 * đây nhé ku [
    { value: "VIDEO", label: 'Video', time: "phút" },
    { value: "BAITAP", label: 'Bài tập nộp', time: "giờ" },
    { value: "DISCUSS", label: 'Thảo luận', time: "giờ" },
    { value: "CAPS", label: 'Capstone', time: "ngày" },
    { value: "DUAN", label: 'Dự án cuối khóa', time: "ngày" },
    { value: "TAILIEU", label: 'Tài liệu', time: "phút" },
    { value: "QUIZ", label: 'Trắc nghiệm', time: "phút" },
    { value: "RUNCODES", label: 'RUNCODE', time: "phút" },
]
 */

export let getThongKetChiTietMonHoc = function (monHoc, hoanThanh) {
  return;
};
