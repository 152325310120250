import React, { useEffect } from "react";
import servCodingCamp from "../../services/codingCam.service";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { NavLink } from "react-router-dom";
import "./style.scss";
import classNames from "classnames";
import { checkActiveNavlink, parentPage } from "../../routes/utitlity";
import { useDispatch, useSelector } from "react-redux";
import { selectListLoTrinh, setListLoTrinh } from "./loTrinhSlice";
import { useTranslation } from "react-i18next";
export default function DropdownLoTrinh() {
  const { t } = useTranslation()

  const list = useSelector(selectListLoTrinh);
  let dispatch = useDispatch();
  let { chuyenDeId: id } = useParams();
  useEffect(() => {
    servCodingCamp
      .layLotrinhUser()
      .then((res) => {
        dispatch(setListLoTrinh(res.data.content));
        // setListLoTrinh(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const menu = (
    <Menu className="p-4 space-y-5 rounded dropdown-lo-trinh">
      {list?.map(({ tenChuyenDe, chuyenDeId }) => {
        return (
          <div key={chuyenDeId} className="shadow cursor-pointer">
            <NavLink
              to={`/${parentPage.task}/${chuyenDeId}`}
              className={classNames({
                active: chuyenDeId == id,
              })}
            >
              {tenChuyenDe}
            </NavLink>
          </div>
        );
      })}
    </Menu>
  );

  return (
    <div>
      <Dropdown arrow overlay={menu}>
        <p className="p-1 px-3 flex  justify-center text-content text-sm items-center rounded-xl z-10 relative  duration-500 text-button uppercase whitespace-nowrap cursor-pointer space-x-2">
          <span className={checkActiveNavlink(parentPage.task) ? "active-link" : ""}>
            {" "}
            {t('header.loTrinh')}
            <i class="pl-2 fa fa-caret-down "></i>
          </span>
        </p>
      </Dropdown>
    </div>
  );
}
