import { Modal } from "antd";
import React, { useState } from "react";

export default function UserGuilde() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    stopVideo();
  };
  const youtubeLinkToEmbed = (link) => {
    var videoId = link.split("v=")[1];
    var ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition != -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return videoId;
  };
  const stopVideo = () => {
    var iframe = document.getElementById("huongdan");
    var src = iframe.src;
    iframe.src = src;
  };
  return (
    <>
      <Modal
        title="Video hướng dẫn học tập"
        width="70vw"
        height="90vh"
        style={{ top: 10 }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ minWidth: "40vw", minHeight: "40vh" }} className="w-full">
          <iframe
            id="huongdan"
            width="1280"
            height="720"
            src="https://www.youtube.com/embed/v9fnWZH2934"
            // title="Hướng dẫn hệ thống Live Coding"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>
      <div className=" cursor-pointer hover:text-yellow-300">
        <button
          onClick={showModal}
          className=" text-white  focus:outline-none btn_theme_red p-0 px-2 h-8 leading-8"
        >
          {" "}
          <i className="fa fa-question mr-"></i> Cách dùng
        </button>
      </div>
    </>
  );
}
