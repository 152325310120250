import React from "react";
import Lottie from "lottie-react";
import bgAnimate from "./success.json";

export default function DaChamContent({ data }) {
  return (
    <div style={{ height: "80vh" }} className="flex flex-col items-center ">
      <Lottie loop={false} animationData={bgAnimate} style={{ width: "70%", height: "70%" }} />
      <p className="text-title text-5xl transform -translate-y-20"> Điểm của bạn: {data.diem}</p>
    </div>
  );
}
