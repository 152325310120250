import React, { useEffect, useState } from "react";

import CollapseTask from "./CollapseTask/CollapseTask";
import BannerTask from "./Banner/BannerTask";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDataTaskPageAction } from "../../redux/reducer/taskPageSlice";
export default function TaskPage() {
  let { dataTaskPage } = useSelector((state) => state.taskPageSlice);
  let { chuyenDeId } = useParams();
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataTaskPageAction(chuyenDeId));
  }, [chuyenDeId]);

  return (
    <div className="w-full h-full ">
      <div className="c-col-full">
        <BannerTask dataTask={dataTaskPage} />
      </div>
      <div className="flex ">
        <div className="c-col-full space-y-5">
          {dataTaskPage?.danhSachMonHoc?.map((item, index) => {
            return <CollapseTask dataContent={item} key={index} index={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
