import React, { useState } from "react";
import useWindowSize from "../../hook/useWindowSize";
import ConfettiExplosion from "react-confetti-explosion";
import { Modal } from "antd";
import coinBg from "./image/coin.png";
import { useDispatch, useSelector } from "react-redux";
import { setPhanThuong } from "./giftSlice";
export default function ModalConfetti() {
  const { phanThuong } = useSelector((state) => state.phanThuongSlice);
  let dispatch = useDispatch();

  const showModal = () => {
    setPhanThuong(true);
  };
  const handleOk = () => {
    dispatch(setPhanThuong(null));
  };
  const handleCancel = () => {
    dispatch(setPhanThuong(null));
  };
  const { width, height } = useWindowSize();
  if (!phanThuong) return <></>;
  let { tichCuc, coin, kinhNghiem, danhSachVatPham } = phanThuong;
  if (!tichCuc && !coin && !kinhNghiem && !danhSachVatPham) {
    return <></>;
  }
  console.log("render");
  return (
    <div>
      <div id="tooltip_modal " className="w-full relative">
        <div
          className="cursor-pointer flex flex-col justify-center w-full items-center  "
          onClick={showModal}
        >
          {/* {loai.icon} */}
        </div>
        {phanThuong && (
          <div
            style={{ width: "90vw" }}
            className=" h-screen  absolute top-1/2 left-1/2 transform  -translate-x-1/2 overflow-hidden  z-30 -translate-y-1/2 flex justify-center "
          >
            {/* <Confetti width={width} height={height} /> */}
            {/* <ConfettiExplosion /> */}
          </div>
        )}
        <Modal
          title=""
          visible={{}}
          onOk={handleOk}
          onCancel={handleCancel}
          footer=""
          bodyStyle={{
            borderRadius: "20px",
            overflow: "hidden",
          }}
          style={{
            borderRadius: "20px",
            maxWidth: "700px",
            minWidth: "200px",
          }}
        >
          <div className="flex space-x-5  items-center  ">
            {/* <div className="h-full w-48 flex items-center justify-center flex-shrink-0"> Lorem ipsum dolor sit.</div> */}
            {/* <div className="c-card-content text-3xl  w-full">{loai.title}</div> */}
            <div className=" h-full space-y-10   text-sub-title w-full p-2">
              <h4 className="text-2xl text-center">Thông tin vật phẩm:</h4>
              <div className="flex items-center justify-around w-full text-xl">
                {tichCuc ? (
                  <p>
                    {" "}
                    Tích cực:
                    {tichCuc}{" "}
                    <span>
                      <i style={{ color: "rgba(0, 209, 255, 1)" }} class="fa fa-star"></i>
                    </span>
                  </p>
                ) : (
                  ""
                )}

                {kinhNghiem ? (
                  <p>
                    Kinh nghiệm:
                    {kinhNghiem}{" "}
                    <span>
                      <i style={{ color: "#ffc107" }} class="fa fa-bolt"></i>
                    </span>
                  </p>
                ) : (
                  ""
                )}

                {coin ? (
                  <p className="flex items-center ">
                    <span> Coin:</span>
                    {coin} <img src={coinBg} width={20} alt="" />
                  </p>
                ) : (
                  ""
                )}
              </div>
              <p>{}</p>
              {/* render List vat pham */}
              <div className="w-full pt-5 flex justify-center flex-wrap">
                {danhSachVatPham?.map(({ tenVatPham, hinhAnh }) => {
                  return (
                    <div className="w-1/3 flex flex-col items-center">
                      <img src={hinhAnh} alt="" className="w-20" />
                      <p className="text-content text-sm">{tenVatPham}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
