import axios from "axios";
import { API_URL_DOMAIN } from "./urlDomain";

// import {history} from '../index';
export const config = {
  setCookie: function (name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },
  getCookie: function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  getStore: function (name) {
    if (localStorage.getItem(name)) {
      return localStorage.getItem(name);
    }
    return null;
  },
  setStore: function (name, value) {
    localStorage.setItem(name, value);
  },
  setStoreJson: function (name, value) {
    let json = JSON.stringify(value);
    localStorage.setItem(name, json);
  },
  getStoreJson: function (name) {
    if (localStorage.getItem(name)) {
      let result = localStorage.getItem(name);
      return JSON.parse(result);
    }
    return null;
  },
  removeStore: function (name) {
    localStorage.removeItem(name);
  },
  ACCESS_TOKEN: localStorage.getItem('ACCESS_TOKEN'),
  USER_LOGIN: "userLogin",
};

export const {
  setCookie,
  getCookie,
  getStore,
  setStore,
  setStoreJson,
  removeStore,
  getStoreJson,
  ACCESS_TOKEN,
  USER_LOGIN,
} = config;

export const http = axios.create({
  baseURL: API_URL_DOMAIN,
  timeout: 30000,
});



http.interceptors.request.use(
  function (config) {
    if(ACCESS_TOKEN)
    config.headers['Token'] = ACCESS_TOKEN;
  // console.log(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// http.interceptors.request.use(
//   function (config) {
//     if (ACCESS_TOKEN)
//       config.data = {
//         ...config.data,
//         Token: ACCESS_TOKEN
//       };
//     console.log(ACCESS_TOKEN);
//     console.log(config);
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );


http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (err.response.status === 400 || err.response.status === 404) {
      return Promise.reject(err);
    }
    if (err.response.status === 401 || err.response.status === 403) {
      alert("Token không hợp lệ ! Vui lòng đăng nhập lại !");
      return Promise.reject(err);
    }
  }
);

