import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Select, Radio, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import httpServ from "../../services/http.service";
import { setUserInfor } from "../../redux/reducer/signUpReducer";
import { setDataTestDauVao } from "./testDauVaoSlice";
import axios from "axios";
var qs = require("qs");
var jwt = require("jsonwebtoken");
export default function FormDieuKhoan({ handleSuccess }) {
  const [listNgonNgu, setListNgonNgu] = useState([]);
  useEffect(() => {
    let { id } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    axios
      .get(`https://api.techx.edu.vn/api/bai-test/lay-ngonngu`)
      .then((res) => {
        setListNgonNgu(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    // check còn hạn hay không
  }, []);
  let dispatch = useDispatch();
  const onFinish = (values) => {
    // console.log("😀 - onFinish - values", values);
    // handleSuccess();
    dispatch(
      setDataTestDauVao({
        maBaiTest: values.maBaiTest,
        gmail: values.email,
      })
    );
    handleSuccess();
    return;
    httpServ
      .getKiemTraEmailTestDauVao(values.email)
      .then((res) => {
        if (res.data.content != 0) {
          message.error("Bạn đã làm bài test rồi");
        } else {
          try {
            let { token } = qs.parse(window.location.search, {
              ignoreQueryPrefix: true,
            });
            let { data: type } = jwt.verify(token, "JAVA_TEST_QUIZ");
            console.log("yes log");
            dispatch(
              setDataTestDauVao({
                maBaiTest: values.maBaiTest,
                gmail: values.email,
              })
            );
            handleSuccess();
          } catch (error) {}
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div className="w-full flex justify-center flex-col items-center mt-5">
      <Form
        name="basic"
        className=" w-full sm:p-3 md:p-5  lg:w-5/6  "
        // labelCol={{ sm: { span: 6 }, xl: { span: 4 } }}
        // wrapperCol={{ sm: { span: 20 }, lg: { span: 16 } }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label={<span className="text-content">Email</span>}
          name="email"
          rules={[
            {
              type: "email",
              message: "Email không đúng định dạng",
            },
            {
              required: true,
              message: "Trường này không được để trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* chọn ngôn ngữ */}
        <Form.Item
          rules={[
            {
              required: true,
              message: "Trường này không được để trống",
            },
          ]}
          label={<p className="text-content">Chọn ngôn ngữ bạn muốn làm bài test</p>}
          name="maBaiTest"
        >
          <Radio.Group>
            <Radio key="PYTHON_CAP2" value="PYTHON_CAP2">
              <span className="text-content">Không biết lập trình</span>
            </Radio>
            <p className="text-content mt-2">Đã biết lập trình</p>
            {listNgonNgu.map((item) => (
              <Radio key={item.key} value={item.key}>
                <span className="text-content">{item.value}</span>
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item wrapperCol={{ sm: { offset: 0 }, md: { offset: 4 }, span: 16 }}>
          <div className="w-full flex justify-center space-x-3 ">
            <Button className="w-32" type="primary" htmlType="submit">
              Tiếp theo
            </Button>
          </div>
        </Form.Item>
      </Form>

      <div></div>
    </div>
  );
}
