import React from "react";

let listColor = {
  blue: {
    filledColor:
      "linear-gradient(360deg, #272ec3 -3.43%, #5687E2 33.79%, #115BC0 79.29%, #62BDFF 218.89%",
    // unfilledColor:
    //   "linear-gradient(180deg, #3D78D7 0%, rgba(24, 73, 245, 0.1) 100%)",
    unfilledColor:
      "linear-gradient(180deg, #275bb0 0%, rgba(119, 141, 219, 0.2) 100%)",
  },
  red: {
    filledColor:
      "linear-gradient(180deg, #FF0000 -113.05%, rgba(224, 0, 0, 0.555209) 44%, rgba(90, 0, 0, 0) 170%)",
    unfilledColor:
      "linear-gradient(180deg, #EA2F24 -130%, rgba(240, 151, 145, 0) 170%)",
  },
  yellow: {
    filledColor: "linear-gradient(77.47deg, #5F4400 -18.18%, #FFB800 100%)",
    unfilledColor:
      "linear-gradient(180deg, #FFEE96 0%, rgba(255, 255, 255, 0) 100%)",
  },
};

export default function ProgressLine({
  percent,
  type,
  witdh = "100%",
  size = 2,
}) {
  let color = listColor[type];

  return (
    <div
      style={{ background: color.unfilledColor, witdh: witdh }}
      className={`  rounded-xl mx-auto lg:h-${size * 2} w-full md:h-${size}`}
    >
      <div
        style={{
          width: `${percent}%`,
          background: color.filledColor,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        }}
        className="h-full flex justify-end rounded-xl  text-content px-2 font-bold leading-none items-center"
      >
        <span>{percent}%</span>
      </div>
    </div>
  );
}
