import { createSlice } from "@reduxjs/toolkit";
import { nopbaiService } from "../../services/nopbaiService";
import { setMessage } from "./MessageReducer-Chat";
import { nguoiDungService } from "../../services/nguoiDungService";
import { monHocService } from "../../services/monHocService";

const initialState = {
  NopBaiState: {
    chuyenDeId: 0,
    loaiModule: "",
    monHocId: 0,
    baiLam: "",
    daXoa: false,
    diem: 0,
    hanNopBai: "",
    id: 0,
    maLoai: "",
    ngayTao: "",
    nguoiCham: "",
    nguoiDungId: "",
    nhanXet: "",
    requireId: 0,
    taskId: 0,
    trangThai: "",
  },
  nopBaiCurrent: {
    chuyenDeId: 0,
    loaiModule: "",
    monHocId: 0,
    baiLam: "",
    daXoa: false,
    diem: 0,
    hanNopBai: "",
    id: 0,
    maLoai: "",
    ngayTao: "",
    nguoiCham: "",
    nguoiDungId: "",
    nhanXet: "",
    requireId: 0,
    taskId: 0,
    trangThai: "",
  },
  dsNopBaiByGroup: [],
  dsNopBaiByMentorId: [],
  dsNopBaiByMentorIdViewMentor: [],
  dsbailamtheoids: [],
  dsHocVienByMentorId: [],
  dsMonHocByChuyenDe: [],
};

const nopBaiReducer = createSlice({
  name: "monHocReducer",
  initialState,
  reducers: {
    setNopBaiState: (state, action) => {
      state.NopBaiState = { ...action.payload };
    },
    getDsNopBaiByGroup: (state, action) => {
      if (action.payload.length) {
        state.dsNopBaiByGroup = [...action.payload.sort((a, b) => a.requireId - b.requireId)];
      }
    },
    getNopBaiCurrent: (state, action) => {
      state.nopBaiCurrent = action.payload;
      if (action.payload.baiLam) state.dsbailamtheoids = JSON.parse(action.payload.baiLam);
    },
    GetDsNopBaiByMentorId: (state, action) => {
      state.dsNopBaiByMentorId = [...action.payload];
    },
    GetNopBaisByNguoiDungViewModel: (state, action) => {
      state.dsNopBaiByMentorIdViewMentor = [...action.payload];
    },
    GetDSByMentorId: (state, action) => {
      state.dsHocVienByMentorId = [...action.payload];
    },
    GetMonHocsByListId: (state, action) => {
      state.dsMonHocByChuyenDe = [...action.payload];
    },
  },
});

export const {
  setNopBaiState,
  getDsNopBaiByGroup,
  getNopBaiCurrent,
  GetDsNopBaiByMentorId,
  GetNopBaisByNguoiDungViewModel,
  GetDSByMentorId,
  GetMonHocsByListId,
} = nopBaiReducer.actions;
export default nopBaiReducer.reducer;
//--------- action async ------------

export const getDsNopBaiByGroupApi = (gtaskId, mentorId) => {
  return async (dispatch) => {
    const result = await nopbaiService.GetDSByGtaskId(gtaskId, mentorId);
    const action = getDsNopBaiByGroup(result.data.content);
    dispatch(action);
  };
};

export const putMentorChamBai = (mentorId, MentorChamBai) => {
  return async (dispatch) => {
    try {
      const result = await nopbaiService.mentorChamBai(mentorId, MentorChamBai);
      if (result.status === 200) {
        const action = setMessage({
          type: "success",
          message: "Chấm Bài Thành Công",
          description: "",
        });
        dispatch(action);
      } else {
        const action = setMessage({
          type: "error",
          message: "Chấm Bài Không Thành Công",
          description: "Có Thể Hệ Thống Đang Có Vấn Đề !",
        });
        dispatch(action);
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const GetDsNopBaiByMentorIdApi = (mentorId) => {
  return async (dispatch) => {
    const result = await nopbaiService.GetNopBaisByNguoiDungService(mentorId);
    const action = GetDsNopBaiByMentorId(result.data.content);
    // console.log(action)
    dispatch(action);
  };
};

export const GetNopBaisByNguoiDungViewModelApi = (mentorId) => {
  return async (dispatch) => {
    const result = await nopbaiService.GetNopBaisByNguoiDungViewModelService(mentorId);
    const action = GetNopBaisByNguoiDungViewModel(result.data.content);
    // console.log(action)
    dispatch(action);
  };
};
export const GetDSByMentorIdApi = (mentorId) => {
  return async (dispatch) => {
    const result = await nguoiDungService.GetDSByMentorIdService(mentorId);
    const action = GetDSByMentorId(result.data.content);
    // console.log(action)
    dispatch(action);
  };
};
export const GetMonHocsByListIdApi = (ds) => {
  return async (dispatch) => {
    const result = await monHocService.GetMonHocsByListIdService(ds);
    const action = GetMonHocsByListId(result.data.content);
    // console.log(action)
    dispatch(action);
  };
};
