import React, { useEffect } from "react";
import List from "../VideoList/List";
import servCodingCamp from "../../../services/codingCam.service";
import { selectPersonalState, setPersonalState } from "../tuongNhaSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Favorites() {
  // let { id } = localStorageServ.userInfo.get();
  let { userId } = useParams();
  let { profile, danhSachVideoDaLuu } = useSelector(selectPersonalState);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   servCodingCamp
  //     .getThongTinTuongNha(userId, 4)
  //     .then((res) => {
  //       dispatch(setPersonalState(res.data.content));
  //     })
  //     .catch((err) => {});
  // }, [userId]);
  return (
    <div id="bagdes_tab">
      <List videoList={danhSachVideoDaLuu} />
    </div>
  );
}
