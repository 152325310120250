import { createSlice } from "@reduxjs/toolkit";
import { chuyenDeService } from "../../services/chuyenDeService";
import { danhSachTagService } from "../../services/danhSachTagService";

const initialState = {
  arrChuyenDe: [],
  arrTag: [],
  DSChuyenDeMentorPhuTrach: [],
  DSChuyenDeHocVienDangKy: [],
};

const chuyenDeReducer = createSlice({
  name: "chuyenDeReducer",
  initialState,
  reducers: {
    getChuyenDeAction: (state, action) => {
      state.arrChuyenDe = action.payload;
    },
    getListTagAction: (state, action) => {
      state.arrTag = action.payload;
    },
    getDSChuyenDeMentorPhuTrach: (state, action) => {
      state.DSChuyenDeMentorPhuTrach = [...action.payload];
    },
    getDSChuyenDeHocVienDangKy: (state, action) => {
      state.DSChuyenDeHocVienDangKy = [...action.payload];
    },
  },
});

export const {
  getChuyenDeAction,
  getListTagAction,
  getDSChuyenDeMentorPhuTrach,
  getDSChuyenDeHocVienDangKy,
} = chuyenDeReducer.actions;
export default chuyenDeReducer.reducer;

//--------- action async ------------

export const getChuyenDeApi = () => {
  return async (dispatch) => {
    const result = await chuyenDeService.layDanhSachChuyenDe();

    const action = getChuyenDeAction(result.data.content);
    dispatch(action);
  };
};

// get danh sach tag

export const getListTagApi = () => {
  return async (dispatch) => {
    const result = await danhSachTagService.getDanhSachTag();

    const action = getListTagAction(result.data.content);

    dispatch(action);
  };
};

export const getDSChuyenDeMentorPhuTrachApi = (mentorId) => {
  return async (dispatch) => {
    const result = await chuyenDeService.getChuyenDeMentorPhuTrach(mentorId);
    const action = getDSChuyenDeMentorPhuTrach(result.data.content);
    dispatch(action);
  };
};

export const getDSChuyenDeHocVienDangKyApi = () => {
  return async (dispatch) => {
    try {
      const rs = await chuyenDeService.getChuyenDeHocVienDK();
      const action = getDSChuyenDeHocVienDangKy(rs?.data?.content);
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
};
