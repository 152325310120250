import React from "react";
import CardDiscussion from "./CardDiscussion";

export default function DiscussionTopic({ data }) {
  const renderCardTopics = () => {
    return data.map((dTopic) => {
      return <CardDiscussion dTopic={dTopic} />;
    });
  };
  return (
    <div className="h-full p-5 space-y-6" style={{ width: "27%" }}>
      {renderCardTopics()}
    </div>
  );
}
