import React, { useEffect, useState } from "react";
import InformationProject from "../../components/ListRequireNopBai/InformationProject";
import ListRequireNopBai from "../../components/ListRequireNopBai/ListRequireNopBai";
import ContentTabCol from "../../components/ContentTabCol/ContentTabCol";
import { createPayloadLayThongTinBaiTap, loaiNhiemVu } from "../../utils/taskUtils";
import servCodingCamp from "../../services/codingCam.service";
import { useParams } from "react-router-dom";
import ModalClickZoomImg from "../../components/ModalClickZoomImg/ModalClickZoomImg";
import { useTranslation } from "react-i18next";

export default function DetailExercise() {
  const { t } = useTranslation()

  let { chuyenDeId, taskId, monHocId } = useParams();
  const [detailPage, setDetailPage] = useState({});
  const [trangThaiTongBaiTap, setTrangThaiTongBaiTap] = useState({
    noiDung: [],
  });

  const maLoaiNhiemVu = detailPage.maLoaiNhiemVu;
  const [listTabPane, setListTabPane] = useState([]);
  let listRequire = [];
  if (detailPage.noiDungNhiemVu?.noiDungBaiTap?.noiDung) {
    listRequire = JSON.parse(detailPage.noiDungNhiemVu.noiDungBaiTap.noiDung);
  }
  let getListPanel = (trangThaiTongBaiTap) => {
    let listTabPane = listRequire.map((required, index) => {
      // kiểm tra bài đã nộp hay chưa

      // -1 => false => chưa nộp

      return {
        label: required.requireId,
        key: required.requireId,
        children: (
          <ListRequireNopBai
            monHocId={monHocId}
            taskId={taskId}
            chuyenDeId={chuyenDeId}
            trangThaiTongBaiTap={trangThaiTongBaiTap}
            required={required}
            orderNumber={index}
            getThongTinBaiTapNop={getThongTinBaiTapNop}
            maLoaiNhiemVu={maLoaiNhiemVu}
            tongCoin={detailPage.tongCoin}
            tongExp={detailPage.tongExp}
          />
        ),
      };
    });
    setListTabPane(listTabPane);
  };
  let getThongTinBaiTapNop = () => {
    let payload = createPayloadLayThongTinBaiTap(taskId, chuyenDeId, monHocId);
    servCodingCamp
      .getLayThongTinBaiTapNop(payload)
      .then((res) => {
        setTrangThaiTongBaiTap(res.data.content);
        getListPanel(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        console.log(res);

        setDetailPage(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [chuyenDeId, taskId, monHocId]);
  useEffect(() => {
    getThongTinBaiTapNop();
  }, [detailPage.id, chuyenDeId, taskId, monHocId]);

  return (
    <div id="containerNoiDungBaiTap" className="flex w-full flex-wrap c-col-full">
      <ModalClickZoomImg />
      <div className="c-col-full">
        <p className="text-title font-medium my-2">
          <i className="fas fa-graduation-cap mr-2"></i>
          <span>
            {t('chiTietNhiemVu.thongTinDuAn')}

          </span>
          {/* {detailPage.tenBaiHoc} */}
        </p>
        <InformationProject
          trangThaiTongBaiTap={trangThaiTongBaiTap}
          maNhiemVu={maLoaiNhiemVu}
          detail={detailPage}
        />
      </div>
      <div className="tabCol w-full">
        <ContentTabCol data={listTabPane} />
      </div>
    </div>
  );
}
