// eslint-disable-next-line import/no-anonymous-default-export
const environment = {
  //baseUrl: "https://localhost:5001",
  baseUrl: "https://backend.cyberlearn.vn",
  faceBookAppId: "130543658347826",
  env: "dev",
  production: false,
  enableCheat: true,
  version: "1.0.0",
};
export default environment;
