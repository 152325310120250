import React from "react";
import Lottie from "lottie-react";
import upgradeLottie from "../assets/lottie_json/76555-update-app.json";
import { Portal } from "react-portal";
import useWindowSize from "../hook/useWindowSize";
export default function Upgrade_Notify_Page() {
  const { widthWindow, heightWindow } = useWindowSize();

  return widthWindow < 768 ? (
    <Portal node={document && document.getElementById("custom-portal")}>
      <div className="flex flex-col justify-center items-center fixed top-0 left-0 w-screen h-screen bg-white space-y-2">
        <div className="w-full flex justify-center items-center ">
          <Lottie
            loop={false}
            animationData={upgradeLottie}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <p className="text-gray-900 text-center w-2/3">
          Hiện tại phiên bản này chưa hỗ trợ trên mobie, bạn hãy đăng nhập bằng
          máy tính hoặc tablet để sử dụng nhé
        </p>
      </div>
    </Portal>
  ) : (
    ""
  );
}
