import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { NavLink, useParams } from "react-router-dom";
import CollapseTask from "./CollapseTask/CollapseTask";
import { getDataTaskPageAction } from "../../redux/reducer/taskPageSlice";
export default function OutlineTaskList() {
  let { dataTaskPage } = useSelector((state) => state.taskPageSlice);
  let { chuyenDeId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataTaskPageAction(chuyenDeId));
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <button
        className=" text-xs rounded  p-1  block border-1 border-white text-white"
        onClick={showModal}
      >
        <i class="fa fa-bars"></i> <span className="text-xs">Course Outline</span>
      </button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="rounded"
      >
        <div className="c-col-full space-y-3">
          <div>Danh sách nhiệm vụ</div>
          {dataTaskPage?.danhSachMonHoc?.map((item, index) => {
            return <CollapseTask dataContent={item} key={index} index={index} />;
          })}
        </div>
      </Modal>
    </div>
  );
}
