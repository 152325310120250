// import { Segmented } from "antd";
import React, { useEffect, useState } from "react";
import servCodingCamp from "../../services/codingCam.service";
import ContentTab from "../ContentTab/ContentTab";
import { useParams } from "react-router-dom";

let ItemPhanCong = ({ require }) => {
  const [isFinish, setIsFinish] = useState(true);
  let options = [
    {
      title: "Chưa làm",
      isDone: false,
    },
    {
      title: "Đã làm",
      isDone: true,
    },
  ];
  let renderList = () => {
    return options?.map(({ title, isDone }, index) => {
      return {
        label: (
          <button
            onClick={() => {
              setIsFinish(isDone);
            }}
            className=" rounded-full text-content font-medium"
          >
            {title}
          </button>
        ),
        key: index,
        children: <p className="text-white"></p>,
      };
    });
  };
  return (
    <div className="text-content  ">
      <h3 className="text-white capitalize font-medium text-center">{require.tenHocVien}</h3>
      <ContentTab
        paddingHeaderX={15}
        paddingHeaderY={3}
        paddingTabItemX={15}
        paddingTabItemY={5}
        data={renderList()}
      />

      <ul className=" list-item space-y-3 h-24  overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-white scrollbar-thumb-rounded-xl  scrollbar-track-rounded-xl">
        {require?.dsRequire
          ?.filter(({ isDone }) => isDone == isFinish)
          .map((item, index) => {
            console.log("😀 - {require?.dsRequire?.map - item", item);
            return (
              <li className="text-white">
                {/* {item.noiDung} */}
                Task {item.requireId + 1}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export default function AssignmentAndDetail() {
  const [assignment, setAssignment] = useState({});
  let { chuyenDeId, taskId, monHocId } = useParams();

  useEffect(() => {
    let payload = {
      chuyenDeId,
      monHocId,
      taskId,
    };
    servCodingCamp
      .chiaTaskCapstone(payload)
      .then((res) => {
        console.log(res);
        setAssignment(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="c-col-full flex-grow">
        <div className=" h-full  overflow-hidden">
          <div className="c-card-content  flex items-center border-b border-black">
            <p className="text-title font-bold ">
              <div className="icon_theme w-11 h-11 mr-2 ">
                <i className="fas fa-wrench"></i>
                {/* <span className="bg_icon_theme"></span> */}
              </div>
              Bảng phân công công việc
            </p>
          </div>
          <div className="c-card-content  text-white flex justify-between">
            {assignment?.listDsRequireHv?.map((item, key) => {
              return <ItemPhanCong require={item} key={key} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}
