/* eslint-disable no-useless-constructor */
class LocalStorageKey {
  ACCESS_TOKEN = "ACCESS_TOKEN";
  USER_INFOR = "USER_INFOR_TECH_X";
  USER_DEMO = "USER_DEMO";
  USER_TOUR = "USER_TOUR";
  TIME_DEMO = "TIME_DEMO";
  CODE_DEMO = "CODE_DEMO";
  TIME_LOGIN = "TIME_LOGIN";
  NUOCNGOAI_DEMO = "NUOCNGOAI_DEMO";
  LUCKY_WHEEL = "LUCKY_WHEEL";
  LUCKY_WHEEL_NEW = "LUCKY_WHEEL_NEW";
  MODE_THEME = "MODE_THEME";
  DATA_DETAIL_PAGE_CAPS = "DATA_DETAIL_PAGE_CAPS";
  DATA_LEARN_VIDEO_PAGE = "  DATA_LEARN_VIDEO_PAGE";
  DATA_LEARN_QUIZ_PAGE = "  DATA_LEARN_QUIZ_PAGE";
  DATA_LEARN_RUNCODE_PAGE = "  DATA_LEARN_RUNCODE_PAGE";
  DATA_LEARN_BAI_TAP_PAGE = "  DATA_LEARN_BAI_TAP_PAGE";
  ACTIVE_INDEX_NAV = "ACTIVE_INDEX_NAV";
  REFER_LINK = "REFER_LINK";
}

class BaseStorage {
  key;

  constructor(_key) {
    this.key = _key;
  }

  set = (value) => {
    const dataString = JSON.stringify(value);
    localStorage.setItem(this.key, dataString);
  };

  get = () => {
    const dataString = localStorage.getItem(this.key);
    return !dataString ? null : JSON.parse(dataString);
  };

  remove = () => {
    localStorage.removeItem(this.key);
  };
}

class LocalStorageService extends LocalStorageKey {
  constructor() {
    super();
  }

  clearLocalStorage = () => {
    localStorage.clear();
  };
  /**
   * access token storage
   */

  accessToken = new BaseStorage(this.ACCESS_TOKEN);

  /**
   * user info
   */

  userInfo = new BaseStorage(this.USER_INFOR);
  userDemo = new BaseStorage(this.USER_DEMO);
  userTour = new BaseStorage(this.USER_TOUR);
  timeDemo = new BaseStorage(this.TIME_DEMO);
  codeDemo = new BaseStorage(this.CODE_DEMO);
  timeLogin = new BaseStorage(this.TIME_LOGIN);
  nuocngoaiDemo = new BaseStorage(this.NUOCNGOAI_DEMO);
  luckyWhell = new BaseStorage(this.LUCKY_WHEEL);
  luckyWhellNew = new BaseStorage(this.LUCKY_WHEEL_NEW);
  modeTheme = new BaseStorage(this.MODE_THEME);
  dataDetailPageCaps = new BaseStorage(this.DATA_DETAIL_PAGE_CAPS);
  dataLearnVideoPage = new BaseStorage(this.DATA_LEARN_VIDEO_PAGE);
  dataLearnQuizPage = new BaseStorage(this.DATA_LEARN_QUIZ_PAGE);
  dataLearnRunCode = new BaseStorage(this.DATA_LEARN_RUNCODE_PAGE);
  dataLearnBaiTaiTapPage = new BaseStorage(this.DATA_LEARN_BAI_TAP_PAGE);
  activeIndexNav = new BaseStorage(this.ACTIVE_INDEX_NAV);
  referLink = new BaseStorage(this.REFER_LINK);
}

const localStorageServ = new LocalStorageService();

export default localStorageServ;
