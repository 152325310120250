import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phanThuong: {},
};

const phanThuongSlice = createSlice({
  name: "phanThuongSlice",
  initialState,
  reducers: {
    setPhanThuong: (state, action) => {
      // state.phanThuong = action.payload;
      if (action.payload == null) {
        state.phanThuong = null;
      } else {
        state.phanThuong = action.payload;
      }
    },
  },
});

export const { setPhanThuong } = phanThuongSlice.actions;
export default phanThuongSlice.reducer;
