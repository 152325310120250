import React, { useState } from "react";
import { Button, Modal } from "antd";
import { loaiNhiemVu, nhiemVuColor, typeTaskIcon } from "../../utils/taskUtils";

export default function TooltipContentNhiemVu({ maLoaiNhiemVu }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  let nhiemVu = typeTaskIcon[maLoaiNhiemVu];
  return (
    <>
      <button
        className="cursor-pointer flex flex-col justify-start"
        onClick={showModal}
      >
        <span style={{ color: nhiemVuColor[maLoaiNhiemVu] }}>
          {nhiemVu?.icon}
        </span>
      </button>
      <Modal
        title=""
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
        width={400}
        bodyStyle={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
        style={{
          borderRadius: "20px",
        }}
      >
        <div className="text-title  py-5">
          <p>Thông tin vật phẩm</p>
          {nhiemVu?.content}
        </div>
      </Modal>
    </>
  );
}
