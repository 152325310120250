import React, { useState } from "react";
import { Modal } from "antd";
import "./ModalContentIcon.css";
function ModalWrapper({ icon, content }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div id="tooltip_modal " className="w-full">
      <div
        className="cursor-pointer flex flex-col justify-center w-full items-center "
        onClick={showModal}
      >
        {icon}
      </div>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={() => {
          handleCancel();
        }}
        footer=""
        bodyStyle={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
        style={{
          borderRadius: "20px",
          maxWidth: "900px",
          minWidth: "400px",
          // width: 900,
        }}
        className="w-max"
      >
        <div className="p-5">{content}</div>
      </Modal>
    </div>
  );
}

// icon, title, moTa
// export default React.forwardRef(ModalWrapper);
export default ModalWrapper;
