import localStorageServ from "../services/locaStorage.service";
import { loaiNhiemVu } from "../utils/taskUtils";

export const typeTask = {
  VIDEO: "VIDEO",
  BAITAP: "BAITAP",
  QUIZ: "QUIZ",
  CAPS: "CAPS",
  RUNCODE: "RUNCODE",
  TAILIEU: "TAILIEU",
};
export const parentPage = {
  task: "task-page",
  pratice: "pratice-page",
};
export let checkActiveNavlink = (urlString) => {
  const currentPathname = window.location.pathname;
  return currentPathname.includes(urlString);
};
// dùng chung cho các trang task, competetion, pratice

export let navigatePageByTask = (nhiemVu, navigate, parentPath) => {
  let { maLoaiNhiemVu, chuyenDeId, id: taskId, monHocId } = nhiemVu;
  switch (maLoaiNhiemVu) {
    case loaiNhiemVu.CAPS:
      navigate(`/${parentPath}/detail-screen-capstone/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    case "DUAN":
      navigate(`/${parentPath}/detail-screen-capstone/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    case loaiNhiemVu.BAITAP:
      navigate(`/${parentPath}/detail-screen-bai-tap/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    case loaiNhiemVu.HOMEWORK:
      navigate(`/${parentPath}/detail-screen-bai-tap/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    case loaiNhiemVu.VIDEO:
      navigate(`/${parentPath}/learn-video/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    case loaiNhiemVu.RECORD:
      navigate(`/${parentPath}/learn-video/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    case loaiNhiemVu.QUIZ: {
      navigate(`/${parentPath}/detai-screen-quiz/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    }
    case loaiNhiemVu.RUNCODE: {
      navigate(`/${parentPath}/run-code/${chuyenDeId}/${monHocId}/${taskId}`);
      break;
    }
  }
};

export let getDataLocalByTask = (loai) => {
  switch (loai) {
    case typeTask.CAPS:
      return localStorageServ.dataDetailPageCaps.get();

    case typeTask.BAITAP:
      return localStorageServ.dataLearnBaiTaiTapPage.get();

    case typeTask.VIDEO:
      return localStorageServ.dataLearnVideoPage.get();

    case typeTask.QUIZ: {
      return localStorageServ.dataLearnQuizPage.get();
    }
    case typeTask.RUNCODE: {
      return localStorageServ.dataLearnRunCode.get();
    }
    // case typeTask.QUIZ: {
    //   return localStorageServ.dataLearnQuizPage.get();
    // }
    // default:
    //   alert("ko có dữ liệu của ", loai);
    // window.location.href = "/";
  }
};
