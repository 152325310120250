import React, { useEffect, useState } from "react";
import ItemPosted from "./ItemPosted";
import servCodingCamp from "../../services/codingCam.service";

export default function DocumentPage() {
  const [isLayoutGrid, setIsLayoutGrid] = useState(true);
  const [listDocument, setListDocument] = useState([]);
  useEffect(() => {
    servCodingCamp
      .getDocumentByOption(0)
      .then((res) => {
        setListDocument(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOnGridLayout = () => {
    setIsLayoutGrid(true);
  };
  const handleOnListLayout = () => {
    setIsLayoutGrid(false);
  };
  const renderListPosted = () => {
    return listDocument.map((d) => {
      return <ItemPosted dataDocment={d} isLayoutGrid={isLayoutGrid} />;
    });
  };
  return (
    <div className="mt-36">
      <div className="c-card-content">
        <button className="bg-sp px-4 py-2 text-white text-sub-title rounded-lg">
          Bài viết của tôi
        </button>
      </div>
      <div className="c-card-content ">
        <div className="flex h-full">
          <div style={{ width: "90%" }} className=" h-full relative">
            <input
              type="text"
              placeholder="Nhập vào tên bài viết hoặc chủ đề tác giả"
              className="h-full w-full border border-black py-2 px-3 outline-none"
            />
            <i class="fa fa-search absolute text-title font-bold right-4 top-1/2 transform -translate-y-1/2"></i>
          </div>
          <div
            style={{ width: "10%" }}
            className="flex   h-full font-bold text-title"
          >
            <button
              onClick={handleOnListLayout}
              className="flex-1 flex justify-center items-center"
            >
              <i class="fa fa-list"></i>
            </button>
            <button
              onClick={handleOnGridLayout}
              className="flex-1 flex justify-center items-center"
            >
              <i class="fa fa-th-large"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="c-card-content">
        <div className="flex flex-wrap">{renderListPosted()}</div>
      </div>
    </div>
  );
}
