import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import GeneralIntroUser from "../../components/BarTichCucKinhNghiem/BarTichCucKinhNghiem";
import { NavLink, useNavigate } from "react-router-dom";
import { updateThongTinMoRongAction, selectThongTinMoRong } from "../../redux/reducer/authReducer";
import localStorageServ from "../../services/locaStorage.service";
import DropdownLoTrinh from "./DropdownLoTrinh";
import ItemCoin from "../../components/ItemCoin/ItemCoin";
import DropdowThongBao from "./DropdowThongBao/DropdowThongBao";
import { dataHeader } from "../utils";
import { getHinhAnhFB } from "../../utils/GetHinhanh";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

export default function HeaderTask({ }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userId = localStorageServ.userInfo.get()?.id;
  let { userInfo } = useSelector(selectThongTinMoRong);

  useEffect(() => {
    dispatch(updateThongTinMoRongAction());
  }, []);

  const { t, i18n } = useTranslation()

  return (
    <>
      <div
        onClick={() => {
          navigate("/dash-broad");
        }}
        className="logo w-20 flex-shrink-0 h-ful flex items-center cursor-pointer"
      >
        <img src="https://techx.edu.vn/img/logo/logo-cyber.svg" alt="" />
      </div>
      <div className="flex rounded-xl relative items-center h-10  ">
        {dataHeader.map((item, index) => {
          if (index == 1) return <DropdownLoTrinh />;
          return (
            <NavLink key={index} to={item.path}>
              <button
                className={`p-1 px-3 flex h-full justify-center text-content text-sm items-center rounded-xl z-10 relative  duration-500 text-button uppercase w-max`}
              >
                {item.name}
              </button>
            </NavLink>
          );
        })}
      </div>
      <DropdowThongBao />
      <GeneralIntroUser />
      <ItemCoin />
      <div className="w-14 h-14">
        <NavLink to={`/profile/${userId}`}>
          <img
            src={getHinhAnhFB(userInfo.facebookId)}
            className="w-full h-full  block rounded-full m-0  object-cover "
            alt=""
          />
        </NavLink>
      </div>
      <div className="lang">
        <Select
          style={{ width: 70,fontSize: 25 }}
          value={localStorage.getItem("lang")}
          onChange={(value) => {
            localStorage.setItem("lang", value)
            window.location.reload()
          }}
          options={[{ value: 'vi', label: '🇻🇳' }, { value: 'en', label: '🇺🇸' }]}

          
        />
      </div>
      <div
        onClick={() => {
          localStorageServ.userInfo?.remove();
          window.location.href = "/login";
        }}
        style={{ color: " rgb(255, 210, 32)" }}
        className=" cursor-pointer flex items-center justify-start space-x-2 flex-shrink-0"
      >
        <p className=""> {t('header.dangXuat')}</p> <i className="fa fa-sign-out-alt "></i>
      </div>
    </>
  );
}
