import React, { useEffect, useState } from "react";
import MainContent from "./MainContent";
import Desc from "./Desc";
import Discuss from "./Discuss/Discuss";
import servCodingCamp from "../../../services/codingCam.service";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDetailVideoWallServ, setVideoWall } from "../tuongNhaSlice";

export default function DetailWall() {
  let { id, userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetailVideoWallServ(id));
  }, []);
  return (
    <div id="detailWall" className=" w-full">
      <div className="flex w-full  ">
        <div className=" min-h-screen w-3/5  flex-shrink-0">
          <MainContent />
        </div>
        <div className="flex-grow p-3 pl-0 h-screen overflow-y-scroll">
          <Desc></Desc>
          <Discuss></Discuss>
        </div>
      </div>
    </div>
  );
}
