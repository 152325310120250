import React from "react";
import Practices_SingleChoice from "./Pratices/Practices_SingleChoice";
import Practices_SingleChoiceToFill from "./Pratices/Praticees_ChooseAnserToFill";
import Practices_HTML_CSS from "./Pratices/Practices_HTML_CSS";
import Practices_FillCodeToInput from "./Pratices/Practices_FillCodeToInput";
import Practices_MultipleChoice from "./Pratices/Practices_MultipleChoice";
import _ from "lodash";
export default function List4TypeQuiz({ currentQuestionIndex, allQuestions, setListQuestion }) {
  let handle_CheckSingleChoice = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };

    if (newCurrentQuestion.noiDung.dapAn[0] == userAnsers[0]?.cauTraLoi) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckMultipleChoice = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];
    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFillInput = (id, userAnsers = []) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };

    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;
    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank_CSS = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];
    newAllQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let arrRenderQuestion = allQuestions.map((question, index) => {
    let keyIndex = index;
    switch (question?.noiDung.maLoaiBaiTap) {
      case "single":
        return (
          <Practices_SingleChoice
            key={keyIndex}
            question={question}
            handle_CheckSingleChoice={handle_CheckSingleChoice}
          />
        );
      case "multiple_choice":
        return (
          <Practices_MultipleChoice
            key={keyIndex}
            handle_CheckMultipleChoice={handle_CheckMultipleChoice}
            question={question}
          />
        );
      case "fill_inblank_css":
        return (
          <Practices_HTML_CSS
            key={keyIndex}
            handle_CheckFinll_IN_Blank_CSS={handle_CheckFinll_IN_Blank_CSS}
            question={question}
          />
        );
      case "fill_inblank":
        return (
          <Practices_SingleChoiceToFill
            key={keyIndex}
            handle_CheckFinll_IN_Blank={handle_CheckFinll_IN_Blank}
            question={question}
          />
        );
      case "fill_input":
        return (
          <Practices_FillCodeToInput
            key={keyIndex}
            question={allQuestions[currentQuestionIndex]}
            handle_CheckFillInput={handle_CheckFillInput}
          />
        );

      default:
        break;
    }
  });

  return arrRenderQuestion.length > 0 ? arrRenderQuestion[currentQuestionIndex] : <></>;
}
