import React, { useEffect, useState } from "react";
import ItemRanking from "./ItemRanking";
import ItemTop from "./ItemTop";
import ContentTab from "../ContentTab/ContentTab";
import { useParams } from "react-router-dom";
import servCodingCamp from "../../services/codingCam.service";
import { useTranslation } from "react-i18next";
export default function BangXeHangThanhTich({ heightList = 350, btnFilter, isGetAll = true }) {
  let params = useParams();
  const [listUser, setListUser] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  let handleGetXepHang = (option = 1) => {
    let api = isGetAll ? servCodingCamp.getXepHangTong : servCodingCamp.getXepHangTheoChuyenDe;
    api(params.chuyenDeId, option)
      .then((res) => {
        setListUser(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let renderOptionButton = () => {
    return btnFilter.map(({ title, key }, index) => {
      return {
        label: (
          <button
            onClick={() => {
              handleGetXepHang(key);
            }}
            className=" rounded-full text-content font-medium"
          >
            {title}
          </button>
        ),
        key: index,
        children: "",
      };
    });
  };

  let renderTopThree = () => {
    return [
      <ItemTop size={0.8} user={listUser[1]} rank={1} />,
      <ItemTop size={1} user={listUser[0]} rank={0} />,
      <ItemTop size={0.8} user={listUser[2]} rank={2} />,
    ];
  };
  useEffect(() => {
    handleGetXepHang();
  }, [params.chuyenDeId]);
  const { t } = useTranslation()

  return (
    <div className=" h-full flex flex-col">
      <div className="c-card-content pt-0 text-title flex items-center ">
        <p className="text-title">
          <i className="fa fa-chart-bar text-white text-2xl mr-2 capitalize"></i>
          <span> {t('trangChu.bangXepHangThanhTich')}</span>
        </p>
      </div>

      <div style={{ maxWidth: 300 }} className="flex mb-2 mx-auto flex-shrink0 space-x-3">
        {renderTopThree()}
      </div>
      <div className=" ">
        <ContentTab
          paddingHeaderX={15}
          paddingHeaderY={3}
          paddingTabItemX={15}
          paddingTabItemY={5}
          data={renderOptionButton()}
        />
        <div
          style={{ height: heightList }}
          className="   list_ranking space-y-1  overflow-y-scroll  scrollbar-thin  scrollbar-thumb-rounded-xl  scrollbar-track-rounded-xl scrollbar-thumb-gray-400 scrollbar-track-gray-300 "
        >
          {listUser.map((item, index) => {
            return (
              <ItemRanking
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                data={item}
                index={index}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
