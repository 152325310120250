import React from "react";
import { extractSkillLanguage } from "../../DocumentPage/extractIcon";

export default function DetailPosted({ mainPost }) {
  let {
    id,
    tieuDe,
    noiDung,
    tagSkill,
    trangThai,
    loaiBaiViet,
    nguoiTao,
    nguoiDuyet,
    chuyenDeId,
    baiVietCha,
    luotXem,
    luotLike,
    luotTraLoi,
    luotVote,
    bookMark,
  } = mainPost;
  const renderSkill = () => {
    return ["html", "css"].map((s) => {
      return (
        <div className="w-8 h-8 shadow rounded border bg-main p-1">
          {extractSkillLanguage(s)}
        </div>
      );
    });
  };
  return (
    <div style={{ width: "95%" }}>
      <div className="c-card-content flex justify-between">
        <p className="text-title font-bold">{tieuDe}</p>
        <button className="text-sub-title bg-sp text-white rounded px-2 py-1">
          BookMark
        </button>
      </div>
      <div className="c-card-content flex space-x-4">{renderSkill()}</div>
      <div className="c-card-content">
        <button className="bg-sp text-white px-2 py-1 rounded font-bold">
          # {nguoiTao}
        </button>
      </div>
      <div className="c-card-content">
        <p className="text-content">{noiDung}</p>
      </div>
    </div>
  );
}
