import { createSlice } from "@reduxjs/toolkit";
import { groupForTaskService } from "../../services/groupForTaskService";
import { requireForStudentService } from "../../services/requireForStudentService";
import { nguoiDungService } from "../../services/nguoiDungService";

const initialState = {
  dsGroupForTaskHocVien:[],
  groupForTaskViewMentorModel: [],
  groupForTask: {
    id: 0,
    danhSachHocVien: "",
    mentorId: "",
    ngayTao: "",
    isDone: false,
    taskId: 0,
    chuyenDeId: 0,
    maLoai: "",
    monHocId: 0,
  },
  baiHoc: {
    id: 0,
    capDo: 0,
    ghiChu: "",
    maLoai: "",
    moTa: "",
    noiDung: "",
    tenBaiHoc: "",
    thoiLuong: "",
    xemDemo: false,
  },
  currentRequire: {
    requireId: 0,
    noiDung: "",
    ketQua: "",
    hint: "",
    solution: "",
    videoSolution: "",
  },
  requires: [],
  requireForStudents: [],
  checkRequireForStudents: false,
  arrDanhSachHocVien: [],
  arrDSNguoiDung: [],
  thongTinMentor: {
    id: "",
    avatar: "",
    hoTen: "",
    soDt: "",
    urls: "",
  },
};

const groupForTaskReducer = createSlice({
  name: "groupForTask",
  initialState,
  reducers: {
    GetGroupForTaskByMentorId: (state, action) => {
      state.groupForTaskViewMentorModel = [...action.payload];
    },
    getGroupForTask: (state, action) => {
      state.groupForTask = action.payload;
      const newds = JSON.parse(state.groupForTask.danhSachHocVien);

      state.arrDanhSachHocVien = newds.map(e => e.trim());
    },
    getBaiHoc: (state, action) => {
      state.baiHoc = { ...action.payload };
    },
    setCurrentRequire: (state, action) => {
      state.currentRequire = { ...action.payload };
    },
    setRequires: (state, action) => {
      const newRequire = JSON.parse(action.payload);
      if (newRequire) state.requires = [...newRequire];
    },
    setRequireForStudentsByJson: (state, action) => {
      const getTodate = () => {
        const currentDate = new Date();
        return currentDate.toISOString().slice(0, 19);
      };
      let count = 0;
      let sst = 0;
      const newRequireForStudent = JSON.parse(action.payload).map(require => {
        let hvId = "";
        if (count < state.arrDanhSachHocVien.length) hvId = state.arrDanhSachHocVien[count];
        else {
          count = 0;
          hvId = state.arrDanhSachHocVien[count];
        }
        count++;
        sst++;
        return {
          id: sst,
          studentId: hvId,
          noiDung: require.noiDung,
          deadline: getTodate(),
          isDone: false,
          requireId: require.requireId,
          groupForTaskId: state.groupForTask.id,
          ngayTao: getTodate(),
          daXoa: false,
        };
      });
      if (newRequireForStudent.length !== 0) state.requireForStudents = [...newRequireForStudent];
    },
    setRequireForStudentByTaskId: (state, action) => {
      if (action.payload.length !== 0) {
        state.requireForStudents = [...action.payload];
        state.checkRequireForStudents = true;
      } else state.checkRequireForStudents = false;
    },
    putRequireForStudent: (state, action) => {
      const index = state.requireForStudents.findIndex(e => e.id === action.payload.id);
      state.requireForStudents[index].studentId = action.payload.studentId;
    },
    getDsNguoiDung: (state, action) => {
      const arr = action.payload.sort((a, b) => {
        return state.arrDanhSachHocVien.indexOf(a.id) - state.arrDanhSachHocVien.indexOf(b.id);
      });
      state.arrDSNguoiDung = [...arr];
    },
    getThongTinMentor: (state, action) => {
      state.thongTinMentor = { ...action.payload };
    },
    getDsGroupForTaskHocVienAction:(state,action)=>{
      state.dsGroupForTaskHocVien=[...action.payload]
    }
  },
});

export const {
  getBaiHoc,
  getGroupForTask,
  setRequires,
  setRequireForStudentsByJson,
  setRequireForStudentByTaskId,
  putRequireForStudent,
  getDsNguoiDung,
  getThongTinMentor,
  setCurrentRequire,
  GetGroupForTaskByMentorId,
  getDsGroupForTaskHocVienAction
} = groupForTaskReducer.actions;
export default groupForTaskReducer.reducer;

//--------- action async ------------

export const getGroupForTaskApi = (id) => {
  return async (dispatch) => {
    try {
      const result = await groupForTaskService.getGroupForTaskById(id);
      if (result.data.content) dispatch(getGroupForTask(result.data.content));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getBaiHocApi = (id) => {
  console.log(id)
  return async (dispatch) => {
    try {
      const result = await groupForTaskService.getBaiHocById(id);
      dispatch(getBaiHoc(result.data.content));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRequireForStudentByTaskIdApi = (id) => {
  return async (dispatch) => {
    try {
      const result = await requireForStudentService.getRequireForStudentByTaskId(id);
      dispatch(setRequireForStudentByTaskId(result.data.content));
    } catch (error) {
      console.log(error);
    }
  };
};

export const postOrPutRequireForStudentApi = (list) => {
  return async () => {
    try {
      await requireForStudentService.postOrPutRequireForStudent(list);
      console.log("postOrPutRequireForStudentApi success");
    } catch (err) {
      console.log(err);
      console.log("postOrPutRequireForStudentApi Not success");
    }
  };
};

export const layDsNguoiDungByStringApi = (ds) => {
  return async (dispatch) => {
    try {
      const result = await nguoiDungService.layDsNguoiDungByString(ds);
      if (result.data.content) dispatch(getDsNguoiDung(result.data.content));
    } catch (err) {
      console.log(err);
      console.log("layDsNguoiDungByString Not success");
    }
  };
};

export const getThongTinMentorApi = (id) => {
  return async (dispatch) => {
    try {
      const result = await groupForTaskService.getThongTinNguoiDung(id);
      dispatch(getThongTinMentor(result.data.content));
    } catch (error) {
      console.log(error);
      console.log("getThongTinMentorApi Not success");
    }
  };
};

export const GetGroupForTaskByMentorIdApi = (mentorId) => {
  return async (dispatch) => {
    try {
      const result = await groupForTaskService.GetGroupForTaskByMentorId(mentorId);
      if (result.data.content) dispatch(GetGroupForTaskByMentorId(result.data.content));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDsGroupForTaskHocVienApi=()=>{
  return async (dispatch) => {
    try {
      const result = await groupForTaskService.getDsGroupForTaskHocVienService();
      if (result.data.content) dispatch(getDsGroupForTaskHocVienAction(result.data.content));
    } catch (err) {
      console.log(err);
    }
  };
}
