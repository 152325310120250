import React from "react";
import servCodingCamp from "../../../../services/codingCam.service";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getListBinhLuanServ, setDataComment } from "../../tuongNhaSlice";
import classNames from "classnames";

export let ItemReplied = ({ data = {}, size = 1 }) => {
  let { id } = useParams();
  let { avatar, binhLuanId, tenNguoiBinhLuan, noiDung, ngayBinhLuan } = data;
  const dispatch = useDispatch();
  const currentDate = moment();
  const targetDate = moment(ngayBinhLuan);
  const daysDifference = currentDate.diff(targetDate, "days");
  const handleToggleLike = () => {
    // rangThai: 0: binh luan, 1: chinh sua binh luan, 2: reply, 3: like binh luan, 4: bo like
    const LIKE = 3;
    const UN_LIKE = 4;
    let option = data.daLike ? UN_LIKE : LIKE;
    let payload = {
      nopBaiId: id,
      noiDung: "",
      binhLuanId: binhLuanId,
      binhLuanChaId: 0,
    };
    servCodingCamp
      .postCommentVideo(option, payload)
      .then((res) => {
        dispatch(
          getListBinhLuanServ({
            nopBaiId: id,
            binhLuanId: 0,
            // 0 => binh luan cha
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-full space-x-5 text-content flex space-x-5 items-start">
      <img
        style={{
          width: size * 60,
          height: size * 60,
        }}
        src={avatar}
        className="rounded-full object-cover"
        alt=""
      />
      <div className="flex-grow space-y-2">
        <p>{tenNguoiBinhLuan}</p>
        <p>{noiDung}</p>
        <div className=" flex items-center space-x-10 text-gray-400">
          <span className="w-28">
            {daysDifference == 0 ? "Hôm nay" : daysDifference + " ngày trước"}
          </span>
          <span className="flex space-x-2">
            <span onClick={handleToggleLike}>
              {!data.daLike ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={classNames("w-6 h-6 cursor-pointer", {})}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-yellow-300 "
                >
                  <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                </svg>
              )}
            </span>

            <span className="text-gray-300">{data.luotLike}</span>
          </span>
          <span
            onClick={() => {
              let payloadComment = {
                replyTo: tenNguoiBinhLuan,
                commentStatus: 2,
                payload: {
                  nopBaiId: 0,
                  noiDung: "",
                  binhLuanId: 0,
                  binhLuanChaId: data.binhLuanId,
                },
              };

              dispatch(setDataComment(payloadComment));
            }}
            className="cursor-pointer text-gray-300"
          >
            Trả lời
          </span>
        </div>
      </div>
    </div>
  );
};
