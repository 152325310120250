import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";

import ItemTask from "./ItemTask";
import ProgressLine from "../../ProgressLine/ProgressLine";
import { loaiNhiemVu } from "../../../utils/taskUtils";
import { useTranslation } from "react-i18next";

export default function CollapseTask({ dataContent, index }) {
  const { t } = useTranslation()

  const [height, setHeight] = useState(0);
  let soLuongHoanThanh = dataContent?.danhSachNoiDung.filter((item) => {
    return item.hoanThanh;
  }).length;

  useEffect(() => {
    if (soLuongHoanThanh !== 0 && soLuongHoanThanh !== dataContent.danhSachNoiDung.length) {
      setHeight("auto");
    }
    return () => {
      setHeight("0");
    };
  }, [soLuongHoanThanh, dataContent.danhSachNoiDung.length]);
  if (!dataContent) {
    return null;
  }

  let renderDanhSachNhiemVu = () => {
    return dataContent.danhSachNoiDung
      .filter(
        ({ maLoaiNhiemVu }) =>
          maLoaiNhiemVu != loaiNhiemVu.HOMEWORK && maLoaiNhiemVu != loaiNhiemVu.RECORD
      )
      .map((item, index) => {
        return <ItemTask task={item} />;
      });
  };
  let renderListBaiTap = () => {
    let listBaiTap = dataContent.danhSachNoiDung.filter(
      ({ maLoaiNhiemVu }) =>
        maLoaiNhiemVu == loaiNhiemVu.HOMEWORK && maLoaiNhiemVu != loaiNhiemVu.RECORD
    );
    if (listBaiTap.length == 0) return;
    return (
      <div>
        <div className="w-5/5 my-2 mx-auto  h-0.5 bg-white"></div>
        <h1 className="text-content text-center tenChuyenDe py-5" style={{ fontSize: 20 }}>
          Bài tập về nhà
        </h1>
        {listBaiTap.map((item, index) => {
          return <ItemTask task={item} />;
        })}
      </div>
    );
  };
  let renderListRecord = () => {
    let listBaiTap = dataContent.danhSachNoiDung.filter(
      ({ maLoaiNhiemVu }) => maLoaiNhiemVu == loaiNhiemVu.RECORD
    );
    if (listBaiTap.length == 0) return;
    return (
      <div className="space-y-1.5">
        <h1 className="text-content text-center tenChuyenDe py-5" style={{ fontSize: 20 }}>

          {t('loTrinh.xemLaiBuoiHoc')}

        </h1>
        {listBaiTap.map((item, index) => {
          return <ItemTask task={item} />;
        })}
      </div>
    );
  };
  return (
    <div className=" c-col-full card-theme p-1 px-2 relative">
      <div className=" text-black overflow-hidden">
        <button
          aria-expanded={height !== 0}
          aria-controls="example-panel"
          onClick={() => setHeight(height === 0 ? "auto" : 0)}
          className="w-full space-y-2 w rounded "
        >
          <p className="font-bold text-base text-left">{dataContent.tenMonHoc}</p>
          <div className="flex items-center space-x-10 pt-1">
            <p className="text-content flex-shrink-0">
              {t('loTrinh.tienDo')}

            </p>
            <ProgressLine
              type="blue"
              percent={
                (soLuongHoanThanh / dataContent.danhSachNoiDung.length).toFixed(1) * 100 || 0
              }
            />
            <p className="flex-shrink-0 text-content">
              {t('loTrinh.nhiemVuHoanThanh')}
              : {soLuongHoanThanh}/{dataContent.danhSachNoiDung.length}
            </p>
            <i
              className={`fa fa-angle-up transform text-white ${height === "auto" ? "rotate-180" : "rotate-0"
                }`}
            ></i>
          </div>
        </button>
        <AnimateHeight id="example-panel" duration={500} height={height}>
          <div className="">
            <div className="space-y-1.5 c-card-content ">
              {renderDanhSachNhiemVu()}
              {renderListBaiTap()}
              {renderListRecord()}
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
}
