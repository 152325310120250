import React from "react";
import ReactHtmlParser from "react-html-parser";

export default function Content({ data }) {
  return (
    <div className="card-theme card-them h-full">
      <div className="c-card-content leading-8">
        {ReactHtmlParser(data.noiDung)}
      </div>
    </div>
  );
}
