import React from "react";
import { NavLink } from "react-router-dom";

export default function CardCompetitions({ data }) {
  return (
    <div className="c-col-25">
      <div className="card-theme overflow-hidden text-content">
        <div className="h-40">
          <img src={data.img} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="c-card-content font-medium text-sub-title">{data.title}</div>
        <div className="c-card-content flex space-x-7">
          <span className="flex items-center space-x-1">
            <i class="fa fa-gem"></i>
            <span>{data.diamon}</span>
          </span>
          <span className="flex items-center space-x-1">
            <i class="fab fa-bitcoin"></i>
            <span>{data.coin}</span>
          </span>
          <span className="flex items-center space-x-1">
            <i class="fa fa-gifts"></i>
            <span>{data.gift}</span>
          </span>
        </div>
        <div className="c-card-content flex justify-between">
          <NavLink to="/all-competitions" className="bg-main px-3 py-2 rounded-lg">
            KNOWLEDGE
          </NavLink>
          <p className="flex items-center space-x-1 font-bold">
            <span>{data.participants}</span>
            <span> participants</span>
          </p>
        </div>
      </div>
    </div>
  );
}
