import Lottie from "lottie-react";
import coin_lottie from "../../assets/lottie_json/18089-gold-coin.json";
import { IconKinhNghiem, IconTichCuc } from "./IconTichCucKinhNghiem";
import { Trans } from "react-i18next";
class Icon {
  constructor(icon, title, desc) {
    this.icon = icon;
    this.title = title;
    this.desc = desc;
  }
}
let iconNangDong = new Icon(
  <IconTichCuc />,
  (
    <div className="space-y-5">
      <IconTichCuc />
      <p className="text-title">
        <Trans i18nKey='moTaChung.diemTichCuc' />
      </p>
    </div>
  ),
  (
    <div className="flex items-center  justify-center">
      <div className=" text-base  space-y-3 text-gray-800">
        <p>Đây là khu vực hiển thị điểm tích cực của bạn</p>
        <p>Bạn sẽ nhận được điểm kinh nghiệm sau khi hoàn thành lộ trình, khoá học, bài học, bài tập,...</p>
        <p>Bạn sẽ bị trừ điểm tích cực sau khi không học trong 1 khoảng thời gian, nộp bài quá hạn, làm sai trắc nghiệm,..</p>
      </div>
    </div>
  )
);
let iconXp = new Icon(
  <IconKinhNghiem />,
  (
    <div className="space-y-5">
      <IconKinhNghiem />
      <p className="text-title">Điểm kinh nhiệm</p>
    </div>
  ),
  (
    <div className="flex items-center  justify-center">
      <div className=" text-base  space-y-3 text-gray-800">
        <p>Đây là khu vực hiển thị điểm kinh nhiệm của bạn</p>
        <p>Bạn sẽ nhận được điểm kinh nghiệm sau khi hoàn thành lộ trình, khoá học, bài học, bài tập,...</p>
        <p>Bạn sẽ bị trừ điểm năng động sau khi không học trong 1 khoảng thời gian, nộp bài quá hạn, làm sai trắc nghiệm,..</p>
      </div>
    </div>
  )
);
let iconXpHangNgay = new Icon(
  <i class="fa fa-calendar-alt"></i>,
  (
    <div className="flex items-center space-x-2">
      <i class="fa fa-calendar-alt"></i>
      <span>DAILY</span>
    </div>
  ),
  "Đây là điểm XP hằng ngày của các bạn"
);
let iconChuoiDiemDanh = new Icon(
  <i class="fa fa-bolt"></i>,
  (
    <div>
      <i class="fa fa-bolt"></i>
    </div>
  ),
  "Đây là chuỗi điểm danh của bạn"
);
let iconDiemDanh = new Icon(
  <i class="fa fa-bolt"></i>,
  (
    <div>
      <i class="fa fa-bolt"></i>
    </div>
  ),
  "Chúc mừng bạn đã điểm danh"
);
let iconTaskTheoTungHangMuc = new Icon(
  <i class="fa fa-list "></i>,
  (
    <div className="text-center space-y-5">
      <i class="fa fa-list text-5xl"></i>
      <p className="text-title">Task</p>
    </div>
  ),
  "Tổng số lượng task các bạn đã hoàn thành theo từng hạng mục"
);
let iconKhoaHocTheoTungHangMuc = new Icon(
  <i class="fa fa-graduation-cap"></i>,
  (
    <div>
      <i class="fa fa-graduation-cap"></i>
    </div>
  ),
  "Tổng số khóa học các bạn đã hoàn thành theo từng hạng mục"
);
let iconDuAnTheoTungHangMuc = new Icon(
  <i class="fa fa-chart-area"></i>,
  (
    <div>
      <i class="fa fa-chart-area"></i>
    </div>
  ),
  "Tổng số dự án các bạn đã hoàn thành theo từng hạng mục"
);
let iconSkillPyThon = new Icon(
  <img src={"./img/logo-python.png"} className="w-full h-full object-cover" alt="" />,
  (
    <div className="w-32 h-32">
      <img src={"./img/logo-python.png"} className="w-full h-full object-cover" alt="" />
    </div>
  ),
  "Python là một ngôn ngữ lập trình được sử dụng rộng rãi trong các ứng dụng web, phát triển phần mềm, khoa học dữ liệu và máy học (ML). Các nhà phát triển sử dụng Python vì nó hiệu quả, dễ học và có thể chạy trên nhiều nền tảng khác nhau."
);
let iconSkillDotNet = new Icon(
  <img src={"./img/dot-net.png"} className="w-full h-full object-cover" alt="" />,
  (
    <div className="w-32 h-32">
      <img src={"./img/dot-net.png"} className="w-full h-full object-cover" alt="" />
    </div>
  ),
  ".NET là công cụ giúp phát triển ứng dụng dễ dàng trên nhiều hệ điều hành khác nhau, với tính chất đồng nhất giúp đảm bảo chất lượng sản phẩm."
);
let iconSkillReactJs = new Icon(
  <img src={"./img/logo192.png"} className="w-full h-full object-cover" alt="" />,
  (
    <div className="w-32 h-32">
      <img src={"./img/logo192.png"} className="w-full h-full object-cover" alt="" />
    </div>
  ),
  "React JS là một thư viện JavaScript giúp phát triển giao diện người dùng (UI) độc lập với các hệ thống khác, tăng tốc độ hiển thị trang web, tối ưu hóa tương tác người dùng."
);
let iconSkillHtml = new Icon(
  <img src={"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg"} className="w-full h-full object-cover" alt="" />,
  (
    <div className="w-32 h-32">
      <img src={"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg"} className="w-full h-full object-cover" alt="" />
    </div>
  ),
  "HTML (Hypertext Markup Language) là ngôn ngữ đánh dấu siêu văn bản giúp tạo nên nội dung trên các trang web, đơn giản, dễ học và rất phổ biến trên toàn thế giới."
);
let iconSkillCss = new Icon(
  <img src={"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg"} className="w-full h-full object-cover" alt="" />,
  (
    <div className="w-32 h-32">
      <img src={"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg"} className="w-full h-full object-cover" alt="" />
    </div>
  ),
  "HTML (Hypertext Markup Language) là ngôn ngữ đánh dấu siêu văn bản giúp tạo nên nội dung trên các trang web, đơn giản, dễ học và rất phổ biến trên toàn thế giới."
);
let iconTongThoiGianHocTap = new Icon(
  <i class="fa fa-clock layout-icon text-title mr-3"></i>,
  (
    <div>
      <i class="fa fa-clock layout-icon text-title mr-3"></i>
    </div>
  ),
  "Đây là khu vực hiển thị tổng số thời gian của người dùng đã học"
);
let iconTongSoTracNghiem = new Icon(
  <i class="fa fa-pencil-ruler"></i>,
  (
    <div>
      <i class="fa fa-pencil-ruler"></i>
    </div>
  ),
  "Đây là khu vực hiển thị tổng số trắc nghiệm của người dùng đã làm"
);
let iconTongSoRunCode = new Icon(
  <i class="fa fa-laptop-code"></i>,
  (
    <div>
      <i class="fa fa-laptop-code"></i>
    </div>
  ),
  "Đây là khu vực hiển thị tổng số bài run code của người dùng đã làm"
);
let iconTongSoCapstone = new Icon(
  <i class="fab fa-galactic-republic"></i>,
  (
    <div>
      <i class="fab fa-galactic-republic"></i>
    </div>
  ),
  "Đây là khu vực hiển thị tổng số bài capstone của người dùng đã làm"
);
let iconTongSoDiem = new Icon(
  <i class="fab fa-tripadvisor"></i>,
  (
    <div>
      <i class="fab fa-tripadvisor"></i>
    </div>
  ),
  "Đây là khu vực hiển thị tổng số điểm  của người dùng"
);
let iconChungNhanDotNet = new Icon(
  (
    <div className="p-3 flex justify-center items-center card-theme rounded-lg">
      <img className="object-cover w-full h-full" src={"./img/dot-net.png"} alt="" />
    </div>
  ),
  (
    <div className="flex space-x-4">
      <div className="w-1/6 p-3 flex justify-center items-center card-theme rounded-lg">
        <img className="object-cover w-full h-full" src={"./img/dot-net.png"} alt="" />
      </div>
      <div className="w-5/6 text-content">
        <p>Bạn đã xuất sắc hoàn thành chứng nhận</p>
        <p className="font-bold">Chuyên đề: Lập trình .net từ cơ bản đến nâng cao. Tổng điểm: 88/100</p>
      </div>
    </div>
  ),
  (
    <div className="flex items-center justify-around">
      <button className="bg-main py-2 px-3">Xem Chứng Nhận</button>
      <button className="bg-main py-2 px-3">Chia Sẻ</button>
      <button className="bg-main py-2 px-3">Đăng ký nhận bảng giấy</button>
    </div>
  )
);
let iconChungNhanPython = new Icon(
  (
    <div className="p-3 flex justify-center items-center card-theme rounded-lg">
      <img className="object-cover w-full h-full" src={"./img/logo-python.png"} alt="" />
    </div>
  ),
  (
    <div className="flex space-x-4">
      <div className="w-1/6 p-3 flex justify-center items-center card-theme rounded-lg">
        <img className="object-cover w-full h-full" src={"./img/logo-python.png"} alt="" />
      </div>
      <div className="w-5/6 text-content">
        <p>Bạn đã xuất sắc hoàn thành chứng nhận</p>
        <p className="font-bold">Chuyên đề: Lập trình .net từ cơ bản đến nâng cao. Tổng điểm: 88/100</p>
      </div>
    </div>
  ),
  (
    <div className="flex items-center justify-around">
      <button className="bg-main py-2 px-3">Xem Chứng Nhận</button>
      <button className="bg-main py-2 px-3">Chia Sẻ</button>
      <button className="bg-main py-2 px-3">Đăng ký nhận bảng giấy</button>
    </div>
  )
);
let iconChungNhanReact = new Icon(
  (
    <div className="p-3 flex justify-center items-center card-theme rounded-lg">
      <img className="object-cover w-full h-full" src={"./img/logo192.png"} alt="" />
    </div>
  ),
  (
    <div className="flex space-x-4">
      <div className="w-1/6 p-3 flex justify-center items-center card-theme rounded-lg">
        <img className="object-cover w-full h-full" src={"./img/logo192.png"} alt="" />
      </div>
      <div className="w-5/6 text-content">
        <p>Bạn đã xuất sắc hoàn thành chứng nhận</p>
        <p className="font-bold">Chuyên đề: Lập trình .net từ cơ bản đến nâng cao. Tổng điểm: 88/100</p>
      </div>
    </div>
  ),
  (
    <div className="flex items-center justify-around">
      <button className="bg-main py-2 px-3">Xem Chứng Nhận</button>
      <button className="bg-main py-2 px-3">Chia Sẻ</button>
      <button className="bg-main py-2 px-3">Đăng ký nhận bảng giấy</button>
    </div>
  )
);
let iconCoin = new Icon(
  <Lottie loop={true} animationData={coin_lottie} style={{ cursor: "pointer" }} className="lg:w-10 md:w-9 lg:h-10 md:h-9" />,
  (
    <div>
      <Lottie loop={true} animationData={coin_lottie} style={{ cursor: "pointer" }} className="lg:w-10 md:w-9 lg:h-10 md:h-9" />
    </div>
  ),
  (
    <div className="text-content">
      <p>
        Công dụng: Bạn có thể dùng coin để mua vật phẩm để đổi voucher hoặc tăng điểm tích cực và điểm kinh nghiệm. Bạn sẽ nhận được coin sau khi học bài, hoàn
        thành bài tập , hoàn hành trắc nghiệm,... và ngày tích lũy.
      </p>
    </div>
  )
);
let iconLevel = new Icon(
  "Level",
  (
    <span>
      <i class="fa fa-level-up-alt"></i>
    </span>
  ),
  (
    <div className="text-content">
      <p>Đây là level hiện tại của bạn, học càng nhiều, level càng cao, nếu bạn học giỏi sẽ được ghi danh vào TOP USER</p>
    </div>
  )
);
let iconMonHocCuaChuyenDe = new Icon(
  <i class="fab fa-leanpub"></i>,
  (
    <span>
      <i class="fab fa-leanpub"></i>
    </span>
  ),
  (
    <div className="text-content">
      <p>Đây là tổng số môn học bạn cần học của chuyên đề này</p>
    </div>
  )
);
let iconVideoCuaChuyenDe = new Icon(
  <i class="fa fa-video"></i>,
  (
    <span>
      <i class="fa fa-video"></i>
    </span>
  ),
  (
    <div className="text-content">
      <p>Đây là tổng số video bạn cần xem của chuyên đề này</p>
    </div>
  )
);
let iconNhiemVuCuaChuyenDe = new Icon(
  <i class="fa fa-tasks"></i>,
  (
    <span>
      <i class="fa fa-tasks"></i>
    </span>
  ),
  (
    <div className="text-content">
      <p>Đây là tổng số nhiệm vụ bạn cần làm của chuyên đề này</p>
    </div>
  )
);
let iconTracNghiemCuaChuyenDe = new Icon(
  <i class="fa fa-pencil-ruler"></i>,
  (
    <span>
      <i class="fa fa-pencil-ruler"></i>
    </span>
  ),
  (
    <div className="text-content">
      <p>Đây là tổng số bài trắc nghiệm bạn cần làm của chuyên đề này</p>
    </div>
  )
);
let iconRuncodeCuaChuyenDe = new Icon(
  <i className="fas fa-laptop-code"></i>,
  (
    <span>
      <i className="fas fa-laptop-code"></i>
    </span>
  ),
  (
    <div className="text-content">
      <p>Đây là tổng số bài run code bạn cần làm của chuyên đề này</p>
    </div>
  )
);
let iconDuAnCuaChuyenDe = new Icon(
  <i class="fab fa-galactic-republic"></i>,
  (
    <span>
      <i class="fab fa-galactic-republic"></i>
    </span>
  ),
  (
    <div className="text-content">
      <p>Đây là tổng số bài capstone bạn cần làm của chuyên đề này</p>
    </div>
  )
);
export let iconDashBoard = {
  Level: iconLevel,
  Coin: iconCoin,
  Xp: iconXp,
  NangDong: iconNangDong,
  XpHangNgay: iconXpHangNgay,
  ChuoiDiemDanh: iconChuoiDiemDanh,
  TaskTheoTungHangMuc: iconTaskTheoTungHangMuc,
  DiemDanh: iconDiemDanh,
  KhoaHocTheoTungHangMuc: iconKhoaHocTheoTungHangMuc,
  DuAnTheoTungHangMuc: iconDuAnTheoTungHangMuc,

  SkillPyThon: iconSkillPyThon,
  SkillDotNet: iconSkillDotNet,
  SkillReactJs: iconSkillReactJs,
  TongThoiGianHocTap: iconTongThoiGianHocTap,
  TongSoTracNghiem: iconTongSoTracNghiem,
  TongSoRunCode: iconTongSoRunCode,
  TongSoCapstone: iconTongSoCapstone,
  TongSoDiem: iconTongSoDiem,
  ChungNhanDotNet: iconChungNhanDotNet,
  ChungNhanPython: iconChungNhanPython,
  ChungNhanReact: iconChungNhanReact,
};

export let iconTaskPage = {
  SkillHtml: iconSkillHtml,
  SkillCss: iconSkillCss,
  MonHocCuaChuyenDe: iconMonHocCuaChuyenDe,
  VideoCuaChuyenDe: iconVideoCuaChuyenDe,
  NhiemVuCuaChuyenDe: iconNhiemVuCuaChuyenDe,
  TracNghiemCuaChuyenDe: iconTracNghiemCuaChuyenDe,
  RuncodeCuaChuyenDe: iconRuncodeCuaChuyenDe,
  DuAnCuaChuyenDe: iconDuAnCuaChuyenDe,
  TaskTheoTungHangMuc: iconTaskTheoTungHangMuc,
  TongSoTracNghiem: iconTongSoTracNghiem,
  TongSoRunCode: iconTongSoRunCode,
  TongSoCapstone: iconTongSoCapstone,
  TongSoDiem: iconTongSoDiem,
  TongThoiGianHocTap: iconTongThoiGianHocTap,
};

export let iconBestPractice = {
  SkillHtml: iconSkillHtml,
  SkillCss: iconSkillCss,
  SkillPyThon: iconSkillPyThon,
  SkillDotNet: iconSkillDotNet,
  SkillReactJs: iconSkillReactJs,
};
