import React, { Component } from 'react'

import { Player, ControlBar, ReplayControl, PlaybackRateMenuButton } from 'video-react';
import HLSSource from './HLSSource';

import { Anchor } from 'antd';
import 'video-react/dist/video-react.css'

export default function VideoPlayer({ source }) {



    return (
        <div >
       
                {source ?
                    <Player playsInline fluid={false} width={"100%"} height={550}>

                        <HLSSource
                            isVideoChild
                            src={source && source}
                        />
                        <ControlBar>
                            <PlaybackRateMenuButton rates={[3, 2.5, 2, 1.5, 1]} order={10} />
                        </ControlBar>

                    </Player>
                    : ""}

        </div>
    )
}

