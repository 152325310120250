import { useDispatch, useSelector } from "react-redux";
import servCodingCamp from "../../services/codingCam.service";
import { selectThongTinMoRong, updateThongTinMoRongAction } from "../../redux/reducer/authReducer";

import bgCoin from "./dollar.png";
import toast from "react-hot-toast";
import HtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
export let ContentVatPham = ({ item, onFetch = () => { } }) => {
  const { t } = useTranslation()

  let info = useSelector(selectThongTinMoRong);
  let currentCoin = info.userInfo?.coin;
  let { hinhAnh, tenVatPham, moTa, hanSuDung, chiPhi, id } = item || {};
  let dispatch = useDispatch();

  const handleBuy = () => {
    servCodingCamp
      .muaVatPham(id)
      .then((res) => {
        if (!res.data.content) {
          // mua thất bại
          toast.error(res.data.message);
        } else {
          onFetch();
          toast.success("Mua vật phẩm thành công");
          dispatch(updateThongTinMoRongAction());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex text-title space-x-24 h-full w-full">
      <div className="w-96 space-y-5 text-center rounded ">
        <img src={hinhAnh} className="w-full h-4/5 object-contain rounded" alt="" />
        <h3 className="text-title">{tenVatPham}</h3>
      </div>
      <div style={{ width: "100%" }} className=" space-y-5 px-5">
        <h3 className="text-center text-title">
          {t('vatPham.thongTinVatPham')}

        </h3>
        <p className="">{HtmlParser(moTa)}</p>
        <p>
          {t('vatPham.hanDung')}
          : {hanSuDung == 0 ? t('vatPham.vinhVien') : `${hanSuDung} ${t('vatPham.ngay')}`} </p>
        <p className="flex items-center">
          <span className="mr-2">
            {t('vatPham.giaVatPham')}
            : {chiPhi} </span>
          <img src={bgCoin} className="w-10" alt="" />
        </p>
        {chiPhi <= currentCoin && (
          <button
            onClick={handleBuy}
            className=" py-2  text-white uppercase font-medium text-lg btn_vat_pham w-40"
          >
            {t('vatPham.muaVatPham')}

          </button>
        )}
        {/* show message sau khi mua vật phẩm */}
      </div>
    </div>
  );
};
