import _ from "lodash";
import React from "react";
import HtmlParser from "react-html-parser";

export const HINT = "HINT";
export const SOLUTION = "SOLUTION";
export const SOLUTION_VIDEO = "SOLUTION_VIDEO";
export const RESULT = "RESULT";

export default function ContentGuide({
  type,
  valueGuide,
  listStatus,
  handleBuyGuide,
  coin,
  label = "",
}) {
  let guide = _.find(listStatus, { maTieuDe: type });

  let renderContentGuide = () => {
    if (type == RESULT) {
      return HtmlParser(valueGuide);
    }
    if (guide?.trangThai) {
      // trangThai false =>  mở khoá
      return <div> {HtmlParser(valueGuide)}</div>;
    }
    return (
      <div className=" h-full flex justify-center">
        <button
          onClick={handleBuyGuide}
          className="card-theme rounded px-5 py-1 capitalize space-x-2 border-2 font-medium"
        >
          <i class="fa fa-lightbulb"></i>{" "}
          <span className="capitalize"> Xem {label}</span>{" "}
          <span>(- {coin} coin)</span>
        </button>
      </div>
    );
  };

  return <div>{renderContentGuide()}</div>;
}
