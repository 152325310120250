import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import httpServ from "../../services/http.service";
import servCodingCamp from "../../services/codingCam.service";
import { loaiNhiemVu } from "../../utils/taskUtils";
import classNames from "classnames";
import { Mark_Video } from "../../helper/watermark";
import localStorageServ from "../../services/locaStorage.service";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../components/video/VideoPlayer";

export default function ScreenVideo({
  baiHoc,
  handleHoanThanhBaiHoc,
  handleChangeNext,
  handleChangePrevious,
}) {

  const { t } = useTranslation()

  const [urlVideo, setUrlVideo] = useState("");
  const [isDisableHoanThanh, seIsDisableHoanThanh] = useState(true);
  const user = localStorageServ.userInfo.get();
  useEffect(() => {
    if (baiHoc.content?.noiDung) {
      let { noiDung, type } = baiHoc.content;
      let api =
        baiHoc.type == loaiNhiemVu.RECORD
          ? servCodingCamp.getUrlVideoRecord_FPT(noiDung)
          : servCodingCamp.getUrlVideo_FPT(noiDung);
      api.then((res) => {
        setUrlVideo(res.data);
      });
    }
  }, [baiHoc.content?.id]);
  useEffect(() => {
    let infor = user.hoTen + " - " + user.dienThoai;
    let myInterval = Mark_Video(infor);
    seIsDisableHoanThanh(true);
    setTimeout(() => {
      seIsDisableHoanThanh(false);
    }, 0 * 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [baiHoc?.content?.id]);

  let renderVideo = () => {
    return (
     <VideoPlayer source={urlVideo} />
    );
  };
  let isHoanThanh = baiHoc.content?.hoanThanh;
  return (
    <section style={{ minHeight: "60vh" }} className="  flex flex-col justify-center">
      <div className="border water-mark-wrapper">{renderVideo()}</div>
      <div className="flex c-card-content justify-between items-center">
        <div className="text-title font-medium w-3/5">{baiHoc.content?.tenBaiHoc}</div>
        <div className="   space-x-2 text-black w-96 h-16 ">
          <button
            onClick={() => {
              handleChangePrevious();
            }}
            className="text-content card-theme font-bold  px-1   text-center rounded-lg py-1 flex-1"
          >
            <i class="icon-main fa fa-angle-double-left"></i>
            {t('chiTietNhiemVu.baiTruocDo')}
          </button>
          <button
            onClick={() => {
              isHoanThanh && handleChangeNext();
            }}
            className={classNames(
              "text-content card-theme font-bold  px-1   text-center rounded-lg py-1 flex-1",
              {
                "bg-gray-500 cursor-not-allowed": !isHoanThanh,
              }
            )}
          >
            <i className="icon-main fa fa-angle-double-right"></i>
            {t('chiTietNhiemVu.baiTiepTheo')}

          </button>
          <button
            onClick={() => {
              !isHoanThanh && !isDisableHoanThanh && handleHoanThanhBaiHoc();
            }}
            className={`text-content card-theme   px-1   text-center rounded-lg py-1 flex-1 ${isHoanThanh ? "bg-gray-400 cursor-not-allowed" : "text-yellow"
              }`}
          >
            <i className=" fa fa-check text-white"></i>

            {isHoanThanh ? (
              "Đã hoàn thành"
            ) : isDisableHoanThanh ? (
              <Tooltip
                placement="topLeft"
                title="Bạn cần xem tiếp để hoàn thành"
                arrowPointAtCenter
                defaultVisible={false}
              >
                <span className=" text-gray-600">
                  {t('chiTietNhiemVu.hoanThanh')}


                </span>
              </Tooltip>
            ) : t('chiTietNhiemVu.hoanThanh')
            }
          </button>
        </div>
      </div>
    </section>
  );
}
