import React, { useEffect, useState } from "react";
import Chart from "@uiw/react-heat-map";
import servCodingCamp from "../../services/codingCam.service";
import { Tooltip } from "antd";
import moment from "moment";
const convertedArray = (arr) => {
  return arr.map((item) => {
    const dateParts = item.ngayDiemDanh.split(" ")[0].split("/");
    const date = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;

    const timeParts = item.ngayDiemDanh.split(" ")[1].split(":");
    const count = parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);

    return { date, count };
  });
};
const monthLabels = [
  "T 1",
  "T 2",
  "T 3",
  "T 4",
  "T 5",
  "T 6",
  "T 7",
  "T 8",
  "T 9",
  "T 10",
  "T 11",
  "T 12",
];
const   HeatMapTimeOnline = () => {
  const [listDiemDanh, setListDiemDanh] = useState([]);

  function fetchListDiemDanh() {
    servCodingCamp
      .getLayDanhSachDiemDanh()
      .then((res) => {
        let data = convertedArray(res.data.content);
        setListDiemDanh(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchListDiemDanh();
  }, []);
  return (
    <Chart
      // space={}
      // legendCellSize={0}
      value={listDiemDanh}
      weekLabels={["CN", "Th2", "Th3", "Th4", "Th5", "Th6", "Th7"]}
      // monthLabels={[]}
      monthLabels={monthLabels}
      startDate={new Date("2024/01/01")}
      endDate={new Date("2024/12/31")}
      rectSize={16}
      // width={600}
      className="w-full "
      rectRender={(props, data) => {
        return (
          <Tooltip
            placement="top"
            title={` ${moment(data.date).format("DD/MM/YYYY")} số phút online: ${
              data.count || 0
            } phút`}
          >
            <rect {...props} />
          </Tooltip>
        );
      }}
      legendCellSize={20}
      panelColors={{
        0: "#f4decd",
        2: "#e4b293",
        4: "#d48462",
        10: "#c2533a",
        20: "#ad001d",
      }}
    />
  );
};

export default HeatMapTimeOnline;
