export let languageEditor = {
  js: "javascript",
  py: "python",
};
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const levelClassName = {
  easy: "bg-green-700 text-green-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
  medium:
    "bg-yellow-700 text-yellokgw-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
  hard: "bg-red-700 text-red-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
};
export function checkAnswers(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].message !== "Right answer") {
      return false;
    }
  }
  return true;
}
