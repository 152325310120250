import React from "react";
import { Tabs } from "antd";
import "./ContentTabCol.scss";
import { useTranslation } from "react-i18next";
export default function ContentTabCol(props) {
  const { t } = useTranslation()

  const renderContentByTab = () => {

    return props.data.map((d, i) => {
      return (
        <Tabs.TabPane
          style={{ padding: 0 }}
          tab={
            <span
              style={{ padding: `0px 0px` }}
              // style={{ padding: `${paddingTabItemY}px ${paddingTabItemX}px` }}
              className=" inline-block  itemCol text-black rounded-full w-10 h-10 leading-10 text-center"
            >
              {d.label}
            </span>
          }
          key={d.key}
        >
          {d.children}
        </Tabs.TabPane>
      );
    });
  };
  return (
    <div className="c-card-content project_instruction space-y-3d">
      <p className="text-title font-bold text-white my-2">
        <i className="fas fa-trophy mr-2"></i>
        {t('chiTietNhiemVu.cacBuocThucHien')}
      </p>
      <Tabs
        // tabPosition="left"
        size="small"
        className="w-full "
        tabBarGutter={30}
        tabBarStyle={{
          background:
            " radial-gradient(158.28% 164.47% at 100% 135.53%, #F8DB77 8.4%, #ECA937 31.76%, #FFC94C 51.58%) ",
          borderWidth: "0",
          borderLeft: "0",
          padding: `5px`,
          height: 50,
          border: "none",
          borderRadius: 8,
        }}
        defaultActiveKey="1"
      >
        {renderContentByTab()}
      </Tabs>
    </div>
  );
}
