import React, { useEffect } from "react";
import ScreenVideo from "./ScreenVideo";
import SidebarLearnVideo from "./SidebarLearnVideo";
import TabLearnVideo from "./TabLearnVideo";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import servCodingCamp from "../../services/codingCam.service";
import { useParams } from "react-router-dom";
import { loaiNhiemVu } from "../../utils/taskUtils";
import ModalConfetti from "../../components/ModalConfetti/ModalConfetti";
import { selectThongTinMoRong, updateThongTinMoRongAction } from "../../redux/reducer/authReducer";

export default function LearnVideoPage() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectThongTinMoRong);
  let { chuyenDeId, taskId, monHocId } = useParams();
  const [listBaiHoc, setListBaiHoc] = useState([]);
  const [baiHoc, setBaiHoc] = useState({});

  useEffect(() => {
    getListBaiHoc();
  }, []);
  // ! check trạng thái bài học
  useEffect(() => {
    // getListHoanThanhHoc();
  }, [baiHoc?.content?.id]);
  // ! lấy danh sách bài học
  let getListBaiHoc = (isFristFetch = true) => {
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        let { noiDungNhiemVu: listData, maLoaiNhiemVu, tenNhiemVu } = res.data.content;
        let listBaiHoc = [];
        let index = 0;
        if (maLoaiNhiemVu == loaiNhiemVu.RECORD) {
          listData.forEach((baiHoc, index) => {
            listBaiHoc.push({
              content: {
                tenBaiHoc: tenNhiemVu,
                id: baiHoc.baiHocId,
                noiDung: baiHoc.noiDung,
              },
              type: loaiNhiemVu.RECORD,
              isVideo: true,
              viTri: index++,
            });
          });
        } else {
          listData.forEach((chuongHoc) => {
            listBaiHoc.push({
              content: chuongHoc.tenChuong,
              isVideo: false,
              viTri: index++,
            });

            chuongHoc.noiDungBaiHoc.forEach((baiHoc) => {
              listBaiHoc.push({
                chuongHocId: chuongHoc.chuongHoc,
                content: baiHoc,
                isVideo: true,
                viTri: index++,
                type: loaiNhiemVu.VIDEO,
              });
            });
          });
        }
        let firstVideoIndex = listBaiHoc.findIndex((item) => {
          return item.isVideo;
        });
        isFristFetch && setBaiHoc(listBaiHoc[firstVideoIndex]);

        setListBaiHoc(listBaiHoc);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let handleChangeBaiHoc = (baiHoc) => setBaiHoc(baiHoc);
  const handleHoanThanhBaiHoc = () => {
    // khi nào ấn sẽ lấy được monHocId mới nhất

    const payloadHoanThanhTask = {
      nguoiDungId: userInfo.id,
      chuyenDeId: chuyenDeId * 1,
      monHocId: monHocId * 1,
      taskId: taskId * 1,
      maLoai: baiHoc.type,
      baiHocId: baiHoc.content.id * 1,
      soPhutThucHien: 0,
    };

    servCodingCamp
      .postHoanThanhHoc(payloadHoanThanhTask)
      .then((res) => {
        // dispatch(setPhanThuong(res.data.content));
        // getListHoanThanhHoc();
        getListBaiHoc(false);
        handleChangeNext();
        dispatch(updateThongTinMoRongAction());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let handleChangeNext = () => {
    for (let index = baiHoc.viTri + 1; index < listBaiHoc.length; index++) {
      if (index == listBaiHoc.length) {
        return;
      }
      const baiHoc = listBaiHoc[index];
      if (baiHoc.isVideo) {
        handleChangeBaiHoc(baiHoc);
        return;
      }
    }
  };
  let handleChangePrevious = () => {
    for (let index = baiHoc.viTri - 1; index >= 0; index--) {
      const baiHoc = listBaiHoc[index];
      if (baiHoc.isVideo) {
        handleChangeBaiHoc(baiHoc);
        return;
      }
    }
  };

  // let widthVideo = collapsed ? "c-col-full" : "c-col-70";
  let widthVideo = "";
  return (
    <div className="c-card-content overflow-hidden relative  w-full ">
      <ModalConfetti />
      <div className="flex  relative pt-10 ">
        <div className={`space-y-6 transition duration-700   ${widthVideo} flex-grow`}>
          <ScreenVideo
            baiHoc={baiHoc}
            handleChangeBaiHoc={handleChangeBaiHoc}
            listBaiHoc={listBaiHoc}
            handleHoanThanhBaiHoc={handleHoanThanhBaiHoc}
            handleChangeNext={handleChangeNext}
            handleChangePrevious={handleChangePrevious}
          />
          <TabLearnVideo baiHoc={baiHoc} />
        </div>

        <SidebarLearnVideo
          listBaiHoc={listBaiHoc}
          handleChangeBaiHoc={handleChangeBaiHoc}
          activeID={baiHoc?.content?.id}
        />
      </div>
    </div>
  );
}
