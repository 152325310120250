import React, { useEffect, useState } from "react";
import httpServ from "../../services/http.service";
import ContentQuizz_ViewAnsers from "../../components/Content_Quizz/Content_Quizz_ViewAnswer";
import { Radio } from "antd";
import axios from "axios";
// import Content_Quizz_DauVao_ShowDapAn from "./Content_Quizz_DauVao_ShowDapAn/Content_Quizz_TestDauVao";

export default function TestQuizPage_ShowDapAn() {
  const [optionList, setOptionList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(optionList[0]);
  useEffect(() => {
    axios
      .get("https://api.techx.edu.vn/api/bai-test/quiz-ma-test")
      .then((res) => {
        console.log(res);
        setOptionList(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className=" c-card-content min-h-screen bg c-card-content flex flex-col space-y-4 px-10 ">
      <div className="flex justify-center">
        <Radio.Group onChange={handleRadioChange} value={selectedOption}>
          {optionList.map((option) => (
            <Radio.Button size={"large"} key={option} value={option}>
              {option}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <ContentQuizz_ViewAnsers maBaiTest={selectedOption} />
    </div>
  );
}
