import React from "react";
import "./tabSubContent.css";
export default function TabSubContentQuiz({ anserOption }) {
  const renderDetailTask = () => {
    return [
      {
        label: <span className="text-content px-5 font-bold">ANSWER</span>,
        key: "1",
        children: (
          <div className="c-card-content card-theme rounded  text-content space-y-1 leading-7">
            {anserOption}
          </div>
        ),
      },
      {
        label: <span className="text-content px-5 font-bold">HINT</span>,
        key: "3",
        children: (
          <div className="c-card-content card-theme rounded  text-content space-y-1 leading-7">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt soluta
            repudiandae quod perferendis quo numquam quasi. Fugiat sed
            consequatur dolorum, nam nulla omnis, in sint facere exercitationem
            eaque, ea praesentium.
          </div>
        ),
      },
      {
        label: <span className="text-content px-5 font-bold">SOLUTION</span>,
        key: "2",
        children: (
          <div className="c-card-content card-theme rounded  text-content space-y-1 leading-7">
            {" "}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt soluta
            repudiandae quod perferendis quo numquam quasi. Fugiat sed
            consequatur dolorum, nam nulla omnis, in sint facere exercitationem
            eaque, ea praesentium.
          </div>
        ),
      },
    ];
  };
  return (
    <div style={{ maxHeight: "40vh", minHeight: 200 }} id="sub_content_quiz">
      {/* <ContentTab
        // paddingHeaderX={10}
        paddingHeaderX={0}
        paddingHeaderY={0}
        // paddingHeaderY={widthWindow > 1024 ? 12 : 7}
        paddingTabItemX={10}
        paddingTabItemY={15}
        backgroundHeader="transparent"
        data={[renderDetailTask()[0]]}
      /> */}
      <p className="practice_tieuDe  text-content font-medium my-3">
        Câu trả lời:
      </p>
      <div className="c-card-content card-theme rounded  text-content space-y-1 leading-7">
        {anserOption}
      </div>
    </div>
  );
}
