import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lisLoTrinh: [],
};

const loTrinhSlice = createSlice({
  name: "loTrinhSlice",
  initialState,
  reducers: {
    setListLoTrinh: (state, action) => {
      state.lisLoTrinh = action.payload;
    },
  },
});

export const { setListLoTrinh } = loTrinhSlice.actions;

export default loTrinhSlice.reducer;

export let selectListLoTrinh = (state) => state.loTrinhSlice.lisLoTrinh;
