import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import servCodingCamp from "../../services/codingCam.service";

const initialState = {
  personal: {
    profile: {},
    danhSachVideo: [],
  },
  videoWall: {
    thongTinUser: {},
  },
  listBinhLuan: [],
  dataComment: {
    payload: {
      nopBaiId: 0,
      noiDung: "",
      binhLuanId: 0,
      binhLuanChaId: 0,
    },
    replyTo: "",
    commentStatus: 0,
  },
  listThanhTich: [],
};
export const getListBinhLuanServ = createAsyncThunk(
  "tuongNhaSlice/getListBinhLuan",
  async (payload) => {
    let { nopBaiId, binhLuanId } = payload;
    const response = await servCodingCamp.getBinhLuanVideo(nopBaiId, binhLuanId);
    return response.data.content.reverse();
  }
);
export const getDetailVideoWallServ = createAsyncThunk(
  "tuongNhaSlice/getDetailVideoWallServ",
  async (payload) => {
    const response = await servCodingCamp.getDetailVideoWall(payload);
    return response.data.content;
  }
);
const tuongNhaSlice = createSlice({
  name: "tuongNhaSlice",
  initialState,
  reducers: {
    setPersonalState: (state, action) => {
      state.personal = action.payload;
    },
    setVideoWall: (state, action) => {
      state.videoWall = action.payload;
    },
    setDataComment: (state, action) => {
      state.dataComment = action.payload;
    },
    setListThanhTich: (state, action) => {
      state.listThanhTich = action.payload;
    },
  },
  extraReducers: {
    [getListBinhLuanServ.fulfilled]: (state, action) => {
      state.listBinhLuan = action.payload;
    },
    [getDetailVideoWallServ.fulfilled]: (state, action) => {
      state.videoWall = action.payload;
    },
  },
});

export const { setPersonalState, setVideoWall, setDataComment, setListThanhTich } =
  tuongNhaSlice.actions;

export default tuongNhaSlice.reducer;

export const selectPersionalProfile = (state) => state.profile;
export const selectPersonalState = (state) => state.tuongNhaSlice.personal;
export const selectVideoWall = (state) => state.tuongNhaSlice.videoWall;
export const selectListBinhLuan = (state) => state.tuongNhaSlice.listBinhLuan;
export const selectDataComment = (state) => state.tuongNhaSlice.dataComment;
export const selectListThanhTich = (state) => state.tuongNhaSlice.listThanhTich;
