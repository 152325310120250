import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { checkAnswers } from "./utils";

const initialState = {
  dataRuncode: [],
  testCaseRuncode: [],
  // ! đáp án của hệ thống sau khi submit
};
export const checkSubmitDataRuncode = createAsyncThunk(
  // ! api xử lý đáp án của user
  "users/checkSubmitDataRuncode",
  async (data, thunkAPI) => {
    try {
      const res = await axios({
        url: "https://runcode.cyberlearn.vn/submit",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data,
      });
      // console.log(res);

      const check = checkAnswers(res.data);
      if (check) {
        // gọi api lưu điểm
        let payload = {
          // id: 0,
          // taskId: taskId,
          // requireId: requireId,
          // baiLam: baiLam,
          // maLoai: maLoai,
          // chuyenDeId,
          // monHocId,
        };
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const runcodeSlice = createSlice({
  name: "runcodeSlice",
  initialState,
  reducers: {
    resetResultTestCase: (state, action) => {
      state.testCaseRuncode = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkSubmitDataRuncode.fulfilled, (state, action) => {
      state.testCaseRuncode = action.payload;
    });
  },
});

export const { resetResultTestCase } = runcodeSlice.actions;

export default runcodeSlice.reducer;
