import { Header } from "antd/lib/layout/layout";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function GuestHeader() {
  let navigate = useNavigate();
  return (
    <Header
      style={{
        background: "black",
      }}
      className="flex h-20  fixed top-0 left-0 w-full z-50 items-center  justify-between"
    >
      <div
        onClick={() => {
          navigate("/dash-broad");
        }}
        className="logo w-1/12 h-ful flex items-center cursor-pointer"
      >
        <img src="https://techx.edu.vn/img/logo/logo-cyber.svg" alt="" />
      </div>
      <button
        className="bg-gray-800 border-none px-10 rounded text-red-500 text-xl p-4 font-medium   cursor-pointer  "
        onClick={() => {
          window.location.href = "/login";
        }}
      >
        Đăng nhập
      </button>
    </Header>
  );
}
