import React from "react";
import { Select } from "antd";
import { useMonaco } from "@monaco-editor/react";

export default function SelectThemes({ setThemes, themes }) {
  // console.log("a", monaco?.editor.create());
  return (
    <>
      {themes === "vs-dark" ? (
        <button
          className="outline-none  focus:outline-none rounded  "
          onClick={() => {
            setThemes("light");
          }}
        >
          <i className="fa-solid fa-moon  text-white text-lg "></i>
        </button>
      ) : (
        <button
          className="outline-none focus:outline-none rounded "
          onClick={() => {
            setThemes("vs-dark");
          }}
        >
          <i className="fa-solid fa-sun  text-white text-lg "></i>
        </button>
      )}
    </>
  );
}
