import React, { useState } from "react";
import FormDieuKhoan from "./FormDieuKhoan";
import ModalDieuKhoan from "./ModalDieuKhoan";

export default function FormEmail({ handleSuccess = () => {} }) {
  return (
    <div className="w-full h-screen p-3 space-y-5 flex justify-center items-center text-title">
      <div className=" flex flex-col items-center card-theme w-3/5 rounded p-10">
        <p className="uppercase  text-lg lg:text-xl text-title">Kiểm tra đầu vào</p>
        <div className="text-content text-base md:text-lg"></div>
        <FormDieuKhoan handleSuccess={handleSuccess} />
      </div>
    </div>
  );
}
