import React from "react";
import ModelContentIcon from "../../../components/ModalContentIcon/ModalContentIcon";
import { iconBestPractice } from "../../../components/ModalContentIcon/utilities";
import servCodingCamp from "../../../services/codingCam.service";
import localStorageServ from "../../../services/locaStorage.service";
import savedIcon from "./icon/saved.svg";
import notSavedIcon from "./icon/notSaved.svg";
import bgCard from "./background.png";
import { NavLink } from "react-router-dom";
let styleButton = {
  width: "96px",
  height: "41px",
  background: "linear-gradient(360deg, #FFC94C 14.14%, #F4A408 100%)",
  borderRadius: "9px",
  color: "white",
  fontWeight: 700,
};
let styleText = {
  background: "linear-gradient(360deg, #FFC94C 14.14%, #F4A408 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};

export default function CardBestPractice({ data, isSave }) {
  let { id: userId } = localStorageServ.userInfo.get();
  let handleToggleSave = () => {
    let payload = {
      // id mặc định là 0, be quy định
      id: 0,
      nguoiDungId: localStorageServ.userInfo.get().id,
      baiHocId: data.id,
      // chuyenDeId  sau này BE trả sau
      chuyenDeId: 1,
    };
    // remove: 0: lưu bài , 1 = xóa bài lưu
    let option = isSave ? 1 : 0;
    servCodingCamp
      .postToggleSaveBestPratice(option, payload)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let handleViewsPratice = () => {
    // tang luot xem
    let payload = {
      nguoiDungId: userId,
      chuyenDeId: 1,
      maLoai: "TAILIEU",
      baiHocId: data.id,
    };
    servCodingCamp
      .postViewsBestPratice(payload)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="c-col-25">
      <div
        style={{
          backgroundImage: `url(${bgCard})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundSize: "100% 100%",
        }}
        className="  relative c-card-content h-96 flex flex-col justify-between"
      >
        {/* <img src={bgCard} alt="" className="absolute w-full top-0 left-0" /> */}
        <button className="absolute top-4 right-4" onClick={handleToggleSave}>
          <img className="w-6 " src={!isSave ? savedIcon : notSavedIcon}></img>
        </button>
        <div className="h-40 w-40 mx-auto pt-10">
          <img src={data.img} alt="" className="w-full h-full object-cover" />
        </div>
        <div className=" font-medium text-sub-title relative pl-5 py-4 ">
          <div
            style={{
              background:
                "linear-gradient(360deg, #FFC94C 14.14%, #F4A408 100%)",
            }}
            className="absolute top-0 left-0 w-2 bg-red-50 h-full"
          ></div>
          <p style={styleText}>{data.tenBaiHoc}</p>
        </div>
        <div className="c-card-content flex space-x-2">
          <div className="w-5 h-5 mx-1 inline-bl˚ock  bg-  rounded-xl">
            {/* <ModelContentIcon loai={iconBestPractice.SkillHtml} /> */}
          </div>
          <div className="w-5 h-5 mx-1 inline-block  bg-white  rounded-xl">
            {/* <ModelContentIcon loai={iconBestPractice.SkillCss} /> */}
          </div>
          <p className="flex items-center space-x-1 font-bold text-content">
            <i class="fa fa-eye"></i>
            <span>{data.luotXem}</span>
          </p>
        </div>
        <div className="c-card-content flex justify-center">
          <button onClick={handleViewsPratice} style={styleButton}>
            <NavLink to={`/detail-best-pratice/${data.id}`}>Chi tiết</NavLink>
          </button>
          {/* <button
            onClick={handleToggleSave}
            className="bg-main px-3 py-2 rounded-lg"
          >
            {!isSave ? "Lưu" : "Bỏ lưu"}
          </button> */}
        </div>
      </div>
    </div>
  );
}
/**1 */
