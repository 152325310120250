import React from "react";
import { convertData } from "./util";
import TitleItemPratice from "./TitleItemPratice";

export default function DailiActive({ dataPratice }) {
  let { danhSachNoiDung, phanThuong } = dataPratice;

  const renderDailyActives = () => {
    return danhSachNoiDung?.map((item, index) => {
      return <TitleItemPratice item={item} key={index} />;
    });
  };
  return (
    <div className="c-col-full  ">
      <div className="card-theme c-card-content space-y-5">
        <p className=" text-white text-title">
          <i className="fa fa-circle  text-2xl mr-2"></i> Hoạt động hằng ngày
        </p>
        <div className=" ">
          <div className="px-10">{renderDailyActives()}</div>
        </div>
      </div>
    </div>
  );
}
