import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import "./index.scss";
import store from "./redux/store";


import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';


if (process.env.NODE_ENV === "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
