import React, { useState } from "react";
import { useEffect } from "react";
import Content_Quizz_TestDauVao from "../../components/Content_Quizz_DauVao/Content_Quizz_TestDauVao";
import FormEmail from "./FormEmail";

export default function TestQuizPage() {
  const [isShowQuiz, setIsShowQuiz] = useState(false);
  // return  <Content_Quizz_TestDauVao />;
  return (
    <div className="min-h-screen">
      {!isShowQuiz ? (
        <FormEmail
          handleSuccess={() => {
            setIsShowQuiz(true);
          }}
        />
      ) : (
        <Content_Quizz_TestDauVao />
      )}
    </div>
  );
}
