import React, { useEffect, useState } from "react";
import axios from "axios";
import Collapse from "./Collapse";

export default function WorkShop() {
  const [listWorkShop, setListWorkShop] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apicrm.cybersoft.edu.vn/api/khoahoc/danh-sach-khoa-hoc-free`)
      .then((res) => {
        console.log(res);
        setListWorkShop(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    // /`´
  }, []);
  return (
    <div className="container space-y-10 mt-10">
      {listWorkShop.map((item, index) => {
        return <Collapse data={item} key={index} />;
      })}
    </div>
  );
}
