import React from "react";
import AssignmentAndDetail from "./AssignmentAndDetail";
import ReactHtmlParser from "react-html-parser";
import { loaiNhiemVu } from "../../utils/taskUtils";
import TimeRemain from "./TimeRemain";
import { typeTrangThaiNop } from "../StepProjectInstruction/util";
import ButtonGiaHanBaiTap from "./ButtonGiaHanBaiTap";
import { CHUYEN_DE_INTERN } from "../../pages/TaskPage/utils/utils";
import { useTranslation } from "react-i18next";
function InformationProject({ trangThaiTongBaiTap, detail, maNhiemVu }) {
  const { t } = useTranslation()

  let { deadline, ghiChu, tenNhiemVu, loaiChuyenDe } = detail;
  let label = loaiChuyenDe == CHUYEN_DE_INTERN ? "task" : "bài tập";
  return (
    <div className="card-theme overflow-hidden ">
      <div className="c-card-content ">
        <h2 className="text-title "></h2>
        <div className="text-title font-bold pl-2">
          <p className=" ">
            <div className="icon_theme w-11 h-11  ">
              <i className="fas fa-trophy"></i>
            </div>
            {t('chiTietNhiemVu.tenNhiemVu')}
            : {tenNhiemVu}
          </p>

          {trangThaiTongBaiTap?.trangThai == typeTrangThaiNop.HET_HAN && (
            <div className="flex flex-col items-center space-y-5">
              <p className="teaxt-center text-red-700 capitalize">{label} này đã quá hạn nộp</p>
              <ButtonGiaHanBaiTap nopBaiId={trangThaiTongBaiTap?.noiDung[0]?.id || 0} />
            </div>
          )}
          <p className="text-content">{ReactHtmlParser(ghiChu)}</p>
        </div>
        {/* Nếu không phải là mã loại nhiệm vụ là bài tập thì k có section phân chia nhóm */}
        <TimeRemain label={label} trangThaiTongBaiTap={trangThaiTongBaiTap} deadline={deadline} />
      </div>
      {maNhiemVu === loaiNhiemVu.BAITAP ? (
        ""
      ) : (
        <div className="flex">
          <AssignmentAndDetail />
        </div>
      )}
    </div>
  );
}

export default InformationProject;
