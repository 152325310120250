import React from "react";
import { useSelector } from "react-redux";
import BtnFollow from "../BtnFollow/BtnFollow";
import { selectPersonalState } from "../tuongNhaSlice";
import { useTranslation } from "react-i18next";

let Static = () => {
  let { profile, danhSachVideo } = useSelector(selectPersonalState);
  const { t } = useTranslation()

  let { luotLike, dangFollow, follow } = profile;
  return (
    <div className="avartar-static flex text-content space-x-5 h-full items-center flex-shrink-0 lg:flex-col lg:items-start lg:space-x-0">
      <div className="number">
        <strong>{dangFollow}</strong>
        <span>{t('tuongCuaToi.dangTheoDoi')}</span>
      </div>
      <div className="number">
        <strong>{follow}</strong>
        <span>{t('tuongCuaToi.nguoiTheoDoi')}</span>
      </div>

      <div className="number">
        <strong>{luotLike}</strong>
        <span>{t('tuongCuaToi.luotThich')}</span>
      </div>
      {/* <div className="number">
        <strong>{danhSachVideo?.length | 0}</strong>
        <span>Dự án</span>
      </div> */}
    </div>
  );
};
export default function AvatarWall() {
  let { profile, danhSachVideo } = useSelector((state) => state.tuongNhaSlice.personal);

  return (
    <div
      id="avatar_wall"
      className="  relative flex-shrink-0 space-y-5 mb-5 flex justify-between items-start "
    >
      <div className="flex justify-between items-center space-x-10 w-full">
        <div className="flex-shrink-0 flex items-center space-x-5">
          <img src={profile.avatar} alt="" className="w-32 h-32 object-cover rounded-full" />
          <div className=" space-y-3">
            {" "}
            <div className="flex space-x-3">
              <p className="name capitalize text-2xl text-title ">{profile.hoTen}</p>
              <p className="name capitalize rank">{profile.danhHieu?.tenDanhHieuEN}</p>
            </div>
            <div className="space-x-2 text-content">
              <span>Email</span>
              <span className="fo16nt-medium">{profile.email}</span>
            </div>
          </div>
        </div>
        <div className="flex-grow space-y-5 h-full flex flex-col justify-between items-end ">
          <BtnFollow />
          <Static />
        </div>
      </div>
    </div>
  );
}
