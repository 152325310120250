import React, { useEffect } from "react";
import Countdown from "react-countdown";
import sadIcon from "./sad.png";
import servVatPham from "../../services/vatPham.service";
import ButtonGiamThoiGian from "./ButtonGiamThoiGian";
export default function LamLaiContent({ data, fetchThongTinBaiNop }) {
  const rendererTimer = ({ hours, minutes, seconds }) => {
    if (hours == 0 && minutes == 0 && seconds == 0) {
    }
    return (
      <span className="font-medium text-lg">
        {hours} giờ {minutes} phút {seconds} giây
      </span>
    );
  };
  let hms = data.thoiGianCho; // your input string
  let a = hms.split(":"); // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

  return (
    <div
      sty
      className="w-full    flex flex-col items-center justify-center  text-white space-y-10 xl:space-y-3 xl:mt-5 mt-10"
    >
      <img className="w-28 h-28 object-cover -mb-1" src={sadIcon} />
      <p className="text-xl ">Bạn đã không vượt qua bài kiểm tra, vui lòng thử lại sau:</p>
      <Countdown
        // onComplete={console.log("compllete")}
        // date={Date.now() + 500000000}
        date={Date.now() + seconds * 1000}
        // date={Date.now() + 10 * 1000}
        renderer={rendererTimer}
      />
      <ButtonGiamThoiGian nopBaiId={data.id} fetchThongTinBaiNop={fetchThongTinBaiNop} />
    </div>
  );
}
