import React from "react";

export default function CommentOfPosted() {
  return (
    <div className="flex">
      <div style={{ width: "5%" }} className=""></div>
      <div style={{ width: "95%" }}>
        <div className="c-card-content text-title font-bold">Bình luận (5)</div>
        <div className="c-card-content flex items-start space-x-2">
          <div style={{ width: "10%" }}>
            <div className="w-full h-20">
              <img src="" className="w-full h-full object-cover" alt="" />
            </div>
          </div>

          <div style={{ width: "30%" }} className="space-y-2">
            <p className="text-sub-title icon-main font-bold">
              Trần Thanh Dũng
            </p>
            <button className="bg-main text-white font-bold text-sub-title px-2 py-1 rounded">
              Theo Dõi
            </button>
            <p className="flex justify-between">
              <span className=" text-content space-x-1 flex items-center">
                <i class="fa fa-users font-bold text-sub-title"></i>
                <span>35</span>
              </span>
              <span className=" text-content space-x-1 flex items-center">
                <i class="fa fa-heart font-bold text-sub-title"></i>
                <span>30</span>
              </span>
              <span className=" text-content space-x-1 flex items-center">
                <i class="fa fa-newspaper font-bold text-sub-title"></i>
                <span>25</span>
              </span>
            </p>
            <p className="text-content icon-main">
              Đã trả lời khoảng 3 giờ trước
            </p>
          </div>
          <div
            style={{ width: "60%" }}
            className="flex h-full items-center mt-4"
          >
            <div className="h-0.5 bg-black w-full"></div>
          </div>
        </div>
        <div className="c-card-content text-content border-b border-black">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
          corrupti, exercitationem vel, ea dolore voluptas molestiae placeat
          voluptatem blanditiis dignissimos doloremque nihil tempora facere,
          quae explicabo! Illum ipsum quasi dolores iure. Placeat sequi nihil
          illo facere ea, dolores beatae natus voluptatibus minus iusto, veniam
          vitae velit, voluptas dolore ipsum earum ratione ducimus culpa
          excepturi fugit inventore. Ducimus assumenda minima ullam dignissimos,
          nesciunt ab mollitia eaque iste dolores accusantium distinctio
          consequuntur rem sunt nisi unde laborum! Mollitia, a voluptatibus
          culpa, eveniet voluptatum quae excepturi dicta facere eaque, iusto
          sit. Mollitia assumenda temporibus fugiat tempore odio at explicabo
          tenetur, earum voluptas labore!
        </div>
        <div className="pt-5">
          <button className="c-card-content bg-sp text-white rounded px-2 py-1">
            Xem Thêm Bình Luận
          </button>
        </div>
      </div>
    </div>
  );
}
