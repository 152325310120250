import React, { useEffect, useState } from "react";
import servCodingCamp from "../../../../services/codingCam.service";
import { useParams } from "react-router-dom";
import { ItemReplied } from "./ItemReplied";
export let UserReplied = ({ data }) => {
  let { id } = useParams();
  let { binhLuanId } = data;
  const [listBinhLuan, setlistBinhLuan] = useState([]);
  const [isShow, setIsShow] = useState(false);

  let handleViewMore = () => {
    servCodingCamp
      .getBinhLuanVideo(id, binhLuanId)
      .then((res) => {
        console.log("con", res);
        setlistBinhLuan(res.data.content);
        setIsShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    isShow && handleViewMore();
  }, [data.binhLuanCon]);
  return (
    <div className="space-y-3">
      <ItemReplied data={data} key={data.binhLuanId} />

      <div className="pl-20 space-y-5 transform ">
        {!isShow && data.binhLuanCon > 0 && (
          <p
            className="cursor-pointer"
            onClick={() => {
              // setIsShow(true);
              handleViewMore();
            }}
          >
            Xem {data.binhLuanCon} bình luận{" "}
            <i class="fa fa-angle-down pt-1" aria-hidden="true"></i>{" "}
          </p>
        )}
        {isShow && (
          <>
            {listBinhLuan.map((item, index) => {
              return (
                <ItemReplied data={item} size={0.7} key={item.binhLuanId} />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
