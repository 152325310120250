import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
import { useDispatch } from "react-redux";
import { setUserInfor } from "../../redux/reducer/authReducer";
import localStorageServ from "../../services/locaStorage.service";
import { removeDemoUser } from "../../utils/HocDemoUtils";
import { Modal } from "antd";
import codingfServ from "../../services/codingCam.service";
import axios from "axios";
import moment from "moment";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";

const publicIp = require("public-ip");

export default function BtnLoginFacebook() {
  const getCurrentDay = () => {
    return moment(new Date()).format("DD/MM/YYYY");
  };
  const versionLocalStorage = localStorage.getItem("version");
  if (!versionLocalStorage) {
    localStorage.setItem("version", "1");
    window.location.reload();
  }

  const [message, setMessge] = useState("");
  const [thongTinUser, setThongTinUser] = useState({
    facebookId: "",
    email: "",
    avatar: "",
    diachiIp: "",
  });
  const [isLogin, setIsLogin] = useState(false);

  const dispatch = useDispatch();
  const [appId, setAppId] = useState(false);
  console.log("😀 - BtnLoginFacebook - appId", appId);

  useEffect(() => {
    axios
      .get(
        BASE_URL_CODINGCAMP + "/api/cauhinh/get-faceid?fbclid=IwAR3ivxDEK6Bz2bQ_-VRQIprjbglf-u8uMlG-Jb6rDzKv0VO2ENtiqEojJfE"
      )
      .then((result) => {
        setAppId(result.data.content);
      })
      .catch((err) => { });
  }, []);
  const responseFacebook = (res) => {
    let login = async () => {
      let ipUser = await publicIp.v4();
      let userInfo = {
        facebookId: res.id,
        // facebookId: "2756514864396809",

        email: "",
        avatar: "",
        diachiIp: "",
        // email: res.email,
        // avatar: `https://graph.facebook.com/${res.id}/picture?type=large`,
        // diachiIp: ipUser,
      };
      codingfServ
        .login(userInfo)
        .then((res) => {
          if (res.data.content) {
            switch (res.data.content) {
              case 1:
                setThongTinUser(userInfo);
                setIsLogin(true);
                break;
              case 0:
                setMessge(res.data.message);
                break;
              default:
                localStorageServ.userInfo.set(res.data.content);
                localStorageServ.timeLogin.set(getCurrentDay());
                removeDemoUser();
                dispatch(setUserInfor(res.data.content));
                window.location.href = "/";
                break;
            }
          } else {
            setMessge(res.data.message);
          }
        })
        .catch((er) => { });
    };
    login();
  };

  // Đăng nhập sử dụng tài khoản facebook
  const onDangNhap = () => {
    codingfServ
      .login(thongTinUser)
      .then((res) => {
        if (res.data.content) {
          switch (res.data.content) {
            case 0:
              setMessge(res.data.message);
              break;
            default:
              localStorageServ.userInfo.set(res.data.content);
              localStorageServ.timeLogin.set(getCurrentDay());
              removeDemoUser();
              dispatch(setUserInfor(res.data.content));
              window.location.href = "/";
              break;
          }
        } else {
          setMessge(res.data.message);
        }
      })
      .catch((er) => { });
  };

  return (
    <div className="w-max h-max-content rounded overflow-hidden space-y-5">
      <p className="text-content text-base mb-5">Vui lòng đăng nhập bằng Facebook để học tập</p>
      {appId && (
        <FacebookLogin
          appId={appId}
          autoLoad={false}
          textButton="Đăng nhập với Facebook"
          fields="name,email,picture"
          callback={responseFacebook}
          icon={<i className="fab fa-facebook-f text-xl mr-2 wtext-white font-extralight" />}
        ></FacebookLogin>
      )}
      {message ? <p className="w-96 mt-4 text-red-500 transform duration-300">{message}</p> : ""}
      <Modal
        title="Kiểm tra thông tin"
        visible={isLogin}
        width={500}
        closable={false}
        cancelButtonProps={{ hidden: true }}
        onOk={() => onDangNhap()}
      >
        <p>
          Nhập email:{" "}
          <input
            onChange={(e) => setThongTinUser({ ...thongTinUser, email: e.target.value })}
            class="border rounded-lg w-full hover:shadow-xl p-2 px-3 text-black"
          />
          <p className="w-96 mt-4 text-red-500 transform duration-300">{message}</p>
        </p>
      </Modal>
      {/* <LoginBackup /> */}
    </div>
  );
}
