import React from "react";
import { useSelector } from "react-redux";
import { dapAnDung, dapAnSai } from "../../runcode.constant";

export default function ContentTestCase({ dataInput, dataOutput, keyIndex }) {
  const { testCaseRuncode } = useSelector((state) => state.runcodeSlice);
  // console.log(testCaseRuncode);
  return (
    <div className="px-4 pb-5">
      <div class="flex flex-wrap">
        <div className="w-1/2 px-2 mt-4">
          <div class="flex h-full w-full flex-col space-y-2">
            <div class="text-xs font-medium text-label-3 text-dark-label-3">Input:</div>
            <div class="w-full cursor-text rounded-lg border px-3 font-menlo py-2 bg-dark-fill-3 border-transparent">
              <div
                class="w-full resize-none whitespace-pre-wrap break-words font-menlo outline-none placeholder:text-label-4 placeholder:text-dark-label-4 sentry-unmask"
                placeholder="Enter Testcase"
              >
                {dataInput}
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 px-2 mt-4">
          <div class="flex h-full w-full flex-col space-y-2">
            <div class="text-xs font-medium text-label-3 text-dark-label-3">Ouput:</div>
            <div class="w-full cursor-text rounded-lg border px-3  font-menlo py-2 bg-dark-fill-3 border-transparent" spellcheck="false">
              <div class="w-full resize-none whitespace-pre-wrap break-words font-menlo outline-none placeholder:text-label-4 placeholder:text-dark-label-4 sentry-unmask">
                {dataOutput}
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 px-2 mt-4">
          <div class="flex h-full w-full flex-col space-y-2">
            <div
              class={`text-xs font-medium text-label-3 text-dark-label-3 ${
                testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnDung
                  ? "text-green-500"
                  : testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnSai
                  ? "text-red-500"
                  : "text-white"
              }`}
            >
              Actual Output:
            </div>
            <div
              class={`w-full cursor-text rounded-lg border px-3  font-menlo py-2 bg-dark-fill-3 ${
                testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnDung
                  ? "border-green-500"
                  : testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnSai
                  ? "border-red-500"
                  : "border-transparent"
              } `}
              spellcheck="false"
            >
              <div
                class={`w-full resize-none whitespace-pre-wrap break-words font-menlo outline-none placeholder:text-label-4 placeholder:text-dark-label-4 sentry-unmask ${
                  testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnDung
                    ? "text-green-500"
                    : testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnSai
                    ? "text-red-500"
                    : "text-white"
                }`}
              >
                {testCaseRuncode.length > 0 ? testCaseRuncode[keyIndex].actualOutput : " "}
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 px-2 mt-4">
          <div class="flex h-full w-full flex-col space-y-2">
            <div
              class={`text-xs font-medium text-label-3 text-dark-label-3 ${
                testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnDung
                  ? "text-green-500"
                  : testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnSai
                  ? "text-red-500"
                  : "text-white"
              }`}
            >
              Message:
            </div>
            <div
              class={`w-full cursor-text rounded-lg border px-3  font-menlo py-2 bg-dark-fill-3 ${
                testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnDung
                  ? "border-green-500"
                  : testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnSai
                  ? "border-red-500"
                  : "border-transparent"
              }`}
              spellcheck="false"
            >
              <div
                class={`w-full resize-none whitespace-pre-wrap break-words font-menlo outline-none placeholder:text-label-4 placeholder:text-dark-label-4 sentry-unmask ${
                  testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnDung
                    ? "text-green-500"
                    : testCaseRuncode.length > 0 && testCaseRuncode[keyIndex].message == dapAnSai
                    ? "text-red-500"
                    : "text-white"
                }`}
              >
                {testCaseRuncode.length > 0 ? testCaseRuncode[keyIndex].message : " "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
