import moment from "moment";
import { Trans } from "react-i18next";

export const FORTMAT_DAY = "DD/MM/YYYY";
let formatTime = "DD/MM/YYYY";


let letters = [<Trans i18nKey='trangChu.thu2' />,
<Trans i18nKey='trangChu.thu3' />,
<Trans i18nKey='trangChu.thu4' />,
<Trans i18nKey='trangChu.thu5' />,
<Trans i18nKey='trangChu.thu6' />,
<Trans i18nKey='trangChu.thu7' />,
<Trans i18nKey='trangChu.cn' />
];
export let getCurrentWeekDay = (listDiemDanh) => {
  // Get the current date
  const currentDate = moment();

  // Get the start of the week (Sunday)
  const startOfWeek = currentDate.clone().startOf("week");

  // Create an array to store the days of the week
  const weekDays = [];

  // Loop from Sunday to Saturday and add each day to the array
  for (let i = 0; i < 7; i++) {
    const day = startOfWeek.clone().add(i, "days").format(formatTime);
    let isActive = listDiemDanh.includes(day);
    // let isToday = currentDate.isSame(day, "day");
    let isToday = currentDate.format("DD/MM/YYYY") == day;

    weekDays.push({
      letter: letters[i],
      day: day,
      isActive: isActive,
      isToday: isToday, //
    });
  }

  return weekDays;
};

export const getImgDanhHieu = (name) => {
  return `https://api.techx.edu.vn/images/${name}`;
};
