import { createSlice } from "@reduxjs/toolkit";

import jsonB from "json-bigint";
import { groupChatService } from "../../services/groupChatService";

const initialState = {
  groupChats: [],
  groupChatsByChuyenDe: [],
  chats: [],
  lastGroupChat: {
    id: 1,
    danhSachChat: "",
    noiDungChat: "",
    ngayTao: "",
    groupForTaskId: 0,
    chuyenDeId: 0,
    issueId: 0
  },
  lastChat: [],
  userId: 0,
  joinRoom: { room: "", taskid: 0 },
  joinRoom2: { room: "", chuyenDeId: 0 },
};

const groupChatReducer = createSlice({
  name: "groupChat",
  initialState,
  reducers: {
    getGroupChatByDsChat: (state, action) => {
      state.groupChats = action.payload;
      console.log(state.groupChats && state.groupChats.length !== 0, "task");
      if (state.groupChats && state.groupChats.length !== 0) {
        state.chats = action.payload.map(gc =>
          jsonB.parse(gc.noiDungChat)
        ).flat();
        state.lastGroupChat = state.groupChats[state.groupChats.length - 1];
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)];
        state.lastChat = lastChat;
      }
    },
    getGroupChatByDsChatByChuyenDeId: (state, action) => {
      state.groupChatsByChuyenDe = action.payload;
      // console.log(state.groupChatsByChuyenDe && state.groupChatsByChuyenDe.length !== 0, 'chuyende');
      if (state.groupChatsByChuyenDe && state.groupChatsByChuyenDe.length !== 0) {
        state.chats = action.payload.map(gc =>
          jsonB.parse(gc.noiDungChat)
        ).flat();
        state.lastGroupChat = state.groupChatsByChuyenDe[state.groupChatsByChuyenDe.length - 1];
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)];
        state.lastChat = lastChat;
      }
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    addGroupChat: (state, action) => {
      console.log(action.payload);
    },
    editGroupChat: (state, action) => {
      console.log(action.payload);
    },
    addUserId: (state, action) => {
      state.userId = action.payload;
      localStorage.setItem("userId", jsonB.stringify(action.payload));
    },
    setJoinRoom: (state, action) => {
      state.joinRoom = { ...action.payload };
      localStorage.setItem("currentRoom", JSON.stringify(action.payload));
    },
    setJoinRoom2: (state, action) => {
      state.joinRoom2 = { ...action.payload };
      localStorage.setItem("currentRoom", JSON.stringify(action.payload));
    },
  },
});

export const {
  getGroupChatByDsChat,
  getGroupChatByDsChatByChuyenDeId,
  addGroupChat,
  editGroupChat,
  addUserId,
  setJoinRoom,
  setJoinRoom2,
  setChats
} = groupChatReducer.actions;

export default groupChatReducer.reducer;

//!--------- action async ------------
export const getGroupChatByDsChatApi = (room, taskid) => {
  return async dispatch => {
    try {
      const result = await groupChatService.getGroupChatByDSChat(room, taskid);
      const action = getGroupChatByDsChat(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGroupChatByDsChatByChuyenDeApi = (room, chuyenDeId) => {
  return async dispatch => {
    try {
      const result = await groupChatService.getGroupChatByChuyenDeId(room, chuyenDeId);
      const action = getGroupChatByDsChatByChuyenDeId(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
};

export const postGroupChatApi = groupChat => {
  return async dispatch => {
    try {
      const res = await groupChatService.addGroupChat(groupChat);
      const action = addGroupChat(res.status);
      dispatch(action);
    } catch (err) {
      console.error("postGroupChatApi", err);
    }
  };
};

export const putGroupChatApi = (groupChatId, groupChat) => {
  return async dispatch => {
    try {
      const res = await groupChatService.editGroupChat(groupChatId, groupChat);
      const action = editGroupChat(res.status);
      dispatch(action);
    } catch (err) {
      console.error("postGroupChatApi", err);
    }
  };
};
