import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignUpPage from "./pages/SignUp/SignUpPage";
import TaskPage from "./pages/TaskPage/TaskPage";
import DetailScreenCapstone from "./pages/DetailScreenCapstone/DetailScreenCapstone";
import DetailScreenQuizz from "./pages/DetailScreenQuizz/DetailScreenQuizz";
import HomeTemplate from "./template/HomeTemplate";
import DaskBroad from "./pages/DashBroad/Dashboard";
import Discussion from "./pages/Discussion/Discussion";
import Runcode from "./pages/Runcode/Runcode";
import Upgrade_Notify_Page from "./pages/Upgrade_Notify_Page";
import DetailExercise from "./pages/DetailExercise/DetailExercise";
import LearnVideoPage from "./pages/LearnVideoPage/LearnVideoPage";
import PracticePage from "./pages/PracticePage/PracticePage";
import DocumentPage from "./pages/DocumentPage/DocumentPage";
import BestPracticePage from "./pages/BestPracticePage/BestPracticePage";
import CompetitionsPage from "./pages/CompetitionsPage/CompetitionsPage";
import LiveEventPage from "./pages/LiveEventPage/LiveEventPage";
import DetailDocumentPage from "./pages/DetailDocumentPage/DetailDocumentPage";
import DetailCompetitionsPage from "./pages/DetailCompetitionsPage/DetailCompetitionsPage";
import SpinnerComponent from "./components/Spinner/index";
import BestPracticeDetail from "./pages/BestPraticeDetail/BestPracticeDetail";
import ShopPage from "./pages/ShopPage/ShopPage";
import TestQuizPage from "./pages/TestQuizPage/TestQuizPage";
import { parentPage } from "./routes/utitlity";
import TestQuizPage_ShowDapAn from "./pages/TestQuizPage_ShowDapAn/TestQuizPage_ShowDapAn";
import TuongNhaPage from "./pages/TuongNhaPage/TuongNhaPage";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import HomeSidebar from "./template/HomeSidebar";

import { Toaster } from "react-hot-toast";
import TaskPageTest from "./pages/TaskPage/TaskPageTest";
import { useEffect, useState } from "react";
import DanhSachChat from "./components/DanhSachChat/DanhSachChat";
import LoginPageEmail from "./pages/LoginPageEmail/LoginPageEmail";
import DetailWall from "./pages/TuongNhaPage/DetailWall/DetailWall";
import OnlineTimeTracker from "./components/TimeTracker/TimeTracker";
import { BlogPage } from "./pages/BlogPage/BlogPage";
import WorkShop from "./pages/WorkShop/WorkShop";
import "antd/dist/antd.css";
import "./index.css";
import { withAuth } from "./hook/auth";

export const history = createBrowserHistory();

function App() {
  const theme = useSelector((state) => state.theme.theme);
  const [open, setOpen] = useState(false);

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <div className="backgoundTemplate bg-cover  ">
      <OnlineTimeTracker />
      <SpinnerComponent />

      <Toaster position="top-right" />
      <DanhSachChat open={open} closeDrawer={closeDrawer} />

      <Upgrade_Notify_Page />

      <HistoryRouter history={history}>
        <Routes>
          <Route path="" element={<HomeTemplate />}>
            <Route path="/" element={<Navigate to="/dash-broad" replace />} />
            <Route path="/dash-broad" element={withAuth(DaskBroad)} />
            <Route path="/discussion" element={withAuth(Discussion)} />
            <Route path="/run-code" element={withAuth(Runcode)} />
            <Route path="/learn-video" element={withAuth(LearnVideoPage)} />
            <Route path="/shop-page" element={withAuth(ShopPage)} />

            {/* new page */}
          </Route>
          <Route path="/" element={<HomeSidebar />}>
            <Route path="/blog/:page/:chuyenDeId" element={<BlogPage />} />
            <Route path="/workshop/:chuyenDeId" element={<WorkShop />} />
            <Route path="/task-page-test/:chuyenDeId" element={withAuth(TaskPageTest)} />
            <Route path="/task-page/:chuyenDeId" element={withAuth(TaskPage)} />
            <Route
              path={`/${parentPage.task}/learn-video/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(LearnVideoPage)}
            />
            <Route
              path={`/${parentPage.task}/detai-screen-quiz/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(DetailScreenQuizz)}
            />
            <Route path={`/all-practice`} element={withAuth(PracticePage)} />
            <Route
              path={`/${parentPage.task}/detail-screen-capstone/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(DetailScreenCapstone)}
            />
            <Route path={`/${parentPage.task}/all-document`} element={withAuth(DocumentPage)} />
            <Route
              path={`/${parentPage.task}/detail-page-document/:id`}
              element={withAuth(DetailDocumentPage)}
            />
            <Route
              path={`${parentPage.task}/detail-screen-bai-tap/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(DetailExercise)}
            />
            <Route
              path={`${parentPage.task}/run-code/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(Runcode)}
            />
            {/* pratice page */}
            <Route path={`${parentPage.pratice}/:chuyenDeId`} element={withAuth(PracticePage)} />
            <Route path={`${parentPage.pratice}/task-page`} element={withAuth(TaskPage)} />
            <Route path={`${parentPage.pratice}/learn-video`} element={withAuth(LearnVideoPage)} />
            <Route
              path={`${parentPage.pratice}/detai-screen-quiz/:chuyenDeId`}
              element={withAuth(DetailScreenQuizz)}
            />
            <Route
              path={`${parentPage.pratice}/detail-screen-capstone/:chuyenDeId`}
              element={withAuth(DetailScreenCapstone)}
            />
            <Route path={`${parentPage.pratice}/all-document`} element={withAuth(DocumentPage)} />
            <Route
              path={`${parentPage.pratice}/detail-page-document/:id`}
              element={withAuth(DetailDocumentPage)}
            />
            <Route
              path={`${parentPage.pratice}/detail-screen-bai-tap/:chuyenDeId`}
              element={withAuth(DetailExercise)}
            />
            <Route
              path={`${parentPage.pratice}/run-code/:chuyenDeId`}
              element={withAuth(Runcode)}
            />
            {/* best practices */}
            <Route path={"/detail-best-pratice/:id"} element={withAuth(BestPracticeDetail)} />
            <Route path={`/all-best-practice/:chuyenDeId`} element={withAuth(BestPracticePage)} />
            <Route path={"/detail-screen-competitions"} element={withAuth(CompetitionsPage)} />
            <Route path="/all-competitions" element={withAuth(DetailCompetitionsPage)} />
            <Route path="/detail-screen-live-event" element={withAuth(LiveEventPage)} />
          </Route>

          <Route path="/quiz-test" element={<TestQuizPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login-email" element={<LoginPageEmail />} />
          <Route path="/quiz_dap_an" element={<TestQuizPage_ShowDapAn />} />
          {/* tường nhà */}
          <Route path="/profile/:userId" element={<TuongNhaPage />} />
          <Route path="/wall/:id/:userId" element={<DetailWall />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </HistoryRouter>
    </div>
  );
}

export default App;
