import React from "react";
import { Tabs } from "antd";
import ContentProblemText from "../ProblemAndSolution/Content/ContentProblemText";
import { useSelector } from "react-redux";
import ContentTestCase from "./Content/ContentTestCase";
import { dapAnDung, dapAnSai } from "../runcode.constant";
const { TabPane } = Tabs;
export default function Testcase({ className, question }) {
  let { testCaseRuncode } = useSelector((state) => state.runcodeSlice);
  const renderIconTestCase = (index) => {
    if (testCaseRuncode[index]?.message === dapAnSai) {
      return <i className="fa-solid fa-xmark text-red-500"></i>;
    } else if (testCaseRuncode[index]?.message === dapAnDung) {
      return <i className="fa-solid fa-check text-green-500"></i>;
    }
  };

  return (
    <div>
      <Tabs
        // defaultActiveKey="1"
        className="bg-theme-runcode-problem h-3/4 text-white rounded"
        tabBarGutter={5}
      >
        {question?.test_case?.map((item, index) => {
          return (
            <TabPane
              tab={
                <span className="text-white flex items-center justify-center">
                  Test case {item.testcase_id + 1}
                  {testCaseRuncode.length > 0 && <span className="ml-2 text-lg">{renderIconTestCase(index)}</span>}
                </span>
              }
              key={index}
            >
              <ContentTestCase dataInput={item._input} dataOutput={item._output} keyIndex={index} />
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}
