import React, { useEffect } from "react";
import AvatarUserCicle from "../../../components/AvatarUserCicle/AvatarUserCicle";
import { useSelector } from "react-redux";
import ModelContentIcon from "../../../components/ModalContentIcon/ModalContentIcon";
import { iconDashBoard } from "../../../components/ModalContentIcon/utilities";
import { selectThongTinMoRong } from "../../../redux/reducer/authReducer";
import Badge from "../../../components/Badge/Badge";
import ChuoiDiemDanh from "./ChuoiDiemDanh";
import { getHinhAnhFB } from "../../../utils/GetHinhanh";
import servCodingCamp from "../../../services/codingCam.service";
import ModalDanhHieu from "./ModalDanhHieu";
export default function OverviewInfoUser() {
  const { userInfo } = useSelector(selectThongTinMoRong);
  useEffect(() => {
    servCodingCamp
      .layMocHuyHieu()
      .then((result) => {})
      .catch((err) => {});
  }, []);
  return (
    <div className="card-theme w-full h-full relative ">
      <Badge
        title={<span classnName="text-xs">{userInfo.danhHieu?.tenDanhHieuEN}</span>}
        right={13}
        size={50}
      />
      <div className="c-card-content">
        <div className="flex  flex-col">
          <div className="flex p-5 pb-8">
            <div className="  flex items-center justify-center relative">
              <AvatarUserCicle width={80} height={80} avatar={getHinhAnhFB(userInfo.facebookId)} />
              <div className="pl-10">
                <p className="text-title font-semibold capitalize">{userInfo.hoTen}</p>
                <div className="flex space-x-2 items-center">
                  <ModalDanhHieu danhHieu={userInfo.danhHieu} />

                  <p className="text-yellow name capitalize rank">
                    {userInfo.danhHieu?.tenDanhHieuEN}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow   ">
            <div className="space-y-2 px-2">
              <ModelContentIcon loai={iconDashBoard.NangDong} />
              <ModelContentIcon loai={iconDashBoard.Xp} />
            </div>
          </div>
        </div>
      </div>
      <ChuoiDiemDanh />
    </div>
  );
}
