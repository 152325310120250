import React from "react";
import BtnFollow from "../BtnFollow/BtnFollow";
import { getHinhAnhFB } from "../../../utils/GetHinhanh";
import { useNavigate } from "react-router-dom";
import "./style.scss";
export default function Item({ user }) {
  let navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/profile/${user.id}`);
      }}
      className="flex items-center  space-x-3 text-content  py-1 cursor-pointer item_follow px-5 rounded"
    >
      <img
        src={getHinhAnhFB(user?.facebookId)}
        alt=""
        className="w-16 h-16 object-cover rounded-full"
      />
      <div className=" space-y-1">
        <p className="name capitalize text-2xl text-title ">{user?.hoTen}</p>
        <p className="name capitalize rank">{user?.danhHieu?.tenDanhHieu}</p>
        <p>
          <span className="font-medium">{user?.email}</span>
        </p>
      </div>
      {/* <BtnFollow /> */}
    </div>
  );
}
