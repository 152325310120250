import React, { useEffect } from "react";
import localStorageServ from "../../services/locaStorage.service";
import servCodingCamp from "../../services/codingCam.service";
import { Tabs } from "antd";
import TabTuongNha from "./TabTuongNha";
import "./index.scss";
import Avatar from "./AvatarWall/AvatarWall";
import GuestHeader from "./GuestHeader";
import Header from "./Header";
export default function TuongNhaPage() {
  let isLogin = localStorageServ.userInfo.get();
  return (
    <div id="profilePage" className="c-card-content min-h-screen  px-20 pt-24">
      {isLogin ? <Header /> : <GuestHeader />}
      <Avatar />
      <TabTuongNha />
    </div>
  );
}
