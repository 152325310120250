import moment from "moment";
import React from "react";
import BtnFollow from "../BtnFollow/BtnFollow";
import { selectVideoWall } from "../tuongNhaSlice";
import { useSelector } from "react-redux";

export default function Desc() {
  //
  let detail = useSelector(selectVideoWall);

  const currentDate = moment();
  const targetDate = moment(detail.ngayUp);
  const daysDifference = currentDate.diff(targetDate, "days");
  let user = detail?.thongTinUser;
  return (
    <div className="space-y-5 p-5 card-theme">
      <div className=" w-full flex items-start space-x-5">
        <img src={user.avatar} alt="" className="w-24 h-24 object-cover rounded-full" />
        <div className=" h-full flex flex-col justify-center flex-grow">
          {" "}
          <p className="name capitalize text-2xl text-title">{user.hoTen}</p>
          <div className="space-x-2 text-content">
            <span>Email</span>
            <span className="font-medium">{user.email}</span>
          </div>
          <p className="text-base text-gray-400">
            {daysDifference == 0 ? "Hôm nay" : daysDifference + " ngày trước"}
          </p>
        </div>
        <BtnFollow />
      </div>

      <p className="text-content">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. At voluptatum ea quis doloribus
        distinctio, blanditiis excepturi perspiciatis aut officiis voluptatibus pariatur consectetur
        ullam dolorem ipsam quasi minima asperiores eius odit?
      </p>
    </div>
  );
}
