import React from "react";
import CardBestPractice from "./CardBestPractice";

export default function ListCardBestPracTice({ data, isSave = false }) {
  const renderCardPractice = () => {
    if (data.length == 0) {
      return (
        <p className="c-card-content text-title text-center w-full">
          Không có bài viết trong mục đã lưu
        </p>
      );
    }
    return data.map((d) => {
      return <CardBestPractice isSave={isSave} data={d} />;
    });
  };
  return <div className="flex flex-wrap">{renderCardPractice()}</div>;
}
