import React, { useEffect, useState } from "react";
import servCodingCamp from "../../../services/codingCam.service";
import { Item } from "./Item";
import "./style.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setListImageTag } from "../../../redux/reducer/dashboardSlice";
import { useTranslation } from "react-i18next";
export default function ListModule() {
  const [listLoTrinh, setListLoTrinh] = useState([]);
  const dispatch = useDispatch();
  // gọi api lấy danh sách chuyển đề (lộ trình) đã đăng ký
  const { t } = useTranslation()

  useEffect(() => {
    servCodingCamp
      .layLotrinhUser()
      .then((res) => {
        setListLoTrinh(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("https://api.techx.edu.vn/api/danh-sach-tag/lay-hinh-tag")
      .then((res) => {
        dispatch(setListImageTag(res.data.content));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className=" c-col-full  relative space-y-4">
      <p className="font-bold text-title text-left"> {t('trangChu.loTrinhDangHoc')}</p>

      <div className="grid list_module  w-full ">
        {listLoTrinh.map((item, index) => {
          return <Item data={item} key={index} />;
        })}
      </div>
    </div>
  );
}
