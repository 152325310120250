import React, { useEffect } from "react";
import ContentChallenge from "./ContentChallenge";
import TableTopUserChallenge from "./TableTopUserChallenge";
import servCodingCamp from "../../services/codingCam.service";

const dataFake = {
  tenChuDe: "10 Challange JS fudamemeta",
  imgChuDe: "./img/card-best-pratice.png",
  diamon: "30,000",
  coin: "30",
};
export default function DetailCompetitionsPage() {
  useEffect(() => {
    servCodingCamp
      .getListCompetition()
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className=" mt-32">
      <div className="flex">
        <div className="c-col-35 ">
          <div className="card-theme c-card-padding h-full">
            <p className="c-card-content border-b border-gray-200 text-title font-extrabold flex items-center space-x-3">
              <i class="fab fa-earlybirds"></i>
              {/* <span>{dataFake.tenChuDe}</span> */}
            </p>
            <div className=" h-72">
              <img
                src={dataFake.imgChuDe}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div className="c-card-content text-title font-bold flex items-center space-x-2 bg-sp">
              <i class="fa fa-gifts"></i>
              <span>Tổng giá trị phần thưởng</span>
            </div>
            <div className="c-card-content flex space-x-7 text-sub-title">
              <span className="flex items-center space-x-1">
                <i class="fa fa-gem"></i>
                <span>{dataFake.diamon}</span>
              </span>
              <span className="flex items-center space-x-1">
                <i class="fab fa-bitcoin"></i>
                <span>{dataFake.coin}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="c-col-65 ">
          <div className="card-theme c-card-padding h-full">
            <p className="c-card-content border-b border-gray-200 text-title font-extrabold flex items-center space-x-3 ">
              <i class="fab fa-earlybirds"></i>
              <span>specific description</span>
            </p>

            <div className="c-card-content text-sub-title ">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatem, ab. Velit sed architecto laboriosam cumque at ipsa
              dolores similique quasi aliquam omnis? Aut at cum quos autem sit.
              Voluptate placeat aspernatur dolor, quia ut eaque recusandae
              minima error exercitationem tenetur iure tempora quisquam
              voluptatum quod consequuntur cupiditate animi vel voluptates,
              corporis perspiciatis vero ea omnis! Odio excepturi labore tempore
              minus eum modi aut possimus? Inventore assumenda nostrum sunt
              iusto eos, exercitationem quas cumque at rerum, necessitatibus
              distinctio perspiciatis. Vel vitae et omnis hic natus soluta
              facere architecto quo totam molestiae, rerum inventore
              reprehenderit, necessitatibus ad dignissimos deleniti ipsam,
              voluptates recusandae.
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-20">
        <ContentChallenge />
        <TableTopUserChallenge />
      </div>
    </div>
  );
}
