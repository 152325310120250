import React, { useState } from "react";
import { Layout } from "antd";
import SideNavigation from "./SideNavigation/SideNavigation";
import { Outlet } from "react-router-dom";
import HeaderTask from "./HeaderTask/HeaderTask";
import { useSelector } from "react-redux";
import useWindowSize from "../hook/useWindowSize";
import BreadCrumbNav from "../components/BreadCrumbNav/BreadCrumbNav";
const { Header, Content, Sider } = Layout;

export default function HomeSidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const theme = useSelector((state) => state.theme.theme);
  let root = document.documentElement;
  root.classList.add(theme);
  const { widthWindow, heightWindow } = useWindowSize();
  const extractLayoutSideBar = (collapsed) => {
    if (widthWindow > 1024 && !collapsed) {
      return 120;
    } else {
      return 40;
    }
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "black",
          zIndex: 100,
        }}
        className="flex h-16  fixed top-0 left-0 w-full  items-center  justify-between space-x-5"
      >
        <HeaderTask />
      </Header>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={extractLayoutSideBar(collapsed)}
          className=" fixed top-20 left-0 "
        >
          <SideNavigation isCollapsed={collapsed} />
        </Sider>
        <Layout
          style={{
            margin: `0px 0 0 ${extractLayoutSideBar(collapsed)}px`,
            paddingTop: "64px",
          }}
          className="bg-content"
        >
          <Content className="  relative">
            <div className="pt-8">
              <BreadCrumbNav />
              <div className="page_container">
                <Outlet />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
