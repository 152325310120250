import { Tag } from "antd";
import React from "react";
import HtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
export default function ContentProblemText({ question }) {
  // console.log(dataRuncode);
  const levelClassName = {
    easy: "bg-green-700 text-green-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
    medium:
      "bg-yellow-700 text-yellow-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
    hard: "bg-red-700 text-red-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
  };
  return (
    <div className="px-4 py-2 h-full overflow-y-scroll">
      <div className="">
        <span class="mr-2 inline-block  text-2xl font-medium text-label-1 dark:text-dark-label-1 ">
          {HtmlParser(question.vi.question_title)}
        </span>
        <div className="my-2 border-b border-white pb-4">
          <Tag className={levelClassName[question._level]}>
            {HtmlParser(question._level)}
          </Tag>
        </div>
        <div className="text-base pt-4 space-y-6">
          <p> Đề bài: {HtmlParser(question.vi.problem)} </p>
          <div>
            <p>Định dạng đầu vào:</p>
            <div
              class="w-full cursor-text py-2 rounded-lg border px-3 bg-dark-fill-3 border-transparent"
              spellcheck="false"
            >
              <p className="mb-0"> {HtmlParser(question.input_format)}</p>
            </div>
          </div>
          <div>
            <p>Định dạng đầu ra:</p>
            <div
              class="w-full cursor-text py-2 rounded-lg border px-3 bg-dark-fill-3 border-transparent"
              spellcheck="false"
            >
              <p className="mb-0">{HtmlParser(question.output_format)}</p>
            </div>
          </div>
          <div>
            <p>Giải thích:</p>
            <div
              class="w-full cursor-text py-2 rounded-lg border px-3 bg-dark-fill-3 border-transparent"
              spellcheck="false"
            >
              <div
                className="whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: question.vi.explanation }}
              ></div>
            </div>
          </div>
          <div>
            <p>Các ràng buộc:</p>
            <div
              class="w-full cursor-text py-2 rounded-lg border px-3 bg-dark-fill-3 border-transparent space-y-2"
              spellcheck="false"
            >
              <p className="mb-0">{question.vi.constraint_input}</p>
              <p className="mb-0">{question.vi.constraint_output}</p>
              <p className="mb-0">{question.vi.constraint_time}</p>
            </div>
          </div>
          <p>Đầu vào mẫu:</p>
          <div
            class="w-full cursor-text rounded-lg border px-3 py-2 bg-dark-fill-3 border-transparent"
            spellcheck="false"
          >
            <p className="mb-0">{question.sample_input}</p>
          </div>
          <p>Đầu ra mẫu:</p>
          <div
            class="w-full cursor-text rounded-lg border px-3 py-2 bg-dark-fill-3 border-transparent"
            spellcheck="false"
          >
            <p className="mb-0">{question.sample_output}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
