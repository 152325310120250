import React from "react";
import { Tabs } from "antd";
import "./problemAndSolution.scss";
import ContentProblemText from "./Content/ContentProblemText";
const { TabPane } = Tabs;

function callback(key) {
  // console.log(key);
}
export default function ProblemAndSolution({ question }) {
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        className="bg-theme-runcode-problem text-white rounded"
        onChange={callback}
        tabBarGutter={5}
      >
        <TabPane tab={<span className="text-white ">Bài toán</span>} key="1">
          <ContentProblemText question={question} />
        </TabPane>
        {/* <TabPane
          tab={<span className="text-white ">Problem (Video)</span>}
          key="2"
        >
          <div className="p-2">Tính năng chưa update</div>
        </TabPane>
        <TabPane
          disabled
          tab={
            <span className="text-white ">
              Hint <i className="text-red-500 fa-solid fa-lock ml-2"></i>
            </span>
          }
          key="3"
        >
          <div className="p-2">Tính năng chưa update</div>
        </TabPane>
        <TabPane
          disabled
          tab={
            <span className="text-white ">
              Solution <i className="text-red-500 fa-solid fa-lock ml-2"></i>
            </span>
          }
          key="4"
        >
          <div className="p-2">Tính năng chưa update</div>
        </TabPane> */}
      </Tabs>
      ,
    </>
  );
}
