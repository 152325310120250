import { Button, message, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createPayloadNopBai } from "../../utils/taskUtils";
import { typeTask } from "../../routes/utitlity";
import servCodingCamp from "../../services/codingCam.service";
import toast from "react-hot-toast";
import localStorageServ from "../../services/locaStorage.service";
import { typeTrangThaiNop } from "../StepProjectInstruction/util";
import { useDispatch } from "react-redux";
import { updateThongTinMoRongAction } from "../../redux/reducer/authReducer";
import { useTranslation } from "react-i18next";
export default function Navigate_Footer_Pratices({
  current,
  total,
  handleClickNextQuestion,
  isDisableBtn,
  listQuestion,
  onSuccess,
}) {
  const { t } = useTranslation()

  let dispatch = useDispatch();
  let totalTime;
  let timmer = setInterval(() => {
    totalTime++;
  }, 1000);
  // const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  let userInfo = localStorageServ.userInfo.get();
  let { chuyenDeId, taskId, monHocId } = useParams();
  const markTaskAsCompleted = () => {
    const taskCompletionData = {
      nguoiDungId: userInfo.id,
      chuyenDeId,
      monHocId,
      taskId,
      maLoai: typeTask.QUIZ,
      baiHocId: 0,
      soPhutThucHien: Math.floor(timmer / 60),
    };
    return servCodingCamp.postHoanThanhHoc(taskCompletionData);
  };
  const submitAssignmentData = (assignmentData) => {
    return servCodingCamp.postNopBaiTap(assignmentData);
  };
  const handle_PostKetQua = async () => {
    let countCorrected = 0;
    for (let index = 0; index < listQuestion.length; index++) {
      const question = listQuestion[index];
      question.isCorrect && countCorrected++;
    }
    let diemQuizz = countCorrected;
    if (diemQuizz == 0) {
      diemQuizz++;
    }
    let baiLam = { soCauDung: countCorrected, noiDungBaiNop: listQuestion };
    let assignmentData = createPayloadNopBai(
      taskId,
      typeTask.QUIZ,
      JSON.stringify(baiLam),
      0,
      chuyenDeId,
      monHocId
    );

    try {
      let result = await submitAssignmentData(assignmentData);
      if (result.data?.content.trangThai == typeTrangThaiNop.DA_CHAM) {
        await markTaskAsCompleted();
      }
      onSuccess();
      toast.success("Bạn đã nộp bài tập thành công");
      clearImmediate(timmer);
      dispatch(updateThongTinMoRongAction());
    } catch (error) {
      console.error(error);
      toast.error("Lưu điểm không thành công");
    }
    // gọi api hoàn thành
  };

  const handleClickNext = () => {
    return !isDisableBtn ? handleClickNextQuestion() : null;
  };
  const percent = Math.floor((current / total) * 100);
  const nextBtnCss = isDisableBtn
    ? " text-gray-600 bg-gray-300 cursor-not-allowed"
    : "  bg-blue-theme text-white ";
  return (
    <div
      style={{
        background:
          "radial-gradient(91.01% 91.01% at 16.52% 42.45%, #ffc94c 0%, #eebe47 23%, #edb720 52%",
      }}
      className=" bg-white flex items-center h-16 w-full justify-center space-x-10 px-16 border-none rounded-2xl"
    >
      <div className="flex items-cente space-x-5 justify-center max-w-screen-md w-full">
        <Progress
          step={total}
          percent={percent}
          // size="small"
          className="w-full"
          showInfo={false}
          strokeWidth={15}
          // rgb(139, 29, 234) 1.36%, rgb(74, 0, 224) 100%)
          strokeColor={{
            // "0%": "#4A00E0",
            "0%": "#301D09",
            "100%": "#FF0C0C",
            // "100%": "#8E2DE2",
          }}
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        <span className="font-bold flex-shrink-0 text-content text-black">
          {current}/{total} câu
        </span>
      </div>

      <Button
        onClick={() => {
          return current === total ? handle_PostKetQua() : handleClickNext();
        }}
        className={
          "  text-white duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg" +
          nextBtnCss
        }
        // loading={loading}
      >
        {current == total ? t('chiTietNhiemVu.hoanThanh') : t('chiTietNhiemVu.cauTiepTheo')}
      </Button>
    </div>
  );
}
