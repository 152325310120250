import React from "react";
import { Tabs } from "antd";

export default function WriteComment() {
  return (
    <div className="flex">
      <div style={{ width: "5%" }}></div>
      <div style={{ width: "95%" }} className="c-card-content">
        <Tabs>
          <Tabs.TabPane
            tab={<span className="text-sub-title icon-main">Viết Bài</span>}
            key="item-1"
          >
            <div className="h-44 border border-black">
              <div className="c-card-content border-b border-black">
                Thêm Tiêu Đề
              </div>
              <div></div>
            </div>
            <div className="text-right mt-3">
              <button className="bg-main text-white rounded px-2 py-1">
                Đăng bài
              </button>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span className="text-sub-title icon-main">Xem Trước</span>}
            key="item-2"
          >
            Content 2
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}
