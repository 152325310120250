import React from "react";
import ChartStatisticTimeLearning from "./ChartStatisticTimeLearning/ChartStatisticTimeLearning";
import OverviewInfoUser from "./OverviewInfoUser/OverviewInfoUser";
import ThanhTichDashBoard from "./ThanhTichDashBoard/ThanhTichDashBoard";
import ListModule from "./ListModule/ListModule";
import BangXeHangThanhTich from "../../components/BangXeHangThanhTich/BangXeHangThanhTich";
import "./index.css";
import { Trans, useTranslation } from "react-i18next";

export default function Dashboard() {
  const { t, i18n } = useTranslation()

  return (
    <div className="flex w-full flex-grow flex-wrap  ">
      <div className="c-col-75  flex flex-col left_dashboard">
        <div id="upper_daskboard" className="flex w-full">
          <div className="c-col-40 ">
            <OverviewInfoUser />
          </div>
          <div className="c-col-60 ">
            <ChartStatisticTimeLearning />
          </div>
        </div>
        <div className="flex  ">
          <ListModule />
        </div>
      </div>

      <div className="c-col-25 pl-0 flex xl:flex-row flex-col  xl:space-y-0 xl:space-x-5 xl:pl-5 space-y-5 right_dashboard  xl:h-full ">
        <div className="flex-shrink-0 xl:w-2/3 w-full ">
          <div className="card-theme c-card-content">
            <BangXeHangThanhTich
              btnFilter={[
                {
                  title: <Trans i18nKey='trangChu.capDo' />,
                  key: 0,
                },
                {
                  title: <Trans i18nKey='trangChu.tichCuc' />,
                  key: 1,
                },
                {
                  title: <Trans i18nKey='trangChu.hocGioi' />,
                  key: 2,
                },
              ]}
              heightList={150}
            />
          </div>
        </div>
        <ThanhTichDashBoard />
      </div>
    </div>
  );
}
