import React, { useCallback, useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import SelectLanguages from "../SelectLanguages/SelectLanguages";
import SelectThemes from "../SelectThemes/SelectThemes";
import Testcase from "../Testcase/Testcase";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import _debounce from "lodash/debounce";
import { checkSubmitDataRuncode } from "../../runcodeSlice";
import { checkAnswers, isJson, languageEditor } from "../../utils";
import { unwrapResult } from "@reduxjs/toolkit";
import servCodingCamp from "../../../../services/codingCam.service";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import localStorageServ from "../../../../services/locaStorage.service";
import classNames from "classnames";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../../../redux/reducer/spinnerReducer";
import { loaiNhiemVu } from "../../../../utils/taskUtils";
import { useTranslation } from "react-i18next";

export default function RuncodeEditor({
  question,
  idCauHoi,
  disabledButton,
  setDisabledButton,
  increaseQuestionIndex,
  isShowNextQuestion,
}) {
  const { t } = useTranslation()

  let { chuyenDeId, monHocId, taskId } = useParams();

  const dispatch = useDispatch();
  const editorRef = useRef(null);
  let userInfo = localStorageServ.userInfo.get();
  const [languages, setLanguages] = useState(question.init_code[0]._language);
  const [themes, setThemes] = useState("vs-dark");
  const [defaultCode, setDefaultCode] = useState("");
  const [code, setCode] = useState("");
  useEffect(() => {
    const renderDefaultCode = () => {
      let defaultCode = question?.init_code.find((item) => item._language === languages);
      const formattedValue = defaultCode._function;

      isJson(formattedValue);
      if (isJson(formattedValue)) {
        setDefaultCode(JSON.parse(formattedValue));
        setCode(JSON.parse(formattedValue));
      }
      setDefaultCode(formattedValue);
      setCode(formattedValue);
      if (idCauHoi.baiLamTracNghiem) {
        setDefaultCode(JSON.parse(idCauHoi.baiLamTracNghiem));
        setCode(JSON.parse(idCauHoi.baiLamTracNghiem));
      }
    };
    renderDefaultCode();
  }, [languages, question, idCauHoi]);

  useEffect(() => {
    if (idCauHoi.diem === 100) {
      setDisabledButton(false);
    }
  }, [idCauHoi.diem]);

  const setLanguage = (language) => {
    setLanguages(language);
  };
  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  //này để submit

  const submitAssignmentData = (assignmentData) => {
    return servCodingCamp.postNopBaiTap(assignmentData);
  };

  const markTaskAsCompleted = () => {
    const taskCompletionData = {
      nguoiDungId: userInfo.id,
      chuyenDeId,
      monHocId,
      taskId,
      maLoai: "RUNCODE",
      baiHocId: idCauHoi,
      soPhutThucHien: 10,
    };
    return servCodingCamp.postHoanThanhHoc(taskCompletionData);
  };
  const onSubmitCorrect = async () => {
    const assignmentData = {
      id: 0,
      taskId: taskId * 1,
      requireId: idCauHoi,
      baiLam: editorRef.current.getValue(),
      maLoai: loaiNhiemVu.RUNCODE,
      chuyenDeId: chuyenDeId * 1,
      monHocId: monHocId * 1,
    };

    try {
      await submitAssignmentData(assignmentData);
      await markTaskAsCompleted();
      toast.success("Bạn đã trả lời chính xác");
    } catch (error) {
      console.error(error);
      toast.error("Lưu điểm không thành công");
    }
  };
  const submitAnswer = async () => {
    // chặn click khi chưa trả lời
    if (code == defaultCode) return;
    const data = {
      code: editorRef.current.getValue(),

      language: languages === "javascript" ? "js" : languages,
      question: idCauHoi,
    };
    // ! gọi api gửi đáp án
    dispatch(set_request_spinner_started());
    dispatch(checkSubmitDataRuncode(data))
      .then(unwrapResult)
      .then((res) => {
        console.log(res);
        checkAnswers(res) ? onSubmitCorrect() : toast.error("Đã có lỗi xảy ra");
        dispatch(set_request_spinner_ended());
      })
      .catch((err) => {
        toast.error("Đã có lỗi xảy ra");
        console.log(err);
        dispatch(set_request_spinner_ended());
      });
  };

  return (
    <div className="">
      <div className="py-1 bg-theme-runcode-problem rounded px-2  flex justify-between items-center">
        <div className="space-x-4">
          <SelectLanguages question={question} setLanguages={setLanguage} />
          <SelectThemes setThemes={setThemes} themes={themes} />
        </div>
        <button
          onClick={() => {
            setCode(defaultCode);
          }}
          className={classNames(
            " px-2 py-1 rounded   focus:outline-none",
            {
              "cursor-not-allowed bg-gray-600 text-gray-500": code == defaultCode,
            },
            {
              "card-theme  text-yellow-theme": code != defaultCode,
            }
          )}
        >
          Reset Code
        </button>
      </div>
      <div className="space-y-1">
        <Editor
          height="50vh"
          language={languageEditor[languages]}
          theme={themes}
          defaultValue={defaultCode}
          value={code}
          onChange={(value) => {
            setCode(value);
          }}
          className="rounded bg-theme-runcode-problem shadow"
          onMount={handleEditorDidMount}
          options={{
            fontSize: 14,
            tabSize: 4,
            minimap: {
              enabled: false,
            },
          }}
        />
        <div className="py-2 bg-theme-runcode-problem rounded px-2  flex justify-between items-center">
          <button
            onClick={submitAnswer}
            className={classNames(
              " px-2 py-1 rounded   focus:outline-none",
              {
                "cursor-not-allowed bg-gray-600 text-gray-500": code == defaultCode,
              },
              {
                "text-white  focus:outline-none btn_theme_red p-0 px-2 h-8 leading-5":
                  code != defaultCode,
              }
            )}
          >

            {t('chiTietNhiemVu.nopBai')}

          </button>
          {isShowNextQuestion && false && (
            <div>
              {disabledButton ? (
                <Tooltip placement="topLeft" title={t('chiTietNhiemVu.hoanThanhDeMoKhoa')}>
                  <button
                    onClick={increaseQuestionIndex}
                    className={`text-white px-4 py-1 rounded border border-white focus:outline-none ${disabledButton ? "cursor-not-allowed" : ""
                      }`}
                  >

                    {t('chiTietNhiemVu.cauTiepTheo')}

                  </button>
                </Tooltip>
              ) : (
                <button
                  disabled={disabledButton}
                  onClick={increaseQuestionIndex}
                  className={`text-white px-4 py-1 rounded border border-white focus:outline-none ${disabledButton ? "cursor-not-allowed" : ""
                    }`}
                >
                  {t('chiTietNhiemVu.cauTiepTheo')}

                </button>
              )}
            </div>
          )}
        </div>
        {question && <Testcase question={question} />}
      </div>
    </div>
  );
}
