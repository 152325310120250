import classNames from "classnames";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import HeatMapTimeOnline from "../../../../components/TimeTracker/Heatmap";
import "./style.scss";
export default function ModalHeatMap({ day, index, currentWeek }) {
  const [modalVisible, setModalVisible] = useState(false);

  let renderItemDay = () => {
    let activeColor = "rgb(255, 201, 76)";

    return (
      <div
        onClick={() => {
          setModalVisible(true);
        }}
        style={{ width: "13%" }}
        className="relative h-10 cursor-pointer"
      >
        <span
          style={{
            background: activeColor,
            border: day.isActive ? "1px solid #FFC94C" : "none",
          }}
          className={classNames(
            "h-8 w-full relative top-0 left-0   text-content  font-bold flex items-center justify-center text-green-theme  ",
            {
              "rounded-tl-xl rounded-bl-xl": index == 0,
            },
            {
              "rounded-tr-xl rounded-br-xl": index == currentWeek.length - 1,
            }
          )}
        >
          {day.letter}
        </span>
      </div>
    );
  };

  return (
    <>
      <Modal
        show={modalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
        size="lg"
        className="modal-heat-map"
      >
        <Modal.Body className="flex justify-center  modal-body-gradient rounded text-white">
          <HeatMapTimeOnline />
        </Modal.Body>
      </Modal>
      {renderItemDay()}
    </>
  );
}
