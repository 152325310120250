import React from "react";
import AvatarUserCicle from "../../components/AvatarUserCicle/AvatarUserCicle";

export default function TableTopUserChallenge() {
  return (
    <div className="c-col-38">
      <div className="h-full bg-white relative">
        <div className="absolute top-0 left-0 transform -translate-y-full c-card-content text-title font-medium inline-block border-b-0 border-2 py-2  bg-sp w-full text-center">
          Top học viên 10 Challange JS fudamemeta
        </div>
        <div className="card-theme rounded-none">
          <table className="w-full text-left c-card-content">
            <tr className="text-sub-title font-bold  border-yellow-500 border-b-2">
              <th className=" py-7 "></th>
              <th>Họ Tên</th>
              <th>Tiến Độ</th>
              <th>Thời Gian</th>
              <th>Điểm</th>
            </tr>
            <tr className="font-semibold text-content">
              <td className=" py-5 ">
                <div className="flex flex-col items-center justify-center">
                  <AvatarUserCicle
                    width={"30px"}
                    height={"30px"}
                    avatar={"./img/ngo_diec_pham.png"}
                  />
                  <span>358</span>
                </div>
              </td>
              <td>
                <p>Trương Tấn Khải</p>
                <p className="icon-main">[PRICIPAL ENGINER]</p>
              </td>
              <td>10%</td>
              <td>30Days</td>
              <td>9.8</td>
            </tr>
            <tr className="font-semibold text-content">
              <td className=" py-5 ">
                <div className="flex flex-col items-center justify-center">
                  <AvatarUserCicle
                    width={"30px"}
                    height={"30px"}
                    avatar={"./img/ngo_diec_pham.png"}
                  />
                  <span>358</span>
                </div>
              </td>
              <td>
                <p>Trương Tấn Khải</p>
                <p className="icon-main">[PRICIPAL ENGINER]</p>
              </td>
              <td>10%</td>
              <td>30Days</td>
              <td>9.8</td>
            </tr>
            <tr className="font-semibold text-content">
              <td className=" py-5 ">
                <div className="flex flex-col items-center justify-center">
                  <AvatarUserCicle
                    width={"30px"}
                    height={"30px"}
                    avatar={"./img/ngo_diec_pham.png"}
                  />
                  <span>358</span>
                </div>
              </td>
              <td>
                <p>Trương Tấn Khải</p>
                <p className="icon-main">[PRICIPAL ENGINER]</p>
              </td>
              <td>10%</td>
              <td>30Days</td>
              <td>9.8</td>
            </tr>
            <tr className="font-semibold text-content">
              <td className=" py-5 ">
                <div className="flex flex-col items-center justify-center">
                  <AvatarUserCicle
                    width={"30px"}
                    height={"30px"}
                    avatar={"./img/ngo_diec_pham.png"}
                  />
                  <span>358</span>
                </div>
              </td>
              <td>
                <p>Trương Tấn Khải</p>
                <p className="icon-main">[PRICIPAL ENGINER]</p>
              </td>
              <td>10%</td>
              <td>30Days</td>
              <td>9.8</td>
            </tr>
            <tr className="font-semibold text-content">
              <td className=" py-5 ">
                <div className="flex flex-col items-center justify-center">
                  <AvatarUserCicle
                    width={"30px"}
                    height={"30px"}
                    avatar={"./img/ngo_diec_pham.png"}
                  />
                  <span>358</span>
                </div>
              </td>
              <td>
                <p>Trương Tấn Khải</p>
                <p className="icon-main">[PRICIPAL ENGINER]</p>
              </td>
              <td>10%</td>
              <td>30Days</td>
              <td>9.8</td>
            </tr>
            <tr className="font-semibold text-content">
              <td className=" py-5 ">
                <div className="flex flex-col items-center justify-center">
                  <AvatarUserCicle
                    width={"30px"}
                    height={"30px"}
                    avatar={"./img/ngo_diec_pham.png"}
                  />
                  <span>358</span>
                </div>
              </td>
              <td>
                <p>Trương Tấn Khải</p>
                <p className="icon-main">[PRICIPAL ENGINER]</p>
              </td>
              <td>10%</td>
              <td>30Days</td>
              <td>9.8</td>
            </tr>
            <tr className="font-semibold text-content">
              <td className=" py-5 ">
                <div className="flex flex-col items-center justify-center">
                  <AvatarUserCicle
                    width={"30px"}
                    height={"30px"}
                    avatar={"./img/ngo_diec_pham.png"}
                  />
                  <span>358</span>
                </div>
              </td>
              <td>
                <p>Trương Tấn Khải</p>
                <p className="icon-main">[PRICIPAL ENGINER]</p>
              </td>
              <td>10%</td>
              <td>30Days</td>
              <td>9.8</td>
            </tr>
            <tfoot>
              <tr className=" py-7 font-bold mt-2 border-t-2 border-yellow-500 text-sub-title ">
                <p>Thông tin của tôi</p>
              </tr>
              <tr className="font-semibold text-content">
                <td className=" py-5 ">
                  <div className="flex flex-col items-center justify-center">
                    <AvatarUserCicle
                      width={"30px"}
                      height={"30px"}
                      avatar={"./img/ngo_diec_pham.png"}
                    />
                    <span>358</span>
                  </div>
                </td>
                <td>
                  <p>Trương Tấn Khải</p>
                  <p className="icon-main">[PRICIPAL ENGINER]</p>
                </td>
                <td>10%</td>
                <td>30Days</td>
                <td>9.8</td>
              </tr>
            </tfoot>
          </table>
          {/* <table>
            <thead>
              <th>aa</th>
              <th>Họ Tên</th>
              <th>Tiến Độ</th>
              <th>Thời Gian</th>
              <th>Điểm</th>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>Thông tin của tôi</tr>
              <tr className="font-semibold text-content ">
                <td>
                  <div className="flex flex-col items-center justify-center">
                    <AvatarUserCicle
                      width={"30px"}
                      height={"30px"}
                      avatar={"./img/ngo_diec_pham.png"}
                    />
                    <span>358</span>
                  </div>
                </td>
                <td>
                  <p>Trương Tấn Khải</p>
                  <p className="icon-main">[PRICIPAL ENGINER]</p>
                </td>
                <td>10%</td>
                <td>30Days</td>
                <td>9.8</td>
              </tr>
            </tfoot>
          </table> */}
        </div>
      </div>
    </div>
  );
}
