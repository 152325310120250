import React, { useEffect, useState } from "react";

import ModalContentIcon from "../../../components/ModalContentIcon/ModalContentIcon";
import { iconDashBoard } from "../../../components/ModalContentIcon/utilities";
import servCodingCamp from "../../../services/codingCam.service";
import { useTranslation } from "react-i18next";
export default function DetailTaskByTime({ typeFilter = 0 }) {
  const { t } = useTranslation()

  const [statis, setStatis] = useState([]);
  useEffect(() => {
    servCodingCamp
      .getThongKeHocDashboard(1, typeFilter)
      .then((res) => {
        setStatis(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let task = statis[0]?.soLuongHoanThanh;
  let course = statis[4]?.soLuongHoanThanh;
  let project = statis[5]?.soLuongHoanThanh;
  return (
    <div className="flex justify-between items-center px-10 py-0 ">
      <div className="flex flex-col items-center justify-start text-content h-16 ">
        <ModalContentIcon loai={iconDashBoard.TaskTheoTungHangMuc} />
        <span className=" "> {t('trangChu.nhiemVu')}
        </span>
        <span>{task}</span>
      </div>
      <div className="flex flex-col items-center justify-start text-content h-16 ">
        <ModalContentIcon loai={iconDashBoard.KhoaHocTheoTungHangMuc} />

        <span className=" "> {t('trangChu.khoaHoc')}
</span>
        <span>{course}</span>
      </div>
      <div className="flex flex-col items-center justify-start text-content h-16">
        <ModalContentIcon loai={iconDashBoard.DuAnTheoTungHangMuc} />
        <span className="  ">{t('trangChu.duAn')}
</span>
        <span className="">{project}</span>
      </div>
    </div>
  );
}
