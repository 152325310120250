const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  type: "success",
  message: "",
  description: "",
};

const messageReducer = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: function (state, action) {
      const { description, type, message } = action.payload;
      state.type = type;
      state.message = message;
      state.description = description;
    },
  },
});

export const { setMessage } = messageReducer.actions;
export default messageReducer.reducer;
