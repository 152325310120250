import classNames from "classnames";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
let activeVideo = {
  titleVideo: {
    background: "linear-gradient(360deg, #DBB845 0%, #FCE9BF 109.6%)",
    borderRadius: "11px",
  },
  buttonHoanThanh: {
    background: "radial-gradient(226.46% 280.26% at 41.98% 268.42%, #FF0C0C 8.4%, #301D09 89.92%) ",
    borderRadius: "50%",
  },
  text: {
    color: "black",
  },
};

export default function SidebarLearnVideo({ handleChangeBaiHoc, listBaiHoc, activeID }) {
  const { t } = useTranslation()

  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const renderSidebar = () => {
    return listBaiHoc.map((item) => {
      let isDaHoanThanh = item.content.hoanThanh;
      if (!item.isVideo) {
        return (
          <div className=" c-card-content  rounded bg-sp flex items-center text-content font-bold">
            <div className="icon_theme  mr-2 icon-main">
              <i class="fa fa-book"></i>
              <span className="bg_icon_theme"></span>
            </div>
            <p className="m-0 icon-main">{item.content}</p>
          </div>
        );
      }
      // button video
      let isAllow = item.content.truyCap;
      let isActive = activeID == item.content.id;
      let styleButtonVideo = isActive ? activeVideo.titleVideo : {};
      let styteButtonHoanThanh = isDaHoanThanh ? activeVideo.buttonHoanThanh : { borderRadius: 11 };

      let styleText = isActive ? activeVideo.text : {};
      return (
        <div className=" card-theme rounded">
          <div
            style={styleButtonVideo}
            onClick={() => {
              isAllow
                ? handleChangeBaiHoc(item)
                : toast.error("Bạn cần hoàn thành video trước đó để xem được video này");
            }}
            className={classNames(
              `p-3 space-y-2 rounded text-content hover:card-theme duration-75 cursor-pointer

            `,
              { "bg-gray-600  cursor-not-allowed": !isAllow }
            )}
          >
            <div className=" flex space-x-2">
              <button
                style={styteButtonHoanThanh}
                className="w-5 h-5 icon-main bg-main flex-shrink-0"
              >
                {isDaHoanThanh ? <i className="fa fa-check" /> : ""}
              </button>
              <div className=" space-y-1">
                <p style={styleText} className=" font-medium flex-1">
                  {item.content.tenBaiHoc}
                </p>
                <p
                  className={`flex items-center justify-start text-sub-content  font-bold  space-x-2 ${activeID == item.content.id ? "icon-main icon-time" : "text-white"
                    } `}
                >
                  <i
                    className={`fa fa-play   ${activeID == item.content.id ? "icon-main " : "text-white"
                      }`}
                  ></i>
                  <span style={styleText}>{item.content.thoiLuong} phút</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  if (collapsed) {
    return (
      <div
        onClick={toggle}
        className="cursor-pointer absolute w-40 right-0 top-0 h-10  flex items-center space-x-3 justify-center card_theme bort transform translate-x-32 hover:translate-x-2 duration-200 z-10 bg-white border-black pl-4"
      >
        <i id="teeee" className=" fa fa-arrow-left "></i>{" "}
        <span className="text-black ">
          {t('chiTietNhiemVu.noiDungKhoaHoc')}

        </span>
      </div>
    );
  }
  return (
    <div style={{ height: "80vh" }} className="c-col-30 transition ">
      <section className="card-theme     space-y-1  flex flex-col">
        <div className="c-card-content space-y-2">
          <h3 className="text-title flex justify-between items-center px-3">
            {" "}
            <span>
              {t('chiTietNhiemVu.noiDungVideo')}

            </span>{" "}
            <i onClick={toggle} className="fa fa-times text-white text-xl cursor-pointer"></i>
          </h3>
          <div
            style={{ maxHeight: "60vh " }}
            className="    space-y-1  overflow-y-scroll  scrollbar-thin  scrollbar-thumb-rounded-xl  scrollbar-track-rounded-xl scrollbar-thumb-gray-400 scrollbar-track-gray-300 "
          >
            {renderSidebar(listBaiHoc)}
          </div>
        </div>
      </section>
    </div>
  );
}
