import React from "react";
import ProgressLine from "../../components/ProgressLine/ProgressLine";
import LootBox from "./LootBox/LootBox";

export default function Bonous({ dataPratice }) {
  let { phanThuong } = dataPratice;
  let pointDaNhan = [30];
  let pointUser = 30;
  const renderActivityBonus = () => {
    return phanThuong.map((item, index) => {
      let isDisabled = item.point > pointUser;
      let isUsed = pointDaNhan.includes(item.point);
      return (
        <div className="  items-center flex flex-col space-y-2 ">
          <LootBox isDisabled={isDisabled} isUsed={isUsed} item={item} />
          <div className="text-white">{item.point}</div>
        </div>
      );
    });
  };
  let currentPercent = (pointUser / phanThuong[phanThuong.length - 1]?.point) * 100;
  return (
    <div className=" c-col-full    ">
      <div className="card-theme c-card-content space-y-5">
        <p className=" text-white text-title">
          <i className="fa fa-circle  text-2xl mr-2"></i> Bảng Xếp Hạng
        </p>
        <ProgressLine type="yellow" percent={Math.floor(currentPercent)} />
        <div className="flex  relative items-center justify-between p-3 px-20">{renderActivityBonus()}</div>
      </div>
    </div>
  );
}
