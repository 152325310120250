import { Button, Modal, Progress } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import servCodingCamp from "../../services/codingCam.service";
import { useTranslation } from "react-i18next";

export default function Navigate_Footer_Pratices_DaoVao({
  current,
  handleClickNextQuestion,
  isDisableBtn,
  total,
  listQuestion,
}) {
  const { t } = useTranslation()

  let { maBaiTest, gmail } = useSelector((state) => state.testDauVaoSlice.info);

  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const hideModal = () => {
    setOpen(false);
  };

  const handle_PostKetQua = () => {
    let countCorrected = 0;
    for (let index = 0; index < listQuestion.length; index++) {
      const question = listQuestion[index];
      question.isCorrect && countCorrected++;
    }
    let diemQuizz = (countCorrected / total) * 10;
    let inforQuizz = {
      soCauDung: countCorrected,
      diem: diemQuizz,
      noiDungBaiLam: listQuestion.map((item) => {
        return {
          id: item.id,
          luaChon: item.isCorrect,
          noiDungbaiLam: item.userAnsers,
          tieuDe: item.noiDung,
        };
      }),
    };

    let dataNopBai = {
      email: gmail,
      maBaiTest: maBaiTest,
      linkNopBai: JSON.stringify(inforQuizz),
    };
    // dataNopBai = JSON.stringify(dataNopBai);
    // return
    servCodingCamp
      .putNopBaiTestDauVao(dataNopBai)
      .then((res) => {
        // if (diemQuizz > 7) {
        Modal.info({
          title: `
          Chúc mừng bạn đã hoàn thành xong bài kiểm tra.

          `,
          onOk() {
            window.location.href = "https://cyberlearn.vn/";
          },
        });
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickNext = () => {
    return !isDisableBtn ? handleClickNextQuestion() : null;
  };
  const percent = Math.floor((current / total) * 100);
  const nextBtnCss = isDisableBtn
    ? " text-gray-600 bg-gray-300 cursor-not-allowed"
    : "  bg-blue-theme text-white ";
  return (
    <div
      style={{
        background:
          "radial-gradient(91.01% 91.01% at 16.52% 42.45%, #ffc94c 0%, #eebe47 23%, #edb720 52%",
      }}
      className=" bg-white flex items-center h-16 w-full justify-center space-x-10 px-16 border-none rounded-2xl"
    >
      <div className="flex items-cente space-x-5 justify-center max-w-screen-md w-full">
        <Progress
          step={total}
          percent={percent}
          className="w-full"
          showInfo={false}
          strokeWidth={15}
          strokeColor={{
            "0%": "#301D09",
            "100%": "#FF0C0C",
          }}
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        <span className="font-bold flex-shrink-0  text-content ">
          {current}/{total} câu
        </span>
      </div>

      <Button
        onClick={() => {
          return current === total ? handle_PostKetQua() : handleClickNext();
        }}
        className={
          "  text-white duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg" +
          nextBtnCss
        }
        loading={loading}
      >
        {current == total ? t('chiTietNhiemVu.hoanThanh') : t('chiTietNhiemVu.cauTiepTheo')}
      </Button>
    </div>
  );
}
