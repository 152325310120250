import React from "react";

export default function LiveEventPage() {
  return (
    <div className=" mt-36">
      <div className="flex">
        <div className="c-col-38">
          <div className="card-theme h-full text-content">
            <p className="font-bold text-title c-card-content border-b border-gray-200 flex items-center space-x-3">
              <i class="fa fa-chalkboard-teacher"></i>

              <span>WORK SOFT</span>
            </p>
            <p className="c-card-content text-content font-bold">Chia sẻ kinh nghiệm lập trình cùng chuyên gia Ths. Lê Quang Song</p>
            <div className="c-card-content space-y-2">
              <p className="space-x-2">
                <i class="fa fa-directions icon-main"></i>
                <span>Định hướng nghề nghiệp</span>
              </p>
              <p className="space-x-2">
                <i class="fa fa-directions icon-main"></i>
                <span>Định hướng lộ trình</span>
              </p>
              <p className="space-x-2">
                <i class="fa fa-directions icon-main"></i>
                <span> Chia sẻ kiến thức kinh nghiệm khi học code</span>
              </p>
              <p className="space-x-2">
                <i class="fa fa-directions icon-main"></i>
                <span> Chia sẻ cách làm cv Định hướng tương lai dài hạn</span>
              </p>
            </div>
            <div className="c-card-content flex justify-between">
              <button className="bg-main text-white py-2 px-4 rounded-lg text-sub-title">Tham Gia</button>
              <div className="text-content font-bold px-4">
                <p className="flex items-center space-x-2 ">
                  <i class="fa fa-clock icon-main"></i>
                  <span>19:00 PM 10-10-2025</span>
                </p>
                <p className="flex items-center space-x-2 ">
                  <i class="fa fa-map-marker-alt icon-main"></i>
                  <span>Online</span>
                </p>
              </div>
            </div>
            <div className="c-card-content text-title">
              <p>
                <span>Số người đã đăng ký : </span>
                <span className="icon-main">25/50</span>
              </p>
            </div>
          </div>
        </div>
        <div className="c-col-62">
          <div className="card-theme h-96">
            <img src="./img/card-best-pratice.png" alt="" className="h-full w-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
}
