import React, { useState, useEffect } from "react";
import Practices_SingleChoice from "../Pratices/Practices_SingleChoice";
import Practices_SingleChoiceToFill from "../Pratices/Praticees_ChooseAnserToFill";
import Practices_HTML_CSS from "../Pratices/Practices_HTML_CSS";
import Practices_FillCodeToInput from "../Pratices/Practices_FillCodeToInput";
import Practices_MultipleChoice from "../Pratices/Practices_MultipleChoice";
import _ from "lodash";
import Navigate_Footer_Pratices from "./Navigate_Footer_Pratices";
import "./Praticess.css";
import { useParams } from "react-router-dom";
import servCodingCamp from "../../services/codingCam.service";
import { convertDataQuizz, canClickNextQuestion } from "../List4TypeQuiz/utils";
import List4TypeQuiz from "../List4TypeQuiz/List4TypeQuiz";

export default function TracNghiem_Start({ onSuccess }) {
  let { chuyenDeId, taskId, monHocId } = useParams();
  let [currentQuestionIndex, setCurrentQuestsionIndex] = useState(0);
  let [scoreQuiz, setScoreQuiz] = useState(null);
  const [allQuestions, setListQuestion] = useState([]);
  useEffect(() => {
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        setListQuestion(convertDataQuizz(res.data.content.noiDungNhiemVu));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let handleClickNextQuestion = () => {
    setCurrentQuestsionIndex(currentQuestionIndex + 1);
  };
  let isDisableNextBtn = canClickNextQuestion(allQuestions, currentQuestionIndex);
  return (
    <div className="w-full c-card-content  ">
      <div className="w-full   ">
        <List4TypeQuiz
          setListQuestion={setListQuestion}
          currentQuestionIndex={currentQuestionIndex}
          allQuestions={allQuestions}
        />
      </div>
      <Navigate_Footer_Pratices
        current={currentQuestionIndex + 1}
        total={allQuestions.length}
        handleClickNextQuestion={handleClickNextQuestion}
        isDisableBtn={isDisableNextBtn}
        listQuestion={allQuestions}
        setScoreQuiz={setScoreQuiz}
        onSuccess={onSuccess}
      />
    </div>
  );
}
