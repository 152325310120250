export let convertDataQuizz = (dataRaw) => {
  let listQuestionRaw = dataRaw
    .filter((question) => {
      return question?.maLoaiBaiTap != "runcode";
    })
    .map((item, index) => {
      // console.log("item off", item);
      return {
        // id: item.id,
        id: index,
        noiDung: item,
        isCorrect: false,
        userAnsers: [],
        maLoaiBaiTap: item.maLoaiBaiTap,
      };
    });
  listQuestionRaw = listQuestionRaw || [];
  return listQuestionRaw;
};

export let canClickNextQuestion = (allQuestions, currentQuestionIndex) => {
  let isDisableNextBtn;

  let typeQuestion = allQuestions[currentQuestionIndex]?.noiDung.maLoaiBaiTap;
  if (typeQuestion === "multiple_choice" || typeQuestion === "fill_input") {
    isDisableNextBtn =
      allQuestions[currentQuestionIndex]?.userAnsers?.length === 0;
  }
  if (typeQuestion === "single" || typeQuestion === "multiple_choice") {
    isDisableNextBtn =
      allQuestions[currentQuestionIndex]?.userAnsers?.length === 0;
  }
  if (typeQuestion === "fill_inblank_css" || typeQuestion === "fill_inblank") {
    isDisableNextBtn = !allQuestions[currentQuestionIndex].userAnsers[0];
  }
  return isDisableNextBtn;
};
