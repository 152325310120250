import { AxiosServCodingCamp } from "./axios.service";

export let typeTrangThaiFllow = {
  DANG_FOLLOW: 1,
  DUOC_FOLLOW: 0,
};
class UserWallRequest {
  constructor() {}
  danhSachFllow = (userId, trangThai) => {
    return AxiosServCodingCamp.getMethod(
      `nguoidung/list-follow/${userId}/${trangThai}`,
      false
    );
  };
}

const servUserWall = new UserWallRequest();

export default servUserWall;
